import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const ModalSubTitle = styled.p`
    margin: 0%;
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    @media (min-width: 760px) {
        font-size: 16px;
        margin-bottom: 40px;
    }
`;