import { RootState } from "@data/store";
import { useSelector } from "react-redux";

export const usePostSelectors = () => {

    const { 
        posts,
        organisationPosts,
        userTalentPosts,
    } = useSelector((state: RootState) => state.post);

    return {
        posts,
        organisationPosts,
        userTalentPosts,
    };
};

