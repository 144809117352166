import styled from "styled-components";

export const PageWrapperChildren = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 10px 0px 10px;

    @media (min-width: 1200px) {
        padding: 0px 0px 0px 0px;
        width: 1200px;
    }
`;