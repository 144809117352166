import { 
    saveUserTalentSpontaneousDemand,
    saveOrganisationRecruiterSpontaneousDemand,
} from "@data/reducers";
import { 
    fetchDeletedSpontaneousDemandRequest,
    fetchGetOrganisationSpontaneousDemandRequest,
    fetchGetUserTalentSpontaneousDemandRequest,
    fetchUpdatedSpontaneousDemandRequest,
    fetchUserTalentNewSpontaneousDemandRequest,
} from "@data/services";
import { AppDispatch } from "@data/store";
import { FetchResponseType, SpontaneousDemand } from "@utilities/types";
import { useCallback } from "react";
import { useDispatch } from "react-redux"

export const useSpontaneousDemandDispatch = () => {

    const dispatch = useDispatch<AppDispatch>();

    const onGetUserTalentSpontaneousDemandRequest = useCallback(async(userID: string) => {
        const req:FetchResponseType = await fetchGetUserTalentSpontaneousDemandRequest(userID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const userTalentSpontaneousDemand:SpontaneousDemand[] = req.data;
        dispatch(saveUserTalentSpontaneousDemand(userTalentSpontaneousDemand));
    }, [dispatch]);

    const onGetOrganisationSpontaneousDemandRequest = useCallback(async(organisationID: string) => {
        const req:FetchResponseType = await fetchGetOrganisationSpontaneousDemandRequest(organisationID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const organisationSpontaneousDemand:SpontaneousDemand[] = req.data;
        dispatch(saveOrganisationRecruiterSpontaneousDemand(organisationSpontaneousDemand));
    }, [dispatch]);

    const onCreateUserTalentNewSpontaneousDemandRequest = useCallback(async(newDemand: SpontaneousDemand) => {
        const req:FetchResponseType = await fetchUserTalentNewSpontaneousDemandRequest(newDemand);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        return 'success !';
    }, []);

    const onUpdatedSpontaneousDemandRequest = useCallback(async(updatedDemand: SpontaneousDemand) => {
        const req:FetchResponseType = await fetchUpdatedSpontaneousDemandRequest(updatedDemand);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        return 'success !';
    }, []);

    const onDeletedSpontaneousDemandRequest = useCallback(async(spontaneousDemandID: string) => {
        const req:FetchResponseType = await fetchDeletedSpontaneousDemandRequest(spontaneousDemandID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        return 'success !';
    }, []);

    const onResetSpontaneousDemandRequest = useCallback(()=> {
        dispatch(saveUserTalentSpontaneousDemand([]));
        dispatch(saveOrganisationRecruiterSpontaneousDemand([]));
    }, [dispatch]);

    return {
        onGetUserTalentSpontaneousDemandRequest,
        onGetOrganisationSpontaneousDemandRequest,
        onCreateUserTalentNewSpontaneousDemandRequest,
        onUpdatedSpontaneousDemandRequest,
        onDeletedSpontaneousDemandRequest,
        onResetSpontaneousDemandRequest,
    };
};
