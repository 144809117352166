import { TalentAccount_RDVComponent } from "./TalentAccount_RDV.component";

export const TalentAccount_RDV:React.FC = ()=> {



    return (
        <TalentAccount_RDVComponent />
    )
}
