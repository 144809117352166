import React from "react";
import './styles.css';
import { IBadge } from "./interface";
import { Paragraph } from "../../atomes";

export const Badge:React.FC<IBadge> = ({
    label,
    customColor,
})=> {



    return (
        <div
            className="ft_badge_container"
            style={customColor ? {
                backgroundColor: customColor
            } : {}}
        >
            <Paragraph 
                label={label}
                weight='bold'
                variant='p_sm'
                className="ft_badge_txt"
            />
        </div>
    )
}