import styled from "styled-components";

interface IFloatingCard {
    width: string;
    height?: string;
}

export const FloatingCard2 = styled.div<IFloatingCard>`
    width: ${props => props.width || '100px'};
    height: ${props => props.height || 'auto'};
    overflow: hidden;
    background-color: transparent;
    animation: float 8s ease-in-out infinite;

    @media (min-width: 760px) {
        animation: float2 4s ease-in-out infinite;
    }
    
    @keyframes float {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-15px);
        }
    }

    @keyframes float2 {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
    }
`;