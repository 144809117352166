import { FC, useCallback, useState } from "react";
import styled from "styled-components";
import { FontFamilyEnum } from "@utilities/enums";
import { SimpleButton, Input } from "@components";
import { FormWrapper, InputLabel, InputWrapper, SubTitle, Title } from "./common";

const Section = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const SubTitleUnderline = styled.p`
    text-align: center;
    text-decoration: underline;
    margin: 0%;
    font-size: 13px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
`;

export const ForgotPassword:FC = ()=> {

    const [index, setIndex] = useState<number>(0);
    const [email, setEmail] = useState<string>('');

    const handleValidateEmail = useCallback(()=> {
        if(email){
            setIndex(1);
        }
    }, [email]);

    const renderContent = useCallback(()=> {
        switch (index) {
            case 0:
                return (
                    <Section>
                        <Title>
                            Mot de passe oublié 🤔
                        </Title>
                        <SubTitle>
                            Un e-mail vous sera envoyé pour réinitialiser votre mot de passe.
                        </SubTitle>

                        <FormWrapper>
                            <InputWrapper>
                                <InputLabel>
                                    Adresse email*
                                </InputLabel>
                                <Input
                                    placeholder="Entrez l’adresse email"
                                    value={email}
                                    onChange={setEmail}
                                />
                            </InputWrapper>

                            <SimpleButton onClick={handleValidateEmail}>
                                Confirmé
                            </SimpleButton>
                        </FormWrapper>
                    </Section>
                );

            case 1:
                return (
                    <Section>
                        <Title>
                            Mot de passe oublié 📩
                        </Title>
                        <SubTitleUnderline>
                            Si votre e-mail est enregistré dans nos systèmes,
                        </SubTitleUnderline>
                        <SubTitle>
                            vous allez recevoir recevoir un e-mail pour créer un nouveau mot de passe.
                        </SubTitle>
                    </Section>
                );
            default:
                return null;
        }
    }, [email, index])

    return renderContent();
}