import { FontFamilyEnum, IconNameEnum } from "@utilities/enums";
import { FC, ReactNode, useCallback, useState } from "react";
import styled from "styled-components";
import { Icon } from "./icon";

interface IDropdown {
    elements: {
        title: string
        content: ReactNode
    }[]
}

const DropdownWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const DropdownItem = styled.div<{bgColor: string}>`
    width: 100%;
    padding: 20px;
    border-radius: 16px;
    margin-bottom: 20px;
    border: solid 1px #EFECE6;
    background-color: ${props=> props.bgColor};
    display: flex;
    flex-direction: column;

    @media (min-width: 950px) {
        padding: 30px;
    }
`;

const DropdownItemHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const DropdownItemHeadLabel = styled.label<{color: string}>`
    font-size: 12px;
    max-width: 92%;
    color: ${props=> props.color};
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    @media (min-width: 950px) {
        font-size: 18px;
    }
`;

const DropdownItemContent = styled.div`
    margin-top: 20px;
`;

export const Dropdown:FC<IDropdown> = ({
    elements
})=> {

    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handlePressItem = useCallback((index: number)=> {
        if(index === activeIndex){
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    }, [activeIndex]);

    return (
        <DropdownWrapper>
            {
                elements.map((el, i)=> {
                    const isActiveItem = activeIndex === i;
                    return (
                        <DropdownItem key={i} bgColor={isActiveItem ? '#EFECE6' : 'transparent'}>
                            <DropdownItemHead onClick={()=> handlePressItem(i)}>
                                <DropdownItemHeadLabel color={isActiveItem ? "#101214" : "#efece6"}>
                                    {el.title}
                                </DropdownItemHeadLabel>

                                <Icon 
                                    iconName={isActiveItem ? IconNameEnum.minus : IconNameEnum.plus}
                                    color={isActiveItem ? "#101214" : "#efece6"}
                                    onPress={()=> handlePressItem(i)}
                                />
                            </DropdownItemHead>

                            {
                                isActiveItem && (
                                    <DropdownItemContent>
                                        {el.content}
                                    </DropdownItemContent>
                                )
                            }
                        </DropdownItem>
                    )
                })
            }
        </DropdownWrapper>
    )
}