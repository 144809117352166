export enum ProfessionsEnum {
    // Académique / Recherche
    ProfesseurUniversite = "Professeur d’université",
    ChercheurAcademique = "Chercheur académique",
    ResponsableLaboratoire = "Responsable de laboratoire",
    
    // Aérospatial / Défense
    IngenieurAerospatial = "Ingénieur aérospatial",
    AnalysteDefense = "Analyste en défense",
    TechnicienAeronautique = "Technicien en aéronautique",
    
    // Agriculture
    Agronome = "Agronome",
    TechnicienAgricole = "Technicien agricole",
    ResponsableFerme = "Responsable de ferme",
    
    // Architecture
    Architecte = "Architecte",
    DesignerInterieur = "Designer d’intérieur",
    Urbaniste = "Urbaniste",
    
    // Arts / Création
    ArtisteGraphique = "Artiste graphique",
    Illustrateur = "Illustrateur",
    ConservateurMusee = "Conservateur de musée",
    
    // Association / ONG
    ChargeMissionAssociative = "Chargé de mission associative",
    ResponsableProgrammeONG = "Responsable de programme ONG",
    
    // Banques / Assurances / Finance
    AnalysteFinancier = "Analyste financier",
    GestionnairePortefeuille = "Gestionnaire de portefeuille",
    ConseillerAssurance = "Conseiller en assurance",
    
    // Construction / BTP
    ConducteurTravaux = "Conducteur de travaux",
    ArchitecteInterieur = "Architecte d’intérieur",
    IngenieurGenieCivil = "Ingénieur en génie civil",
    
    // Conseil / Audit
    ConsultantStrategie = "Consultant en stratégie",
    AuditeurFinancier = "Auditeur financier",
    ExpertComptable = "Expert-comptable",
    
    // Culture / Média / Divertissement
    Journaliste = "Journaliste",
    ProducteurAudiovisuel = "Producteur audiovisuel",
    DirecteurArtistique = "Directeur artistique",
    
    // Distribution
    ResponsableMagasin = "Responsable de magasin",
    GestionnaireEcommerce = "Gestionnaire e-commerce",
    DirecteurDistribution = "Directeur de la distribution",
    
    // Éducation / Formation / Recrutement
    Formateur = "Formateur",
    ChargeRecrutement = "Chargé de recrutement",
    ConseillerPedagogique = "Conseiller pédagogique",
    
    // Environnement / Développement durable
    ConsultantDeveloppementDurable = "Consultant en développement durable",
    ChargeMissionEnvironnement = "Chargé de mission environnement",
    SpecialisteEnergieRenouvelable = "Spécialiste en énergie renouvelable",
    
    // Finance / Comptabilité
    Comptable = "Comptable",
    AuditeurInterne = "Auditeur interne",
    AnalysteCredit = "Analyste en crédit",
    
    // Gestion de projet
    ChefProjet = "Chef de projet",
    CoordinateurProjet = "Coordinateur de projet",
    ManagerProjet = "Manager de projet",
    
    // Food et boisson
    ChefCuisine = "Chef de cuisine",
    ResponsableQualiteAlimentaire = "Responsable qualité alimentaire",
    DirecteurRestauration = "Directeur de la restauration",
    
    // Hôtellerie / Tourisme / Loisirs
    DirecteurHotel = "Directeur d’hôtel",
    GuideTouristique = "Guide touristique",
    ManagerLoisirs = "Manager de loisirs",
    
    // Immobilier
    AgentImmobilier = "Agent immobilier",
    GestionnairePatrimoine = "Gestionnaire de patrimoine",
    ConsultantImmobilierCommercial = "Consultant en immobilier commercial",
    
    // Industrie
    IngenieurAgroalimentaire = "Ingénieur en agroalimentaire",
    ResponsableProduction = "Responsable de production",
    TechnicienMaintenanceIndustrielle = "Technicien en maintenance industrielle",
    
    // Ingénierie
    IngenieurCivil = "Ingénieur civil",
    IngenieurMecanique = "Ingénieur mécanique",
    ConsultantIngenierie = "Consultant en ingénierie",
    
    // Informatique / Développement
    AdministrateurSystemes = "Administrateur systèmes",
    IngenieurReseau = "Ingénieur réseau",
    DeveloppeurBackend = "Développeur backend",
    
    // Légal / Justice
    Avocat = "Avocat",
    JuristeEntreprise = "Juriste d’entreprise",
    Notaire = "Notaire",
    
    // Logistique / Supply Chain
    GestionnaireStock = "Gestionnaire de stock",
    ResponsableSupplyChain = "Responsable supply chain",
    CoordinateurLogistique = "Coordinateur logistique",
    
    // Mode / Luxe / Beauté / Art de vivre
    Styliste = "Styliste",
    ChefProduitCosmetique = "Chef de produit cosmétique",
    ResponsableBoutiqueLuxe = "Responsable de boutique de luxe",
    
    // Mobilité / Transport
    Logisticien = "Logisticien",
    ConducteurTransportRoutier = "Conducteur de transport routier",
    ResponsableFlotte = "Responsable de flotte",
    
    // Psychologie / Bien-être
    Psychologue = "Psychologue",
    CoachDeveloppementPersonnel = "Coach en développement personnel",
    ConseillerBienEtre = "Conseiller en bien-être",
    
    // Publicité / Marketing / Agence
    ChargeCommunication = "Chargé de communication",
    ResponsableMarketingDigital = "Responsable marketing digital",
    DirecteurCreation = "Directeur de création",
    
    // Rédaction / Traduction
    RedacteurTechnique = "Rédacteur technique",
    Traducteur = "Traducteur",
    Correcteur = "Correcteur",
    
    // Sciences / Recherche
    ChercheurBiotechnologie = "Chercheur en biotechnologie",
    ScientifiqueLaboratoire = "Scientifique de laboratoire",
    AnalysteRechercheClinique = "Analyste en recherche clinique",
    
    // Services de nettoyage / Entretien
    AgentEntretien = "Agent d’entretien",
    ResponsableHygieneProprete = "Responsable hygiène et propreté",
    TechnicienSurface = "Technicien de surface",
    
    // Services aux entreprises
    ChargeClienteleEntreprise = "Chargé de clientèle entreprise",
    GestionnaireCoworking = "Gestionnaire de coworking",
    ResponsableIncubateur = "Responsable d’incubateur",
    
    // Santé / Social / Environnement
    Medecin = "Médecin",
    ChargeProjetEnvironnemental = "Chargé de projet environnemental",
    ResponsableAssociationCaritative = "Responsable d’association caritative",
    
    // Secteur public et administration
    FonctionnaireTerritorial = "Fonctionnaire territorial",
    ResponsableAdministratif = "Responsable administratif",
    GestionnaireCollectivite = "Gestionnaire de collectivité",
    
    // Tech
    DeveloppeurLogiciel = "Développeur logiciel",
    DataScientist = "Data scientist",
    IngenieurCybersécurité = "Ingénieur en cybersécurité",
    
    // Télécommunications
    IngenieurTelecom = "Ingénieur télécom",
    ResponsableReseau = "Responsable de réseau",
    TechnicienTelecommunications = "Technicien en télécommunications"
}