import { FontFamilyEnum, IconNameEnum } from "@utilities/enums";
import { FC, useCallback, useState } from "react";
import styled from "styled-components";
import { Icon } from "./icon";

interface IInputWithLabel {
    placeholder: string
    value: string
    label: string
    onChange: (e: string)=> void
    inputType?: 'defult' | 'password' | 'number' | undefined
}

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const InputContainer = styled.div`
    flex: 1;
    height: 40px;
    border-radius: 8px;
    padding-right: 20px;
    background-color: #FFFFFF;
    overflow: hidden;
    display: flex;
    align-items: center;

    @media (min-width: 950px) {
        padding-right: 16px;
        height: 48px;
        border-radius: 12px;
    }
`;
const Input = styled.input`
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    height: 40px;
    font-size: 12px;
    padding-left: 14px;
    padding-right: 14px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Regular};

    ::placeholder {
        color: #C3C3C3;
    }

    @media (min-width: 950px) {
        height: 48px;
        font-size: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }
`;
const Label = styled.label`
    font-size: 10px;
    margin-bottom: 7px;
    color: #0C1421;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    @media (min-width: 950px) {
        font-size: 13px;
    }
`;

export const InputWithLabel:FC<IInputWithLabel> = ({
    label,
    value,
    placeholder,
    onChange,
    inputType = 'defult',
})=> {

    const [displayPassword, setDisplayPassword] = useState<boolean>(false);

    const toggleDisplayPassword = useCallback(()=> {
        setDisplayPassword(!displayPassword);
    }, [displayPassword]);

    return (
        <InputWrapper>
            <Label>
                {label}
            </Label>
            <InputContainer>
                <Input 
                    type={
                        inputType === 'defult' ? 'text' 
                        : inputType === 'number' ? 'tel' 
                        : (displayPassword ? 'text' : 'password')
                    }
                    placeholder={placeholder}
                    value={value}
                    onChange={e=> onChange(e.target.value)}
                />
                {
                    inputType === 'password' && (
                        <Icon 
                            iconName={displayPassword ? IconNameEnum.eyeSlash : IconNameEnum.eye}
                            onPress={toggleDisplayPassword}
                        />
                    )
                }
            </InputContainer>
        </InputWrapper>
    )
}