import React, { useCallback, useMemo, useState } from "react";
import './styles.css';
import { IRecruiterAccount_JobOffers } from "./interfaces";
import { useNavigation, useTranslation } from "@utilities/hooks";
import { TabNavigationWithSubHead } from "@connectedComponents";
import { EnumContratType, EnumJobCategory, NavigationPathsEnum } from "@utilities/enums";
import { Button, TabView, JobOffersCard, Modal, CreateAndEditJobOffer, Title, QcmGenerator, Select, Paragraph } from "@components";
import { EnumUserLevel, EnumWorkTime, Post, qcmTestType } from "@utilities/types";
import { enumToStringArray } from "@utilities/functions";
import { Nullable } from "primereact/ts-helpers";

export const RecruiterAccount_JobOffersComponent:React.FC<IRecruiterAccount_JobOffers> = ({
    recruiter,
    posts,
    organisationTest,
    onSaveOrganisationPost,
    onGenerateTestByIA,
    onSaveOrganisationTest,
    onUpdatedPost,
})=> {

    const translate = useTranslation();
    const {navigateTo} = useNavigation();

    const [searchInCurentJob, setSearchInCurentJob] = useState<string>('');
    const [searchInArchieved, setSearchInArchieved] = useState<string>('');
    const [displayAddJobModal, setDisplayAddJobModal] = useState<boolean>(false);
    const [showEditJobOfferModal, setShowEditJobOfferModal] = useState<boolean>(false);

    const [entitle, setEntitle] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [startDate, setStartDate] = useState<Nullable<Date>>();
    const [minSalary, setMinSalary] = useState<string>('');
    const [maxSalaryMonth, setMaxSalaryMonth] = useState<string>('');
    const [contratType, setContratType] = useState<string>('');
    const [workTime, setWorkTime] = useState<string>('');
    const [teleworking, setTeleworking] = useState<boolean>(false);
    const [partialTeleworking, setPartialTeleworking] = useState<boolean>(false);
    const [noTeleworking, setNoTeleworking] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');

    const [selectedJobToAddTest, setSelectedJobToAddTest] = useState<Post | null>(null);
    const [testTitle, setTestTitle] = useState<string>('');
    const [testLevel, setTestLevel] = useState<EnumUserLevel | null>(null);
    const [testCategory, setTestCategory] = useState<string>('');
    const [questions, setQuestions] = useState<qcmTestType[] | null>(null);

    const levelList = useMemo(()=> (
        enumToStringArray(EnumUserLevel)
    ), [])
    const currentJobsList = useMemo(()=> {
        return posts.filter(el=> !el.isArchieved);
    }, [posts]);

    const archievedJobsList = useMemo(()=> {
        return posts.filter(el=> el.isArchieved);
    }, [posts]);

    const contratTypeList = useMemo(()=> {
        return enumToStringArray(EnumContratType);
    }, []);

    const workTimeList = useMemo(()=> {
        return enumToStringArray(EnumWorkTime);
    }, []);

    const handleGoBack = useCallback(()=> {
        navigateTo(NavigationPathsEnum.RECRUITER_ACCOUNT);
    }, [navigateTo]);

    const handleGenerateTestByIA = useCallback(async()=> {
        if(!testLevel){
            return alert('Selectionner un niveau pour le test');
        }

        const result = await onGenerateTestByIA(selectedJobToAddTest?._id!, testLevel);
        setQuestions(result);
    }, [selectedJobToAddTest, testLevel, onGenerateTestByIA]);

    const handleInitTestForm = useCallback(()=> {
        setQuestions(Array(20).fill({
            question: '',
            reponse_a: '',
            reponse_b: '',
            reponse_c: '',
            reponse_d: '',
            solution: '',
            selected_response: '',
            explication: '',
        }))
    }, []);

    const handleSelectedQcm = useCallback((qcmID: string)=> {
        const post = {
            ...selectedJobToAddTest,
            _qcmID: qcmID,
        } as Post;

        onUpdatedPost(post);
        setSelectedJobToAddTest(null);
    }, [selectedJobToAddTest, onUpdatedPost]);

    const handleSaveTest = useCallback(()=> {
        if(!(testTitle && testCategory && testLevel && questions)){
            return alert('Tous les champs doivent etre complet !');
        }

        // onSaveOrganisationTest({
        //     _createdBy_ID: recruiter._organisationID!,
        //     entitle: testTitle,
        //     jobCategory: testCategory as EnumJobCategory,
        //     level: testLevel,
        //     qcm: questions,
        //     time: 10
        // });

        setQuestions(null);
        setTestTitle('');
        setTestLevel(null);
        setTestCategory('');
    }, [
        testTitle, testCategory, 
        testLevel, questions, 
        recruiter, onSaveOrganisationTest,
    ]);

    const toggleDisplayAddNewJobModal = useCallback(()=> {
        setDisplayAddJobModal(!displayAddJobModal);
    }, [displayAddJobModal]);

    const getTestTitle = useCallback((qcmID: string)=> {
        return organisationTest.find(el=> el._id === qcmID)?.entitle;
    }, [organisationTest]);

    const handleClearForm = useCallback(()=> {
        setEntitle('');
        setCity('');
        setCountry('');
        setStartDate(null);
        setMinSalary('');
        setMaxSalaryMonth('');
        setContratType('');
        setWorkTime('');
        setTeleworking(false);
        setPartialTeleworking(false);
        setNoTeleworking(false);
        setDescription('');
    }, []);

    const handleEditJobOffer = useCallback((jobOffer: Post)=> {
        setEntitle(jobOffer.entitle);
        setCity(jobOffer.city);
        setCountry(jobOffer.country);
        setStartDate(jobOffer.startAt);
        setMinSalary(String(jobOffer.criteria.minSalaryMonth));
        setMaxSalaryMonth(String(jobOffer.criteria.maxSalaryMonth));
        setContratType(jobOffer.criteria.contratType);
        setWorkTime(jobOffer.criteria.workTime);
        setTeleworking(jobOffer.criteria.teleworking);
        setPartialTeleworking(jobOffer.criteria.partialTeleworking);
        setNoTeleworking(jobOffer.criteria.noTeleworking);
        setDescription(jobOffer.description);
        setShowEditJobOfferModal(true);
    }, []);

    const handleCloseEditJobOfferModal = useCallback(()=> {
        handleClearForm();
        setShowEditJobOfferModal(false);
    }, [handleClearForm]);

    const handleCreateNewJobOffer = useCallback(()=> {
        if(!
            (
                entitle && city && country && startDate && minSalary
                && maxSalaryMonth && contratType && workTime && description
            )
        ){
            return alert('Tous les champs doit etre remplis !');
        }

        onSaveOrganisationPost({
            _organisationID: recruiter._organisationID,
            _recruiterID: recruiter._id,
            criteria: {
                minSalaryMonth: Number(minSalary),
                maxSalaryMonth: Number(maxSalaryMonth),
                partialTeleworking,
                teleworking,
                noTeleworking,
                contratType: contratType as EnumContratType,
                workTime: workTime as EnumWorkTime,
            },
            entitle,
            description,
            city,
            country,
            startAt: startDate,
        });

        handleClearForm();
        toggleDisplayAddNewJobModal();
    }, [
        city, contratType, 
        country, description, 
        entitle, maxSalaryMonth, 
        minSalary, noTeleworking, 
        partialTeleworking, startDate, 
        teleworking, workTime, recruiter,
        handleClearForm,
        onSaveOrganisationPost,
        toggleDisplayAddNewJobModal,
    ]);

    const handleOnEditJobOffer = useCallback(()=> {
       
    }, []);

    const renderCurrentJobs = useCallback(()=> {
        return (
            <div>
                {/* <Input
                    placeholder="Recherche..."
                    value={searchInCurentJob}
                    onChange={setSearchInCurentJob}
                /> */}

                <div className="ft_recruiterAccount_jobOffer_jobList_container">
                    {
                        currentJobsList.map((el, i)=> {
                            return (
                                <div
                                    key={i}
                                    className="ft_recruiterAccount_jobOffer_jobItem_container"
                                >
                                    <JobOffersCard 
                                        postInfos={el}
                                        hideActionButtons
                                        testTitle={el._qcmID ? getTestTitle(el._qcmID) : ''}
                                        onEdit={()=> handleEditJobOffer(el)}
                                        onDelete={()=> alert('delete')}
                                        onArchieved={()=> alert('archieved')}
                                        onAddTest={()=> setSelectedJobToAddTest(el)}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }, [currentJobsList, handleEditJobOffer, getTestTitle]);

    const renderArchievedJobs = useCallback(()=> {
        return (
            <div>
                {/* <Input
                    placeholder="Recherche..."
                    value={searchInArchieved}
                    onChange={setSearchInArchieved}
                /> */}

                <div className="ft_recruiterAccount_jobOffer_jobList_container">
                    {
                        archievedJobsList.map((el, i)=> {
                            return (
                                <div
                                    key={i}
                                    className="ft_recruiterAccount_jobOffer_jobItem_container"
                                >
                                    <JobOffersCard 
                                        postInfos={el}
                                        hideActionButtons
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }, [archievedJobsList]);

    return (
        <TabNavigationWithSubHead
            pageTitle='Offres'
            onGoBackPress={handleGoBack}
        >
            <div className="ft_jobOffers_container">
                <Button 
                    label="Creer une offre"
                    onButtonPress={toggleDisplayAddNewJobModal}
                />

                <div className="ft_jobOffers_sectionContainer">
                    <TabView 
                        tabViewContent={[
                            {
                                headerTxt: 'En cours',
                                content: renderCurrentJobs()
                            },
                            {
                                headerTxt: 'Archivées',
                                content: renderArchievedJobs()
                            }
                        ]}
                    />
                </div>

                <Modal
                    // fullScreen
                    isVisible={!!selectedJobToAddTest}
                    onClose={()=> {
                        if(questions === null){
                            setSelectedJobToAddTest(null);
                        } else {
                            setQuestions(null);
                        }
                    }}
                >
                    {
                        questions === null ? (
                            <div className="ft_jobOfferRecruiter_modalTestcontainer">
                                <Button 
                                    label="creer un nouveau test"
                                    onButtonPress={handleInitTestForm}
                                    customClassname="ft_jobOfferRecruiter_modalTest_button"
                                />

                                <div className="ft_jobOfferRecruiter_modalTest_subcontainer">
                                    <Title 
                                        variant='h_sm'
                                        weight='light'
                                        label="*Générer un test grace à l'IA"
                                        className="ft_jobOfferRecruiter_modalTest_OU"
                                    />

                                    <Select 
                                        placeholder="Selectionner le niveau du test"
                                        value={testLevel!}
                                        options={levelList}
                                        onChange={e=> setTestLevel(e as EnumUserLevel)}
                                    />
                                    <Button 
                                        label="faire generer le test par l'IA"
                                        onButtonPress={handleGenerateTestByIA}
                                        customClassname="ft_jobOfferRecruiter_modalTest_button"
                                    />
                                </div>

                                <Title 
                                    weight='light'
                                    label="OU"
                                    className="ft_jobOfferRecruiter_modalTest_OU"
                                />

                                <Title 
                                    variant='h_sm'
                                    weight='regular'
                                    label="Selectionner un test"
                                />

                                <div className="ft_recruiterAccount_jobOffer_qcmList_container">
                                    {
                                        organisationTest.map((el, i)=> {
                                            return (
                                                <div
                                                    key={i}
                                                    className="ft_recruiterAccount_jobOffer_qcmListItem_container"
                                                >
                                                    <Paragraph 
                                                        variant='p_sm'
                                                        label={el.entitle}
                                                    />

                                                    <Button 
                                                        label="Voir"
                                                        onButtonPress={()=> {}}
                                                    />

                                                    <Button 
                                                        label="Selectionner"
                                                        onButtonPress={()=> handleSelectedQcm(el._id!)}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        ) : (
                            <QcmGenerator
                                testTitle={testTitle}
                                testLevel={testLevel}
                                questions={questions}
                                setTestLevel={setTestLevel}
                                setTestCategory={setTestCategory}
                                setTestTitle={setTestTitle}
                                setQuestions={setQuestions}
                                onSubmitForm={handleSaveTest}
                            />
                        )
                    }
                </Modal>

                <Modal
                    isVisible={displayAddJobModal || showEditJobOfferModal}
                    // fullScreen
                    onClose={()=> {
                        if(displayAddJobModal){
                            toggleDisplayAddNewJobModal();
                        } else {
                            handleCloseEditJobOfferModal();
                        }
                    }}
                >
                    <CreateAndEditJobOffer 
                        isEditMode={showEditJobOfferModal}
                        entitle={entitle}
                        city={city}
                        country={country}
                        startDate={startDate}
                        minSalary={minSalary}
                        maxSalaryMonth={maxSalaryMonth}
                        contratType={contratType}
                        contratTypeList={contratTypeList}
                        workTime={workTime}
                        workTimeList={workTimeList}
                        teleworking={teleworking}
                        partialTeleworking={partialTeleworking}
                        noTeleworking={noTeleworking}
                        description={description}
                        setEntitle={setEntitle}
                        setCity={setCity}
                        setCountry={setCountry}
                        setStartDate={setStartDate}
                        setMinSalary={setMinSalary}
                        setMaxSalaryMonth={setMaxSalaryMonth}
                        setContratType={setContratType}
                        setWorkTime={setWorkTime}
                        setTeleworking={setTeleworking}
                        setPartialTeleworking={setPartialTeleworking}
                        setNoTeleworking={setNoTeleworking}
                        setDescription={setDescription}
                        onValidateForm={showEditJobOfferModal ? handleOnEditJobOffer : handleCreateNewJobOffer}
                    />
                </Modal>
            </div>
        </TabNavigationWithSubHead>
    )
}
