import React, { useCallback, useEffect, useState } from "react";
import { RecruiterAccount_JobOffersComponent } from "./RecruiterAccount_JobOffers.component";
import { 
    usePostsDispatch,
    usePostSelectors,
    useRecruiterDispatch,
    useRecruiterSelectors,
} from "@utilities/hooks";
import { fetchRequest } from "@utilities/requests";
import { FetchRequestType, Post, TEST_QCM } from "@utilities/types";
import { EnumPaths } from "@utilities/enums";

export const RecruiterAccount_JobOffers:React.FC = ()=> {

    const { recruiter, organisationTestList } = useRecruiterSelectors();
    const { posts } = usePostSelectors();
    const {
        onGetOrganisationPostsRequest,
        onSaveOrganisationPostRequest,
    } = usePostsDispatch();
    const {
        onGenerateTestByIA,
        getOrganisationTestRequest,
        onSaveOrganisationTestRequest,
    } = useRecruiterDispatch();

    const [organisationTest, setOrganisationTest] = useState<TEST_QCM[]>([]);

    const handleSaveOrganisationTest = useCallback(async(test: TEST_QCM)=> {
        await onSaveOrganisationTestRequest(test);
        if(recruiter){
            await getOrganisationTestRequest(recruiter._organisationID!);
        }
    }, [getOrganisationTestRequest, onSaveOrganisationTestRequest, recruiter]);

    const onUpdatedPost = useCallback(async(updatedPost: Post)=> {
        const requestConfig:FetchRequestType = {
            route: EnumPaths.UPDATED_POST,
            method: 'PUT',
            data: updatedPost
        };
    
        const response = await fetchRequest(requestConfig);
        if(response.code === 500){
            alert(response.data.message);
            return null;
        }

        alert('Qcm selectionner !');
    }, []);

    useEffect(()=> {
        if(organisationTestList.length > 0){
            setOrganisationTest(organisationTestList);
        }
    }, [organisationTestList]);

    useEffect(()=> {
        if(recruiter){
            getOrganisationTestRequest(recruiter._organisationID!);
            onGetOrganisationPostsRequest(recruiter._organisationID!);
        }
    }, [recruiter]);

    return (
        <RecruiterAccount_JobOffersComponent 
            organisationTest={organisationTest}
            posts={posts}
            recruiter={recruiter!}
            onSaveOrganisationPost={onSaveOrganisationPostRequest}
            onGenerateTestByIA={onGenerateTestByIA}
            onSaveOrganisationTest={handleSaveOrganisationTest}
            onUpdatedPost={onUpdatedPost}
        />
    )
}
