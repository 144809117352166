import { NavigationPathsEnum } from "@utilities/enums";
import { useNavigation } from "@utilities/hooks";
import { useMemo } from "react";

export const useController = ()=> {

    const { navigateTo } = useNavigation();

    const itemsMenuNav = useMemo(()=> [
        {
            label: 'Profil',
            goTo: ()=> navigateTo(NavigationPathsEnum.RECRUITER_ACCOUNT),
        },
        {
            label: 'Matchings',
            goTo: ()=> {}
        },
        {
            label: 'Offres',
            goTo: ()=> {}
        },
        {
            label: 'Tests',
            goTo: ()=> {}
        },
        {
            label: 'Mes rendez-vous',
            goTo: ()=> {}
        },
        {
            label: 'Candidatures reçus',
            goTo: ()=> {}
        },
        {
            label: 'Gestion des collaborateurs',
            goTo: ()=> {}
        },
        {
            label: 'Paramètres',
            goTo: ()=> {}
        }
    ], [navigateTo]);

    return {
        itemsMenuNav
    }
};
