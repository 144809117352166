import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
  CV,
  TalentInitialStateType,
  TalentSavedPost,
  TEST_QCM,
  UserQCMSolution,
  UserTalent,
} from '@utilities/types';
import { SLICES_NAMES } from '../slicesNames';

const initialState: TalentInitialStateType = {
  talent: null,
  // talentToken: '',
  talentCV: null,
  talentSavedPost: [],
  talentQCMs: [],
  talentQCMsResult: [],
};

const talentSlice = createSlice({
  name: SLICES_NAMES.talent,
  initialState, 
  reducers: {
    saveTalent: (state, action: PayloadAction<UserTalent | null>) => {
      state.talent = action.payload;
    },
    // saveTalentToken: (state, action: PayloadAction<string>) => {
    //   state.talentToken = action.payload;
    // },
    saveTalentCV: (state, action: PayloadAction<CV | null>) => {
      state.talentCV = action.payload
    },
    saveTalentSavedPost: (state, action: PayloadAction<TalentSavedPost[]>) => {
      state.talentSavedPost = action.payload
    },
    saveTalentTests: (state, action: PayloadAction<TEST_QCM[]>) => {
      state.talentQCMs = action.payload
    },
    saveTalentTestResults: (state, action: PayloadAction<UserQCMSolution[]>) => {
      state.talentQCMsResult = action.payload
    }
  },
});

export const { 
  saveTalent,
  // saveTalentToken,
  saveTalentCV,
  saveTalentSavedPost,
  saveTalentTests,
  saveTalentTestResults,
} = talentSlice.actions;

export const talentReducers = talentSlice.reducer;
