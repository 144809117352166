import { FontFamilyEnum, IconNameEnum } from "@utilities/enums";
import { FC } from "react";
import styled from "styled-components";
import { Icon } from "./icon";

interface Props {
    onIconPress: ()=> void
    children: string
}

const BadgeWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    height: 23px;
    border-radius: 23px;
    padding: 0px 10px 0px 10px;
    background-color: #101214;
    color: #fff;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
    font-size: 10px;

    @media (min-width: 760px) {
        font-size: 13px;
        height: 30px;
        padding: 0px 17px 0px 17px;
        border-radius: 60px;
    }
`

export const BadgeWithIcon:FC<Props> = ({
    onIconPress,
    children
})=> {
    return (
        <BadgeWrapper>
            {children}

            <Icon 
                iconName={IconNameEnum.close}
                onPress={onIconPress}
                color="#fff"
            />
        </BadgeWrapper>
    )
}