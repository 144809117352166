import { FC, ReactNode } from "react";
import styled from "styled-components";

interface IDoubleCircle {
    children: ReactNode
    size?: number
}

const CircleOne = styled.div<{size: number}>`
    width: ${props=> props.size + 'px'};
    height: ${props=> props.size + 'px'};
    border-radius: ${props=> props.size + 'px'};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EFECE638;
`;
const CircleTwo = styled.div<{size: number}>`
    width: ${props=> props.size + 'px'};
    height: ${props=> props.size + 'px'};
    border-radius: ${props=> props.size + 'px'};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EFECE6;
`;

export const DoubleCircle:FC<IDoubleCircle> = ({ 
    children, 
    size = 112,
})=> {
    return (
        <CircleOne size={size}>
            <CircleTwo size={size - 20}>
                {children}
            </CircleTwo>
        </CircleOne>
    )
}