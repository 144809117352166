import React from "react";
import './styles.css';
import { useTranslation } from "@utilities/hooks";
import { SubMenu, TabNavigation } from "@connectedComponents";
import { Container } from "./styles";
import { useController } from "../useControllers";

export const RecruiterAccount:React.FC = ()=> {

    const translate = useTranslation();
    const { itemsMenuNav } = useController();

    return (
        <TabNavigation customBG="#EFECE6">
            <>
                <SubMenu 
                    navMenu={itemsMenuNav}
                    active={itemsMenuNav[0].label}
                />

                <Container>
                    
                </Container>
            </>
        </TabNavigation>
    )
}
