import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const Title = styled.h3`
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
    font-size: 20px;
    margin: 0%;
    margin-top: 35px;
    margin-left: 10px;
    color: #101214;
`;

export const JobOfferWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
    background-color: #F9F9F9;

    @media (min-width: 760px){
        gap: 25px;
    }
`;