import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const SimpleButton = styled.button`
    color: #F9F9F9F9;
    background-color: #101214;
    height: 44px;
    border-radius: 9px;
    font-size: 12px;
    padding: 0px 16px 0px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border: none;
    cursor: pointer;
    font-family: ${FontFamilyEnum.PlusJakartSans_Bold};

    @media (min-width: 950px) {
        height: 44px;
        border-radius: 10px;
        font-size: 14px;
        padding: 0px 24px 0px 24px;
    }
`;