import { EnumPaths } from "@utilities/enums";
import { fetchRequest } from "@utilities/requests";
import { 
    FetchRequestType, 
    FetchResponseType,
    Post,
} from "@utilities/types";

export const fetchGetPostRequest = async():Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.GET_ALL_POSTS,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetOrganisationPostRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: `${EnumPaths.GET_ALL_ORGANISATION_POSTS}?organisationID=${payload}`,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchSaveOrganisationPostRequest = async(
    payload: Post
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.CREATE_POST,
        method: 'POST',
        data: payload,
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchUpdatedOrganisationPostRequest = async(
    payload: Post
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.UPDATED_POST,
        method: 'PUT',
        data: payload,
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetTalentResponseToJobRequestRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig:FetchRequestType = {
        route: `${EnumPaths.GET_TALENT_RESPONSES_TO_JOB_OFFER}?talentID=${payload}`,
        method: 'GET'
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};
