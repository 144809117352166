import { FC } from "react";
import styled from "styled-components";

import LeftFlower from '@assets/images/leftFlower.svg';
import RightFlower from '@assets/images/rightFlower.svg';
import { Icon } from "./icon";
import { FontFamilyEnum, IconNameEnum } from "@utilities/enums";
import { useScreenSize } from "@utilities/hooks";

const Section = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 90px;

    @media (min-width: 760px) {
        margin-top: 140px;
    }
`;
const SubSection = styled.div`
    margin: 0px 5px 0px 5px;

    @media (min-width: 760px) {
        margin: 0px 10px 0px 10px;
    }
`;
const Paragraph = styled.p`
    margin: 0%;
    font-size: 10px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Black};
    text-align: center;
    letter-spacing: 0.5px;

    @media (min-width: 760px) {
        font-size: 13px;
        letter-spacing: 1px;
    }
`;
const StarsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 5px;
    gap: 3px;

    @media (min-width: 760px) {
        margin-top: 5px;
        gap: 5px;
    }
`;

export const StarsSection:FC = ()=> {

    const { isMobile } = useScreenSize();

    return (
        <Section>
            <img 
                src={LeftFlower} 
                alt="left flower"
                height={isMobile ? 50 : 83}
            />

            <SubSection>
                <Paragraph>
                    LA PLATEFORME INCONTOURNABLE DE
                </Paragraph>
                <Paragraph>
                    2024 POUR RECRUTEURS ET CANDIDATS
                </Paragraph>
    
                <StarsContainer>
                    {
                        [0,0,0,0,0].map((el, i)=> (
                            <Icon 
                                key={i}
                                iconName={IconNameEnum.star}
                                size={isMobile ? 'xs' : 'lg'}
                            />
                        ))
                    }
                </StarsContainer>
            </SubSection>

            <img 
                src={RightFlower} 
                alt="right flower"
                height={isMobile ? 50 : 83}
            />
        </Section>
    );
};
