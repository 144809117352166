import { FC } from "react";
import styled from "styled-components";
import { Icon } from "./icon";
import { FontFamilyEnum, IconNameEnum } from "@utilities/enums";
import { SimpleBadge } from "./simpleBadge";
import { useScreenSize } from "@utilities/hooks";

interface Props {
    onCardPress: ()=> void
}

const Card = styled.section`
    width: 200px;
    border-radius: 20px;
    border: solid 1px #ECECEC;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 760px) {
        width: 300px;
    }
`;
const OrganisationImg = styled.img`
    width: 65px;
    height: 65px;
    object-fit: cover;
    margin-bottom: 15px;
    margin-top: 20px;

    @media (min-width: 760px) {
        width: 100px;
        height: 100px;
        margin-bottom: 25px;
        margin-top: 40px;
    }
`;
const OrganisationName = styled.h3`
    margin: 0%;
    font-size: 12px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
    color: #101214;

    @media (min-width: 760px) {
        font-size: 22px;
    }
`;
const Details = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    padding-top: 15px;
    gap: 5px;
    border-top: solid 1px #ECECEC;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 760px) {
        margin-top: 40px;
        padding: 20px;
        padding-top: 35px;
        gap: 10px;
    }
`;
const DetailsSubContainer = styled.div`
    margin-top: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 760px) {
        margin-top: 20px;
    }
`;
const JobOfferNumber = styled.label`
    margin: 0%;
    font-size: 10px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Bold};
    color: #101214;

    @media (min-width: 760px) {
        font-size: 13px;
    }
`;
const IconWrapper = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #101214;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 760px) {
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
`;

export const OrganisationCard:FC<Props> = ({
    onCardPress
})=> {

    const { isMobile } = useScreenSize();

    return (
        <Card>
            <OrganisationImg 
                src="https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.png" 
                alt='organisation logo'
            />

            <OrganisationName>Apple</OrganisationName>

            <Details>
                <SimpleBadge>💻 Informatique</SimpleBadge>
                <SimpleBadge>🇨🇮 Abidjan, Côte-d’Ivoire</SimpleBadge>
                <SimpleBadge>👥 Entre 50 et 250 salariés</SimpleBadge>

                <DetailsSubContainer>
                    <JobOfferNumber>
                        4 offres
                    </JobOfferNumber>

                    <IconWrapper onClick={onCardPress}>
                        <Icon 
                            iconName={IconNameEnum.arrowRight}
                            size={isMobile ? '1x' : '2x'}
                            color="#fff"
                        />
                    </IconWrapper>
                </DetailsSubContainer>
            </Details>
        </Card>
    )
}