import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    
    margin-top: 135px;
    background-color: #EFECE6;
    padding: 10px;
    padding-bottom: 100px;

    @media (min-width: 760px){

    }
`;

export const CheckboxWrapper = styled.div`
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 47px;
`;

export const ThanksContainer = styled.div`
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const Thanks = styled.p`
    display: flex;
    align-items: flex-end;
    gap: 5px;
    margin: 0%;
    text-align: center;
    font-size: 12px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
`;
export const Bolder = styled.p`
    margin: 0%;
    color: #101214;
    font-size: 12px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
`;
