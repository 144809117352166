import React from "react";
import './styles.css';
import { ISectionShadow } from "./interface";

export const SectionShadow:React.FC<ISectionShadow> = ({
    children,
    className,
})=> {

    return (
        <div className={`tf_sectionShadow ${className}`}>
            {children}
        </div>
    )
} 