import React from "react";
import { RecruiterAccount_RDVComponent } from "./RecruiterAccount_RDV.component";

export const RecruiterAccount_RDV:React.FC = ()=> {



    return (
        <RecruiterAccount_RDVComponent />
    )
}
