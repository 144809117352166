import { 
    saveAllPosts,
    saveOrganisationPosts,
    saveUserTalentPosts,
} from "@data/reducers";
import { 
    fetchGetOrganisationPostRequest,
    fetchGetPostRequest, 
    fetchGetTalentResponseToJobRequestRequest, 
    fetchSaveOrganisationPostRequest, 
    fetchUpdatedOrganisationPostRequest,
} from "@data/services";
import { AppDispatch } from "@data/store";
import { FetchResponseType, Post } from "@utilities/types";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export const usePostsDispatch = () => {

    const dispatch = useDispatch<AppDispatch>();

    const onGetAllPostRequest = useCallback(async() => {
        const req:FetchResponseType = await fetchGetPostRequest();
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const allPost:Post[] = req.data;
        dispatch(saveAllPosts(allPost));
    }, [dispatch]);

    const onGetOrganisationPostsRequest = useCallback(async(organisationID: string) => {
        const req:FetchResponseType = await fetchGetOrganisationPostRequest(organisationID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const organisationPost:Post[] = req.data;
        dispatch(saveOrganisationPosts(organisationPost));
    }, [dispatch]);

    const onSaveOrganisationPostRequest = useCallback(async(post: Post) => {
        const req:FetchResponseType = await fetchSaveOrganisationPostRequest(post);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        return 'success !';
    }, []);

    const onUpdatedOrganisationPostRequest = useCallback(async(post: Post) => {
        const req:FetchResponseType = await fetchUpdatedOrganisationPostRequest(post);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        return 'success !';
    }, []);
    
    const onGetTalentResponseToJobRequest = useCallback(async(talentID: string)=> {
        const req:FetchResponseType = await fetchGetTalentResponseToJobRequestRequest(talentID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const userTalentPost:Post[] = req.data;
        dispatch(saveUserTalentPosts(userTalentPost));
    }, [dispatch]);

    const onResetPostsRequest = useCallback(()=> {
        dispatch(saveAllPosts([]));
        dispatch(saveOrganisationPosts([]));
        dispatch(saveUserTalentPosts([]));
    }, [dispatch]);

    return {
        onGetAllPostRequest,
        onGetOrganisationPostsRequest,
        onSaveOrganisationPostRequest,
        onUpdatedOrganisationPostRequest,
        onGetTalentResponseToJobRequest,
        onResetPostsRequest,
    };
};
