// FR: Définit une fonction pour formater une date dans un format lisible, en utilisant les paramètres de localisation.
// EN: Defines a function to format a date into a readable format, using locale settings.
export const formatDate = (
    date: any, 
    locale: string = 'default' // Utilisation de 'fr-FR' pour formater la date en français
): string => {

    date = new Date(date);

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    return new Intl.DateTimeFormat(locale, options).format(date);
}

export const formatDateInput = (
    date: any
): string => {

    date = new Date(date);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const getMonthName = (monthNumber: number): string => {
    const months = [
        "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
        "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ];

    if (monthNumber < 1 || monthNumber > 12) {
        throw new Error("Le nombre doit être compris entre 1 et 12.");
    }

    return months[monthNumber - 1];
}

// FR: Définit une fonction pour formater l'heure dans un format lisible, en utilisant les paramètres de localisation.
// EN: Defines a function to format time into a readable format, using locale settings.
export const formatTime = (
    date: any, 
    locale: string = 'default'
): string => {

    date = new Date(date);

    const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    };

    return new Intl.DateTimeFormat(locale, options).format(date);
}

export const getMonth = (date: Date)=> {
    const formatteur = new Intl.DateTimeFormat('fr', { month: 'numeric' });
    return Number(formatteur.format(date));
}

export const getYear = (date: Date)=> {
    const formatteur = new Intl.DateTimeFormat('fr', { year: 'numeric' });
    return Number(formatteur.format(date));
}  
  
