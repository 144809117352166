import React from "react";
import './styles.css';
import { IRdvCard } from "./interface";
import { SectionShadow } from "../../organismes";
import { Paragraph, Title } from "../../atomes";
import { Button } from "../../molecules";
import { formatDate } from "@utilities/functions";

export const RdvCard:React.FC<IRdvCard> = ({
    rdvInfos
})=> {


    return (
        <SectionShadow
            className="ft_rdvCard_shadow_section"
        >
            <div className="ft_rdvCard_section">
                <Title
                    label={rdvInfos.organisationName}
                    variant='h_xs'
                    className="ft_rdvCard_subSection_title"
                />
                <div className="ft_rdvCard_subSection_txt_group">
                    <Paragraph
                        label={`Le ${formatDate(rdvInfos.appointmentDate)} avec`}
                    />
                    <Paragraph 
                        label={rdvInfos.interlocutor}
                        weight='bold'
                        className="ft_rdvCard_txt"
                    />
                </div>
                <Paragraph 
                    label={rdvInfos.canalType}
                />

                <div className="ft_rdvCard_subSection">
                    <Button
                        label="refuser"
                        onButtonPress={()=> {}}
                        customClassname="ft_refuseRdv_button_container"
                    />
                    <Button 
                        label="accepter"
                        onButtonPress={()=> {}}
                        customClassname="ft_acceptRdc_button_container"
                    />
                </div>
            </div>
        </SectionShadow>
    )
} 