import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    
    margin-top: 135px;
    background-color: #EFECE6;
    padding-bottom: 100px;

    @media (min-width: 760px){

    }
`;

export const TestCard = styled.div<{color: string, bgColor?: string}>`
    position: relative;
    width: 100%;
    height: 90px;
    background-color: ${props=> props.bgColor || '#fff'};
    padding: 10px 5px 10px 5px;
    border: solid 5px ${props=> props.color};
    margin-bottom: 20px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    @media (min-width: 760px){
        height: 152px;
        padding: 35px 30px 35px 30px;
        border: solid 7px ${props=> props.color};
        margin-bottom: 40px;
        border-radius: 25px;
    }
`;
export const TestCardSolution = styled.div`
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    border-radius: 8px;
    background-color: #00000060;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 760px){
        border-radius: 19px;
    }
`;
export const TestCardBadge = styled.div<{color: string}>`
    position: absolute;
    z-index: 99;
    right: 20px;
    top: -13px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_SEMI_BOLD};
    font-size: 12px;
    color: #101214;
    height: 20px;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${props=> props.color};
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 760px){
        right: 40px;
        top: -22px;
        font-size: 18px;
        height: 35px;
        border-radius: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const TestSubCard = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

export const Details = styled.div`
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;

    @media (min-width: 760px){
        margin-left: 20px;
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background-color: #101214;
    cursor: pointer;

    @media (min-width: 760px){
        height: 60px;
        width: 60px;
        border-radius: 60px;
    }
`;

export const Image = styled.img`
    width: 45px;
    height: 45px;
    margin-left: 5px;
    border-radius: 8px;
    object-fit: cover;

    @media (min-width: 760px){
        height: 90px;
        width: 90px;
    }
`;

export const TestCardTitle = styled.p`
    margin: 0%;
    font-size: 14px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};

    @media (min-width: 760px){
        font-size: 22px;
    }
`;
export const TestCardSubTitle = styled.p`
    margin: 0%;
    font-size: 12px;
    margin-top: 4px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_SEMI_BOLD};

    @media (min-width: 760px){
        font-size: 18px;
        margin-top: 10px;
    }
`;

export const ModalContainer = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const DisclaimerTitle = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
    font-size: 22px;
    margin-bottom: 30px;
    color: #101214;
    text-align: center;
`;

export const DisclaimerTxt = styled.p`
    margin: 0%;
    margin-bottom: 20px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_SEMI_BOLD};
    font-size: 16px;
    color: #101214;
    text-align: center;

    @media (min-width: 760px){
        width: 70%;
    }
`;
export const DisclaimerLabel = styled.label`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
`;

export const ScoreTxt = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Black};
    font-size: 22px;
    color: #101214;
    text-align: center;
`;

export const LevelResult = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
    font-size: 16px;
    color: #101214;
    text-align: center;
`;

export const ModalSection = styled.div`
    margin-top: 50px;
    width: 100%;
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ModalQuestion = styled.p`
    margin: 0%;
    margin-top: 10px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
    font-size: 16px;
    color: #101214;
    text-align: center;
`;

export const QuestionSection = styled.div`
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 70px;
`;

export const QuestionWrapper = styled.div`
    cursor: pointer;
    border: solid 1px #F1F1EF;
    border-radius: 8px;
    padding: 15px;
    background-color: #ffffff;
`;

export const QuestionTxt = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_REGULAR};
    font-size: 14px;
    color: #101214;
`;

export const DisplayAllQuestion = styled.p`
    text-align: center;
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
    font-size: 14px;
    color: #101214;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 30px;
`;

export const ModalTxt = styled.p`
    margin: 0%;
    margin-top: 30px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_SEMI_BOLD};
    font-size: 16px;
    color: #101214;
    text-align: center;
`;

export const QuestionListWrapper = styled.div`
    width: 100%;
    padding: 0px 40px 0px 40px;
    margin-top: 45px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
`;

export const RoundedQuestionNumber = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: solid 1px #CECECE;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #000000;
    font-size: 19px;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
`;

export const ResultCard = styled.div`
    width: 100%;
    background-color: #fff;
    border: solid 2px #F1F1EF;
    border-radius: 8px;
    padding: 15px;
    cursor: pointer;
`;

export const ResultCardHead = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const ResultCardHeadQuestionLabel = styled.p`
    margin: 0%;
    font-size: 14px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
`;

export const ResultCardHeadQuestionValue = styled.p`
    margin: 0%;
    font-size: 14px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
`;

export const ResultCardBody = styled.div`
    
`;

export const ResponseSection = styled.div`
    padding: 10px 5px 10px 5px;
    margin-top: 10px;
`;

export const ResponseTxt = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_REGULAR};
    color: #101214;
    font-size: 14px;
    padding: 10px 10px 10px 10px;
    margin-bottom: 6px;
    border-radius: 8px;
`;

export const ExplicationTxt = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_REGULAR};
    font-style: italic;
    font-size: 14px;
    color: #101214;
`;

export const Section = styled.section`
    width: 100%;
    min-height: ${window.innerHeight - 458}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Text = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
    width: 80%;
    color: #101214;
`;

export const LevelSection = styled.div`
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    background-color: #fff;
`;

export const WarningText = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_REGULAR};
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 90%;
    color: #FFA86F;

    @media (min-width: 760px){
        width: 50%;
    }
`;
export const WarningTextLabel = styled.label`
    font-family: ${FontFamilyEnum.SF_Pro_Display_Black};
`;
