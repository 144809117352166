import { FC, useCallback, useMemo, useState } from "react";
import { useScreenSize, useTranslation } from "@utilities/hooks";
import { ColorRing } from 'react-loader-spinner';

import { SubMenu, TabNavigation } from "@connectedComponents";
import { 
    Countdown, Modal, ModalSubTitle, 
    ModalTitle, PageHeadSection, 
    SimpleButton, SimpleSelect,
} from "@components";
import { qcmTestType, UserQCMSolution } from "@utilities/types";
import { useController } from "../useControllers";
import { Container, ModalContainer, Section, Text, WarningText, WarningTextLabel} from "./styles";
import { ModalStepsEnum, useControllers } from "./useControllers";
import { 
    DisclaimerComponent, QuestionListComponent, 
    QuizzComponent, TestCardComponent, 
    TestCorrectionComponent, TestSolutionCard,
} from "./components";

export const TalentAccount_TESTS:FC = ()=> {

    const translate = useTranslation();
    const { itemsMenuNav } = useController();
    const { isDesktop } = useScreenSize();

    const { 
        isLoading,
        generateQcmIsOk,
        talentQCMs,
        talentQCMsResult,
        modalSteps,
        isRespondedAllQuestion,
        startCountdown,
        selectedQCM,
        currentQuestion,
        currentQuestionIndex,
        filteredQCMs,
        filteredQCMResults,
        filterOptions,
        selectedTestFilter, 
        setSelectedTestFilter,
        handleValidateQcm,
        handleNextQuestion,
        handleCloseContentModal,
        handleStartTalentEvaluation,
        handleSelectedResponse,
        setSelectedQCM,
        setCountdownValue,
        setModalSteps,
        setCurrentQuestion,
        setCurrentQuestionIndex,
        setStartCountdown,
        getHighestLevelWithHighScore,
    } = useControllers();

    const [isVisibleResponse, setIsVisibleResponse] = useState<qcmTestType | null>(null);
    const [selectedQCMSolution, setSelectedQCMSolution] = useState<UserQCMSolution | null>(null);
    
    const userLevel = useMemo(()=> getHighestLevelWithHighScore(), [getHighestLevelWithHighScore]);

    const renderLoader = useCallback(()=> (
        <ColorRing
            visible={true}
            height="25"
            width="25"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#fff', '#fff', '#fff', '#fff', '#fff']}
        />
    ), [])

    const renderModalContent = useCallback(()=> {
        const countDown = ()=> (
            <Countdown
                minutes={selectedQCM?.time || 10}
                isStart={startCountdown}
                handleCountdownChange={setCountdownValue}
            />
        );

        switch (modalSteps) {
            case ModalStepsEnum.DISCLAIMER:
                return (
                    <DisclaimerComponent 
                        selectedQCM={selectedQCM}
                        setModalSteps={setModalSteps}
                        setCurrentQuestion={setCurrentQuestion}
                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                        setStartCountdown={setStartCountdown}
                    />
                );

            case ModalStepsEnum.QUIZZ:
                const responses = currentQuestion ? [
                    currentQuestion.reponse_a,
                    currentQuestion.reponse_b,
                    currentQuestion.reponse_c,
                    currentQuestion.reponse_d,
                ] : [];

                return (
                    <QuizzComponent 
                        responses={responses}
                        currentQuestion={currentQuestion}
                        currentQuestionIndex={currentQuestionIndex}
                        isRespondedAllQuestion={isRespondedAllQuestion}
                        countDown={countDown}
                        handleSelectedResponse={handleSelectedResponse}
                        handleNextQuestion={handleNextQuestion}
                        handleValidateQcm={handleValidateQcm}
                        setModalSteps={setModalSteps}
                    />
                );
            
            case ModalStepsEnum.QUESTION_LIST:
                return (
                    <QuestionListComponent 
                        selectedQCM={selectedQCM}
                        countDown={countDown}
                        setCurrentQuestion={setCurrentQuestion}
                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                        setModalSteps={setModalSteps}
                    />
                )
        
            default:
                return (
                    <ModalContainer>
        
                    </ModalContainer>
                );
        }
    }, [
        modalSteps, startCountdown, 
        selectedQCM, currentQuestion,
        currentQuestionIndex, isRespondedAllQuestion,
        handleNextQuestion, setCountdownValue, 
        handleValidateQcm, setModalSteps, 
        setCurrentQuestion, setCurrentQuestionIndex, 
        setStartCountdown, handleSelectedResponse,
    ]);

    return (
        <TabNavigation customBG="#EFECE6">
            <>
                <SubMenu
                    navMenu={itemsMenuNav}
                    active={itemsMenuNav[1].label}
                />

                <Container>
                {
                    (talentQCMs.length > 0 || talentQCMsResult.length > 0) ? (
                        <Section style={{justifyContent: 'flex-start'}}>
                            <PageHeadSection>
                                <ModalTitle style={
                                    isDesktop ? {
                                        textAlign: 'center',
                                        marginTop: 30
                                    } 
                                    : {}
                                    }>
                                    Tests de compétences
                                </ModalTitle>

                                <ModalSubTitle style={isDesktop ? {textAlign: 'center'} : {}}>
                                    Mesurez et validez vos compétences grâce à une variété de tests adaptés à votre profil.
                                </ModalSubTitle>

                                {/* {
                                    userLevel && (
                                        <LevelSection>
                                            <SubTitle>
                                                Vous avez validez le niveau : 
                                            </SubTitle>
                                            <SimpleBadge>{userLevel}</SimpleBadge>
                                        </LevelSection>
                                    )
                                } */}

                                <SimpleSelect 
                                    placeholder="Filtré par : "
                                    value={selectedTestFilter}
                                    onChange={setSelectedTestFilter} 
                                    options={filterOptions}
                                    styles={{
                                        height: 43, 
                                        width: isDesktop ? '30%' : '100%',
                                        marginLeft: isDesktop ? '35%' : 0,
                                    }}                                    
                                />
                            </PageHeadSection>
                            {
                                filteredQCMs.map((el, i)=> {
                                    return (
                                        <TestCardComponent 
                                            index={i}
                                            test={el}
                                            setSelectedQCM={setSelectedQCM}
                                        />
                                    );
                                })
                            }

                            {
                                filteredQCMResults.map((el, i)=> {
                                    return (
                                        <TestSolutionCard 
                                            index={i}
                                            solution={el}
                                            setSelectedQCMSolution={setSelectedQCMSolution}
                                        />
                                    );
                                })
                            }
                        </Section>
                    ) : (
                        <Section>
                            <Text>
                                {
                                    isLoading ? 'Patientez quelques instants, le temps que le ou les tests se créent (vous pouvez quitter la page) 📚'
                                    : 'Passez un test de compétence personnel basé sur votre CV 🧠'
                                }
                            </Text>

                            <SimpleButton style={{paddingLeft: 20, paddingRight: 20}}
                                onClick={handleStartTalentEvaluation}
                            >
                                {isLoading ? renderLoader() : 'Générer un test'}
                            </SimpleButton>

                            {
                                !generateQcmIsOk && (
                                    <WarningText>
                                        Pour générer votre test de compétence, veuillez d’abord remplir les sections <WarningTextLabel>‘Informations personnelles’</WarningTextLabel>, <WarningTextLabel>‘Job recherché’</WarningTextLabel> et ajouter au moins une expérience dans <WarningTextLabel>‘Expériences’</WarningTextLabel>.
                                    </WarningText>
                                )
                            }
                        </Section>
                    )
                }
                </Container>

                <Modal
                    isVisible={!!selectedQCM}
                    onClose={handleCloseContentModal}
                >
                    {renderModalContent()}
                </Modal>

                <Modal
                    isVisible={!!selectedQCMSolution}
                    onClose={()=> setSelectedQCMSolution(null)}
                >
                    <TestCorrectionComponent 
                        selectedQCMSolution={selectedQCMSolution}
                        isVisibleResponse={isVisibleResponse}
                        setIsVisibleResponse={setIsVisibleResponse}
                    />
                </Modal>
            </>
        </TabNavigation>
    )
}
