import React from 'react';
import { NavigationPathsEnum } from "@utilities/enums";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  Home,
  FindJobOffers,
  FindOrganisation,
  FindTalent,

  TalentAccount,
  TalentAccount_Settings,
  TalentAccount_OffersSave,
  TalentAccount_RDV,
  TalentAccount_TESTS,
  TalentAccount_UnsolicitedApplications,

  RecruiterAccount,
  RecruiterAccount_JobOffers,
  RecruiterAccount_RDV,
  RecruiterAccount_Test,
  RecruiterAccount_Matching,
  RecruiterAccount_UnsolicitedApplication,
  RecruiterAccount_collaboratorManagement,

  NotFound,
  OrganisationPage,
  CreateNewPasswordPage,
} from '@pages';
  
// FR: Composant fonctionnel React pour la gestion des routes de navigation.
// EN: React functional component for managing navigation routes.
export const Navigations: React.FC = () => {
  
  // FR: Crée un routeur en définissant les chemins et les composants associés.
  // EN: Create a router by defining paths and their associated components.
  const router = createBrowserRouter([
    {
      path: NavigationPathsEnum.HOME,
      element: <Home />,
    },
    // {
    //   path: NavigationPathsEnum.FIND_JOB_OFFER,
    //   element: <FindJobOffers />,
    // },
    // {
    //   path: NavigationPathsEnum.Find_ORGANISATION,
    //   element: <FindOrganisation />,
    // },
    // {
    //   path: NavigationPathsEnum.ORGANISATION_PAGE,
    //   element: <OrganisationPage />,
    // },
    {
      path: NavigationPathsEnum.FIND_TALENT,
      element: <FindTalent />,
    },
    {
      path: NavigationPathsEnum.CREATE_NEW_PASSWORD,
      element: <CreateNewPasswordPage />,
    },

    {
      path: NavigationPathsEnum.TALENT_ACCOUNT,
      element: <TalentAccount />,
    },
    {
      path: NavigationPathsEnum.TALENT_ACCOUNT_SETTINGS,
      element: <TalentAccount_Settings />,
    },
    {
      path: NavigationPathsEnum.TALENT_ACCOUNT_OFFERS_SAVE,
      element: <TalentAccount_OffersSave />,
    },
    {
      path: NavigationPathsEnum.TALENT_ACCOUNT_RDV,
      element: <TalentAccount_RDV />,
    },
    {
      path: NavigationPathsEnum.TALENT_ACCOUNT_TESTS,
      element: <TalentAccount_TESTS />,
    },
    {
      path: NavigationPathsEnum.TALENT_ACCOUNT_UNSOLICITED_APPLICATIONS,
      element: <TalentAccount_UnsolicitedApplications />,
    },

    {
      path: NavigationPathsEnum.RECRUITER_ACCOUNT,
      element: <RecruiterAccount />,
    },
    {
      path: NavigationPathsEnum.RECRUITER_ACCOUNT_JOB_OFFERS,
      element: <RecruiterAccount_JobOffers />,
    },
    {
      path: NavigationPathsEnum.RECRUITER_ACCOUNT_RDV,
      element: <RecruiterAccount_RDV />,
    },
    {
      path: NavigationPathsEnum.RECRUITER_ACCOUNT_TEST,
      element: <RecruiterAccount_Test />,
    },
    {
      path: NavigationPathsEnum.RECRUITER_ACCOUNT_MATCHINGS,
      element: <RecruiterAccount_Matching />,
    },
    {
      path: NavigationPathsEnum.RECRUITER_ACCOUNT_UNSOLICITED_APPLICATIONS,
      element: <RecruiterAccount_UnsolicitedApplication />,
    },
    {
      path: NavigationPathsEnum.RECRUITER_ACCOUNT_COLLAB_MANAGEMENT,
      element: <RecruiterAccount_collaboratorManagement />,
    },

    {
      path: NavigationPathsEnum.NOT_FOUND,
      element: <NotFound />,
    },
  ]);

  // FR: Retourne le fournisseur du routeur pour encapsuler l'application.
  // EN: Return the router provider to encapsulate the application.
  return (
    <RouterProvider 
      // FR: Attribut pour les tests. 
      // EN: Attribute for testing.
      data-testid="navigations"
      router={router} 
    />
  );
};