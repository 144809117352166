import { FC } from "react";
import { AnimatedGradianButton } from "./animatedGradianButton";
import styled from "styled-components";
import { FontFamilyEnum } from "@utilities/enums";

interface IAnimatedInputWithButton {
    buttonName: string
    placeholder: string
    value: string
    onChange: (e: string)=> void
    onButtonPress: ()=> void
}

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    padding-left: 17px;
    width: 90%;
    height: 45px;
    border-radius: 40px;
    background-color: #ffffff;
    -webkit-box-shadow: -9px 17px 39px 0px rgba(0,0,0,0.11); 
    box-shadow: -9px 17px 39px 0px rgba(0,0,0,0.11);

    @media (min-width: 950px) {
        padding: 8px;
        padding-left: 24px;
        width: 576px;
        height: 60px;
        border-radius: 60px;
    }
`;

const Label = styled.label`
    font-size: 14px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    @media (min-width: 950px) {
        font-size: 20px;
    }
`;

const Input = styled.input`
    height: 25px;
    outline: none;
    width: 62%;
    border: none;
    font-size: 12px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    &::placeholder {
        color: #c3c3c3;
        font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
        font-size: 12px;
    }

    @media (min-width: 950px) {
        height: 30px;
        font-size: 16px;
        width: 385px;

        &::placeholder {
            font-size: 16px;
        }
    }
`;

export const AnimatedInputWithButton:FC<IAnimatedInputWithButton> = ({
    buttonName,
    placeholder,
    value,
    onChange,
    onButtonPress,
})=> {

    return (
        <InputContainer>
            <Label>
                🔍
            </Label>
            
            <Input 
                placeholder={placeholder}
                value={value}
                onChange={e=> onChange(e.target.value)}
            />

            <AnimatedGradianButton 
                onClick={onButtonPress}
            >
                {buttonName}
            </AnimatedGradianButton>
        </InputContainer>
    )
}