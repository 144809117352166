import { FC } from "react";
import styled, { keyframes } from "styled-components";
import { Icon } from "./icon";
import { FontFamilyEnum, IconNameEnum } from "@utilities/enums";

interface item {
    label: string, 
    link: any, 
    icon?: any,
    isVisible: boolean
}

interface ISidebar {
    isSidebarOpen: boolean
    items: item[]
    onClose: ()=> void
}

interface ISidebarElement {
    isOpen: boolean
}

export const Sidebar:FC<ISidebar> = ({
    items,
    isSidebarOpen,
    onClose,
})=> {

    return (
        <>
            <SidebarElement isOpen={isSidebarOpen}>
                <IconWrapper>
                    <Icon 
                        iconName={IconNameEnum.close}
                        size='lg'
                        onPress={onClose}
                    />
                </IconWrapper>
                
                <SidebarItemsWrapper>
                    {
                        isSidebarOpen && items.map((item, index) => {
                            if(!item.isVisible) return;
                            return (
                                <SidebarItem 
                                    key={index} delay={index * 0.15}
                                    onClick={item.link}
                                >
                                    {item.label} {item.icon && (<Icon iconName={item.icon} />)}
                                </SidebarItem>
                            )
                        })
                    }
                </SidebarItemsWrapper>
            </SidebarElement>

            <Overlay isOpen={isSidebarOpen} onClick={onClose} />
        </>
    )
}

const SidebarElement = styled.div<ISidebarElement>`
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 300px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    background-color: #F9F9F9;
    padding: 20px;
    transform: ${props => (props.isOpen ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
`;

const Overlay = styled.div<ISidebarElement>`
    display: ${props => (props.isOpen ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000060;
    z-index: 999;
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const SidebarItemsWrapper = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: end;
`;

const fadeInRight = keyframes`
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const SidebarItem = styled.div<{ delay: number }>`
    opacity: 0;
    animation: ${fadeInRight} 1.5s ease-out forwards;
    animation-delay: ${props => props.delay}s;
    margin-bottom: 25px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
    font-size: 18px;
    color: #101214;
`;