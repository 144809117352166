import { useCallback, useEffect, useState } from "react";
import { RecruiterAccount_collaboratorManagementComponent } from "./RecruiterAccount_collaboratorManagement.component";
import { FetchRequestType, FetchResponseType, UserRecruiter } from "@utilities/types";
import { EnumPaths } from "@utilities/enums";
import { fetchRequest } from "@utilities/requests";
import { useRecruiterSelectors } from "@utilities/hooks";

export const RecruiterAccount_collaboratorManagement:React.FC = ()=> {

    const { recruiter } = useRecruiterSelectors();
    const [collabsList, setCollabsList] = useState<UserRecruiter[]>([]);

    const onGetCollab = useCallback(async()=> {
        try {
            const requestConfig:FetchRequestType = {
                route: `${EnumPaths.GET_ALL_USERS_RECRUITERS_BY_ORGABISATION}?organisationID=${recruiter?._organisationID}`,
                method: 'GET',
            };

            const response:FetchResponseType = await fetchRequest(requestConfig);
            
            if(response.code === 200){
                setCollabsList(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }, [recruiter]);

    const onCreateCollab = useCallback(async(collab: UserRecruiter)=> {
        try {
            const requestConfig:FetchRequestType = {
                route: EnumPaths.CREATE_USER_RECRUITER,
                method: 'POST',
                data: collab
            };

            const response:FetchResponseType = await fetchRequest(requestConfig);
            
            if(response.code === 200){
                onGetCollab();
                alert('Collab ajouter avec succes !');
            }
        } catch (error) {
            console.log(error);
        }
    }, [onGetCollab]);

    useEffect(()=> {
        onGetCollab();
    }, [])

    return (
        <RecruiterAccount_collaboratorManagementComponent 
            recruiter={recruiter!}
            collabsList={collabsList}
            onCreateCollab={onCreateCollab}
        />
    )
}
