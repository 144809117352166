import React, { useCallback } from "react";
import './styles.css';
import { ITalentAccount_RDV } from "./interfaces";
import { useNavigation, useTranslation } from "@utilities/hooks";
import { TabNavigationWithSubHead } from "@connectedComponents";
import { NavigationPathsEnum } from "@utilities/enums";
import { RdvCard } from "@components";
import { EnumRdvCanalType } from "@utilities/types";

export const TalentAccount_RDVComponent:React.FC<ITalentAccount_RDV> = ({

})=> {

    const translate = useTranslation();
    const {navigateTo} = useNavigation();

    const handleGoBack = useCallback(()=> {
        navigateTo(NavigationPathsEnum.TALENT_ACCOUNT);
    }, [navigateTo]);

    return (
        <TabNavigationWithSubHead
            pageTitle='Mes rendez-vous'
            onGoBackPress={handleGoBack}
        >
            <div className="ft_talentAccountRDV_container">
                {
                    [0, 0, 0].map((el, i)=> {
                        return (
                            <div
                                key={i}
                                className="ft_talentAccountRDV_itemContainer"
                            >
                                <RdvCard 
                                    rdvInfos={{
                                        organisationName: 'Byme entreprise',
                                        canalType: EnumRdvCanalType.meet,
                                        interlocutor: 'John Doe',
                                        _userID: 'string',
                                        _organisationID: 'string',
                                        _recruiterID: 'string',
                                        userTalentConfirmed: false,
                                        appointmentDate: new Date(),
                                    }}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </TabNavigationWithSubHead>
    )
}
