import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

interface IAnimatedText {
    size?: string
    font?: FontFamilyEnum
    marginLeft?: string
    marginRight?: string
}

export const AnimatedText = styled.p<IAnimatedText>`
    margin: 0%;
    font-size: ${props => props.size || '16px'};
    margin-left: ${props => props.marginLeft || '0px'};
    margin-right: ${props => props.marginRight || '0px'};
    font-family: ${props => props.font || FontFamilyEnum.PlusJakartSans_Regular};
    background: linear-gradient(to right, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 500% auto;
    animation: animetedText 5s ease infinite;

    @keyframes animetedText {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
`;