import styled from "styled-components";

export const Page = styled.section`
    flex: 1;
    padding-top: 70px;
    padding-bottom: 70px;
    min-height: calc(100vh - 233px);
    position: relative;

    @media (min-width: 760px) {
        padding-top: 80px;
        padding-bottom: 80px;
        min-height: calc(100vh - 298px);
    }
`;