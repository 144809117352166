import { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { SimpleButton, Input, ToggleSwitch } from "@components";
import { FontFamilyEnum, NavigationPathsEnum } from "@utilities/enums";
import { FormWrapper, InputLabel, InputWrapper } from "./common";
import { useNavigation, useRecruiterDispatch, useRecruiterSelectors, useTalentDispatch, useTalentSelectors } from "@utilities/hooks";

interface Props {
    onCloseModal: ()=> void
    onForgotPassword: ()=> void
}

const LinkWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;
const Link = styled.label`
    color: #C3C3C3;
    margin-top: 10px;
    font-size: 11px;
    cursor: pointer;
    font-family: ${FontFamilyEnum.PlusJakartSans_Regular};

    @media (min-width: 760px){
        font-size: 12px;
    }
`;
const SwitchWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
`;

export const SigninForm:FC<Props> = ({
    onCloseModal,
    onForgotPassword,
})=> {

    const { talent } = useTalentSelectors();
    const { recruiter } = useRecruiterSelectors();
    const { onTalentSignin } = useTalentDispatch();
    const { onSigninRecruiter } = useRecruiterDispatch();
    const { navigateTo } = useNavigation();
    
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [isRecruteur, setIsRecruteur] = useState<boolean>(false);

    const handleSignin = useCallback(()=> {
        if(email && password){
            if(isRecruteur){
                onSigninRecruiter({mail: email, password});
            } else {
                onTalentSignin({mail: email, password});
            }
        }

        setEmail('');
        setPassword('');
        setIsRecruteur(false);
    }, [
        email, isRecruteur, password,
        onSigninRecruiter, onTalentSignin,
    ]);

    const handleNavigate = useCallback(()=> {
        if(talent){
            navigateTo(NavigationPathsEnum.TALENT_ACCOUNT);
        } else if(recruiter){
            navigateTo(NavigationPathsEnum.RECRUITER_ACCOUNT);
        }
    }, [navigateTo, recruiter, talent]);

    useEffect(()=> {
        if(talent || recruiter){
            onCloseModal();
            handleNavigate();
        }
    }, [
        talent, recruiter, 
        onCloseModal, handleNavigate,
    ]);

    return (
        <FormWrapper>
            <SwitchWrapper>
                <ToggleSwitch
                    checked={isRecruteur}
                    uncheckedLabel="Talent"
                    checkedLabel="Recruteur"
                    onChange={()=> setIsRecruteur(!isRecruteur)}
                />
            </SwitchWrapper>
            
            <InputWrapper>
                <InputLabel>
                    Adresse email*
                </InputLabel>
                <Input 
                    placeholder="Entrez l’adresse email"
                    value={email}
                    onChange={setEmail}
                />
            </InputWrapper>

            <InputWrapper>
                <InputLabel>
                    Mot de passe*
                </InputLabel>
                <Input 
                    inputType='password'
                    placeholder="Entrez le mot de passe"
                    value={password}
                    onChange={setPassword}
                />
                <LinkWrapper>
                    <Link onClick={onForgotPassword}>
                        Mot de passe oublié ?
                    </Link>
                </LinkWrapper>
            </InputWrapper>

            <SimpleButton onClick={handleSignin}>
                Se connecter
            </SimpleButton>
        </FormWrapper>
    )
}