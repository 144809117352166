import styled from "styled-components";

export const TabNavHeaderSection = styled.div`
    position: fixed;
    width: 100%;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0%;
    top: 0%;
    z-index: 999;
    border-bottom: solid 1px #ececec;
    padding: 15px 0px 15px 0px;

    @media (min-width: 760px) {
        padding: 15px 0px 15px 0px;
    }
`;

export const TabNavHeaderSubContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;

    @media (min-width: 760px) {
        padding: 0px 10px 0px 10px;
    }

    @media (min-width: 1200px) {
        padding: 0px 0px 0px 0px;
        justify-content: flex-start;
        width: 1200px;
    }
`;

export const MenuItemsSection = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
`;

export const MenuItems = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
