import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const CardPageSubTitle = styled.p`
    margin: 0%;
    margin-top: 5px;
    font-size: 13px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};

    @media (min-width: 760px){
        font-size: 16px;
    }
`;