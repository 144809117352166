import React, { useCallback } from "react";
import './styles.css';
import { IRecruiterAccount_UnsolicitedApplication } from "./interfaces";
import { useNavigation, useTranslation } from "@utilities/hooks";
import { TabNavigationWithSubHead } from "@connectedComponents";
import { NavigationPathsEnum } from "@utilities/enums";
import { Button, JobOffersCard, Paragraph } from "@components";
import { Accordion, AccordionTab } from "primereact/accordion";

export const RecruiterAccount_UnsolicitedApplicationComponent:React.FC<IRecruiterAccount_UnsolicitedApplication> = ({
    postList
})=> {

    const translate = useTranslation();
    const {navigateTo} = useNavigation();

    const handleGoBack = useCallback(()=> {
        navigateTo(NavigationPathsEnum.RECRUITER_ACCOUNT);
    }, [navigateTo]);

    console.log(postList);

    return (
        <TabNavigationWithSubHead
            pageTitle='Candidatures'
            onGoBackPress={handleGoBack}
        >
            <div className="ft_UnsolicitedApplicationContainer">
                <Accordion>
                    {
                        postList.map((el, i)=> {
                            return (
                                <AccordionTab 
                                    key={i}
                                    header={`${el.talent.firstname} ${el.talent.lastname}`}
                                >
                                    <div>
                                        <div style={{marginBottom: 20}}>
                                            <Paragraph 
                                                variant='p_sm'
                                                weight='light'
                                                label="Infos candidat :"
                                            />

                                            <Paragraph 
                                                label={`${el.talent.firstname} ${el.talent.lastname}`}
                                            />
                                            <Paragraph 
                                                label={el.talent.mail}
                                            />
                                        </div>

                                        <Paragraph 
                                            variant='p_sm'
                                            weight='light'
                                            label="Offre :"
                                        />
                                        <JobOffersCard
                                            postInfos={el.post}
                                            hideActionButtons
                                        />
                                    </div>
                                </AccordionTab>
                                
                            )
                        })
                    }
                </Accordion>
            </div>
        </TabNavigationWithSubHead>
    )
}
