import { ErrorType } from "./error.type"

export interface Appointment {
    _id?: string
    _userID: string
    _organisationID : string
    _recruiterID: string
    userTalentConfirmed?: boolean
    appointmentDate: Date
    createAt?: Date
}

export interface RdvInfosCardType extends Appointment {
    organisationName: string
    canalType: EnumRdvCanalType
    interlocutor: string
}

export enum EnumRdvCanalType {
    phone = 'Téléphone',
    meet = 'Visio',
    face2face = 'En présentiel',
}

export interface AppointmentInitialStateType {
    talentAppointment: Appointment[],
    recruiterAppointment: Appointment[],
}
