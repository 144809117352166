import React, { useCallback } from "react";
import './styles.css';
import { ITalentAccount_OffersSave } from "./interfaces";
import { useNavigation, useTranslation } from "@utilities/hooks";
import { TabNavigationWithSubHead } from "@connectedComponents";
import { NavigationPathsEnum } from "@utilities/enums";

export const TalentAccount_OffersSaveComponent:React.FC<ITalentAccount_OffersSave> = ({

})=> {

    const translate = useTranslation();
    const {navigateTo} = useNavigation();

    const handleGoBack = useCallback(()=> {
        navigateTo(NavigationPathsEnum.TALENT_ACCOUNT);
    }, [navigateTo]);

    return (
        <TabNavigationWithSubHead
            pageTitle='Mes offres sauvegarder'
            onGoBackPress={handleGoBack}
        >
            <div>
                
            </div>
        </TabNavigationWithSubHead>
    )
}
