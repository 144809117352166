import { CSSProperties, FC, useCallback, useState } from "react";
import { 
    CardPage, 
    CardPageSubTitle, 
    CardPageTitle,
    FixedModalFooter,
    Icon,
    Modal,
    ModalContainer,
    ModalTitle,
    SimpleButton,
    SimpleCheckbox,
} from "@components";
import { IconNameEnum } from "@utilities/enums";
import { Bolder, Thanks, ThanksContainer, CheckboxWrapper } from "../styles";

enum CheckboxLabels {
    label_1 = "Oui, j’ai trouvé le job de mes rêves !",
    label_2 = "Oui, je n’ai pas trouvé d’offres correspondant à mon profil.",
    label_3 = "Oui, la navigation sur le site ne me plaît pas.",
}

interface Props {
    simpleButtonOutlineStyle: CSSProperties
}

export const DeleteAccount:FC<Props> = ({
    simpleButtonOutlineStyle,
})=> {

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<CheckboxLabels>();

    const linearGradiantStyles:CSSProperties = {
        background: 'linear-gradient(90deg, rgba(248,80,50,1) 0%, rgba(60,20,16,1) 100%)',
    };

    const handleClose = useCallback(()=> {
        setIsVisible(false);
    }, []);

    const handleSave = useCallback(()=> {
        
    }, []);

    return (
        <>
            <CardPage>
                <CardPageTitle>
                    Supprimer mon compte
                </CardPageTitle>

                <CardPageSubTitle>
                    N’hésitez pas à contacter notre service client si vous avez besoin d’aide.
                </CardPageSubTitle>

                <SimpleButton 
                    onClick={()=> setIsVisible(true)}
                    style={linearGradiantStyles}
                >
                    <Icon iconName={IconNameEnum.trashCan} color="#fff" /> Supprimer mon compte
                </SimpleButton>
            </CardPage>

            <Modal 
                isVisible={isVisible}
                onClose={handleClose}
            >
                <>
                    <ModalContainer>
                        <ModalTitle>
                            Vous partez déjà?
                        </ModalTitle>

                        <CheckboxWrapper>
                            <SimpleCheckbox 
                                label={CheckboxLabels.label_1}
                                checked={selected === CheckboxLabels.label_1}
                                setChecked={()=> setSelected(CheckboxLabels.label_1)}
                            />
                            <SimpleCheckbox 
                                label={CheckboxLabels.label_2}
                                checked={selected === CheckboxLabels.label_2}
                                setChecked={()=> setSelected(CheckboxLabels.label_2)}
                            />
                            <SimpleCheckbox 
                                label={CheckboxLabels.label_3}
                                checked={selected === CheckboxLabels.label_3}
                                setChecked={()=> setSelected(CheckboxLabels.label_3)}
                            />
                        </CheckboxWrapper>

                        <ThanksContainer>
                            <Thanks>
                                Quoi que vous décidiez,
                            </Thanks>
                            <Thanks>
                                <Bolder>merci</Bolder> d’avoir utilisé <Bolder>Ohasis !</Bolder>
                            </Thanks>
                        </ThanksContainer>
                    </ModalContainer>

                    <FixedModalFooter>
                        <SimpleButton onClick={handleSave}>
                            Enregistrer
                        </SimpleButton>

                        <SimpleButton style={simpleButtonOutlineStyle}>
                            Annuler
                        </SimpleButton>
                    </FixedModalFooter>
                </>
            </Modal>
        </>
    )
}