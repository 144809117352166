import { useTalentDispatch, useTalentSelectors } from "@utilities/hooks";
import { useEffect } from "react";

export const useControllers = ()=> {

    const { talent, talentCV } = useTalentSelectors();
    const { 
        onUpdatedUserTalent, 
        onGetTalentCVRequest, 
        onSaveTalentCVRequest,
        onUpdatedTalentAvatarData,
    } = useTalentDispatch();

    useEffect(()=> {
        if(talent){
            onGetTalentCVRequest()
        }
    }, [talent]);

    return {
        talent,
        talentCV,
        onUpdatedUserTalent,
        onSaveTalentCVRequest,
        onUpdatedTalentAvatarData,
    }
}