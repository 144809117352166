import { FontFamilyEnum } from "@utilities/enums";
import { FC } from "react";
import styled from "styled-components";

interface Props {
    checked: boolean
    checkedLabel?: string
    uncheckedLabel?: string
    onChange: ()=> void
}

const ToggleSwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;
const Label = styled.label<{color?: string}>`
    margin: 0%;
    font-size: 16px;
    color: ${props=> props.color || '#101214'};
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px; /* Height of the circle */
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b0b0b0;
  transition: 0.4s;
  border-radius: 10px; /* Rounded corners */
  height: 20px; /* Height of the thin bar */
  margin-top: -6px; /* To center the bar vertically within the circle */

  &:before {
    position: absolute;
    content: "";
    height: 26px; /* Height of the circle */
    width: 26px;
    left: 0;
    top: 50%;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateY(-50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: #2b94e9;
  }

  &:checked + ${Slider}:before {
    transform: translate(24px, -50%); /* Move the circle to the right */
  }
`;

export const ToggleSwitch:FC<Props> = ({ 
    checked, 
    checkedLabel,
    uncheckedLabel,
    onChange,
}) => {
  return (
    <ToggleSwitchWrapper>
        {
            uncheckedLabel && (
                <Label color={!checked ? '#2b94e9': 'grey'}>
                    {uncheckedLabel}
                </Label>
            )
        }

        <Switch>
            <Input type="checkbox" checked={checked} onChange={onChange} />
            <Slider />
        </Switch>

        
        {
            checkedLabel && (
                <Label color={checked ? '#2b94e9': 'grey'}>
                    {checkedLabel}
                </Label>
            )
        }
    </ToggleSwitchWrapper>
  );
};
