import { CSSProperties, FC, useCallback, useState } from "react";
import { 
    CardPage, 
    CardPageSubTitle, 
    CardPageTitle,
    FixedModalFooter,
    Input,
    InputLabel,
    InputWrapper,
    Modal,
    ModalContainer,
    ModalSubTitle,
    ModalTitle,
    SimpleButton,
} from "@components";
import { useScreenSize } from "@utilities/hooks";

interface Props {
    simpleButtonStyle: CSSProperties
    simpleButtonOutlineStyle: CSSProperties
}

export const ChangePassword:FC<Props> = ({
    simpleButtonStyle,
    simpleButtonOutlineStyle,
})=> {

    const { isMobile } = useScreenSize();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmedNewPassword, setConfirmedNewPassword] = useState<string>('');

    const handleClose = useCallback(()=> {
        setIsVisible(false);
    }, []);

    const handleSave = useCallback(()=> {
        if(currentPassword && newPassword && confirmedNewPassword){
            if(newPassword === confirmedNewPassword){
                
            }
        }
    }, [confirmedNewPassword, currentPassword, newPassword]);

    return (
        <>
            <CardPage>
                <CardPageTitle>
                    Mot de passe
                </CardPageTitle>

                <CardPageSubTitle>
                    Créez un mot de passe fort et unique pour sécuriser votre compte.
                </CardPageSubTitle>

                <SimpleButton 
                    onClick={()=> setIsVisible(true)}
                    style={!isMobile ? simpleButtonStyle : {}}
                >
                    Changer mon mot de passe
                </SimpleButton>
            </CardPage>

            <Modal 
                isVisible={isVisible}
                onClose={handleClose}
            >
                <>
                    <ModalContainer>
                        <ModalTitle>
                            Mot de passe
                        </ModalTitle>
                        <ModalSubTitle>
                            Créez un mot de passe fort et unique pour sécuriser votre compte.
                        </ModalSubTitle>

                        <InputWrapper>
                            <InputLabel>
                                Mot de passe actuel*
                            </InputLabel>

                            <Input 
                                inputType='password'
                                placeholder="Entrez le mot de passe actuel"
                                value={currentPassword}
                                onChange={setCurrentPassword}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                Nouveau mot de passe*
                            </InputLabel>

                            <Input 
                                inputType='password'
                                placeholder="Entrez le nouveau mot de passe"
                                value={newPassword}
                                onChange={setNewPassword}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                Confirmez le nouveau mot de passe*
                            </InputLabel>

                            <Input 
                                inputType='password'
                                placeholder="Entrez la confirmation du mot de passe"
                                value={confirmedNewPassword}
                                onChange={setConfirmedNewPassword}
                            />
                        </InputWrapper>
                    </ModalContainer>

                    <FixedModalFooter>
                        <SimpleButton onClick={handleSave}>
                            Enregistrer
                        </SimpleButton>

                        <SimpleButton style={simpleButtonOutlineStyle}>
                            Annuler
                        </SimpleButton>
                    </FixedModalFooter>
                </>
            </Modal>
        </>
    )
}