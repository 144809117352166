export enum FontFamilyEnum {
    SF_Pro_Display_Black = 'SF_Pro_Display_Black',
    SF_Pro_Display_SEMI_BOLD = 'SF_Pro_Display_SEMI_BOLD',
    SF_Pro_Display_MEDIUM = 'SF_Pro_Display_MEDIUM',
    SF_Pro_Display_REGULAR = 'SF_Pro_Display_REGULAR',
    SF_Pro_Display_Bold = 'SF_Pro_Display_Bold',
    SF_Pro_Display_Heavy = 'SF_Pro_Display_Heavy',
    PlusJakartSans_Medium = 'PlusJakartSans_Medium',
    PlusJakartSans_Regular = 'PlusJakartSans_Regular',
    PlusJakartSans_SemiBold = 'PlusJakartSans_SemiBold',
    PlusJakartSans_Bold = 'PlusJakartSans_Bold',
}