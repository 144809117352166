import React from "react";
import './styles.css';
import { IcreateAndEditJobOffer } from "./interface";
import { Button, Calendar, Checkbox, Input, Paragraph, Select, Title } from "@components";

export const CreateAndEditJobOffer:React.FC<IcreateAndEditJobOffer> = ({
    isEditMode = false,
    entitle,
    city,
    country,
    startDate,
    minSalary,
    maxSalaryMonth,
    contratType,
    contratTypeList,
    workTime,
    workTimeList,
    teleworking,
    partialTeleworking,
    noTeleworking,
    description,
    setEntitle,
    setCity,
    setCountry,
    setStartDate,
    setMinSalary,
    setMaxSalaryMonth,
    setContratType,
    setWorkTime,
    setTeleworking,
    setPartialTeleworking,
    setNoTeleworking,
    setDescription,
    onValidateForm,
})=> {


    return (
        <div className="ft_CreateAndEditJobOffer_Container">
            <Title
                label={isEditMode ? "Modifier l'offre" : "Nouvelle offre"}
                variant='h_sm'
                weight='regular'
            />
            
            <div className="ft_CreateAndEditJobOffer_form_Container">
                <div className="ft_CreateAndEditJobOffer_form_Item_Container">
                    <Paragraph
                        variant='p_sm'
                        label="Intitulé"
                    />
                    <Input
                        placeholder="Intitulé"
                        value={entitle}
                        onChange={setEntitle}
                    />
                </div>

                <div className="ft_CreateAndEditJobOffer_form_Item_Container">
                    <Paragraph 
                        variant='p_sm'
                        label="Ville"
                    />
                    <Input 
                        placeholder="Ville"
                        value={city}
                        onChange={setCity}
                    />
                </div>

                <div className="ft_CreateAndEditJobOffer_form_Item_Container">
                    <Paragraph 
                        variant='p_sm'
                        label="Pays"
                    />
                    <Input 
                        placeholder="Pays"
                        value={country}
                        onChange={setCountry}
                    />
                </div>

                <div className="ft_CreateAndEditJobOffer_form_Item_Container">
                    <Paragraph 
                        variant='p_sm'
                        label="Début"
                    />
                    <Calendar 
                        placeholder="Début"
                        value={startDate}
                        onChange={setStartDate}
                    />
                </div>

                <div className="ft_CreateAndEditJobOffer_form_Item_Container">
                    <Paragraph 
                        variant='p_sm'
                        label="Salaire minimun"
                    />
                    <Input 
                        // inputType='number'
                        placeholder="Salaire min"
                        value={minSalary}
                        onChange={setMinSalary}
                    />
                </div>

                <div className="ft_CreateAndEditJobOffer_form_Item_Container">
                    <Paragraph 
                        variant='p_sm'
                        label="Salaire maximal"
                    />
                    <Input 
                        // inputType='number'
                        placeholder="Salaire max"
                        value={maxSalaryMonth}
                        onChange={setMaxSalaryMonth}
                    />
                </div>

                <div className="ft_CreateAndEditJobOffer_form_Item_Container">
                    <Paragraph 
                        variant='p_sm'
                        label="Type de contrat"
                    />
                    <Select 
                        placeholder="Type de contrat"
                        value={contratType}
                        onChange={setContratType}
                        options={contratTypeList}
                    />
                </div>

                <div className="ft_CreateAndEditJobOffer_form_Item_Container">
                    <Paragraph 
                        variant='p_sm'
                        label="Temps de travail"
                    />
                    <Select 
                        placeholder="Temps de travail"
                        value={workTime}
                        onChange={setWorkTime}
                        options={workTimeList}
                    />
                </div>

                <div className="ft_CreateAndEditJobOffer_form_Item_Container">
                    <Checkbox
                        label="Télétravail"
                        checked={teleworking}
                        onCheckboxPress={()=> setTeleworking(!teleworking)}
                    />
                    <Checkbox 
                        label="Télétravail partiel"
                        checked={partialTeleworking}
                        onCheckboxPress={()=> setPartialTeleworking(!partialTeleworking)}
                    />
                    <Checkbox 
                        label="Pas de télétravail"
                        checked={noTeleworking}
                        onCheckboxPress={()=> setNoTeleworking(!noTeleworking)}
                    />
                </div>

                <div className="ft_CreateAndEditJobOffer_form_Item_Container">
                    <Paragraph 
                        variant='p_sm'
                        label="Description"
                    />
                    <Input 
                        inputType='textarea'
                        placeholder="Description"
                        value={description}
                        onChange={setDescription}
                    />
                </div>
            </div>

            <Button 
                label={isEditMode ? "Mètre à jour" : "Creer l'offre"}
                onButtonPress={onValidateForm}
            />
        </div>
    )
} 