import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const FilterTextElement = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    font-size: 11px;
    margin-top: 10px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Bold};
`;