export function getColorByNumber(num: number): string {
    const colors: { [key: number]: string } = {
      0: '#a50026',
      1: '#d62f27',
      2: '#f46d43',
      3: '#fdad60',
      4: '#ebd494',
      5: '#fee08b',
      6: '#d9ef8b',
      7: '#a5d86a',
      8: '#66bd63',
      9: '#199750',
      10: '#006837',
    };
  
    if (num < 0 || num > 10) {
      throw new Error('Le chiffre doit être entre 0 et 10.');
    }
  
    return colors[num];
}
  