import { FC, useState, useEffect, ReactNode } from 'react';
import styled, { keyframes } from "styled-components";
import { Icon } from './icon';
import { IconNameEnum } from '@utilities/enums';

interface ModalProps {
  isVisible: boolean;
  children: ReactNode
  onClose: () => void;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${props => (props.isVisible ? fadeIn : fadeOut)} 0.3s forwards;
`;

const ModalContainer = styled.div<{ isVisible: boolean }>`
  position: relative;
  background: #F9F9F9;
  width: 100%;
  height: 100%;
  animation: ${props => (props.isVisible ? fadeIn : fadeOut)} 0.3s forwards;

  @media (min-width: 760px) {
    width: 55%;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 99;
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  padding: 20px;
  padding-top: 30px;
  width: 100%;
  height: 100%;
`;

export const Modal:FC<ModalProps> = ({ isVisible, children, onClose }) => {
  const [shouldRender, setShouldRender] = useState(isVisible);

  useEffect(() => {
    if (isVisible) {
      setShouldRender(true);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isVisible]);

  const handleAnimationEnd = () => {
    if (!isVisible) setShouldRender(false);
  };

  return shouldRender ? (
    <Overlay 
      isVisible={isVisible} 
      onAnimationEnd={handleAnimationEnd}
    >
      <ModalContainer isVisible={isVisible}>
        <CloseButton onClick={onClose}>
          <Icon iconName={IconNameEnum.close} size='lg' onPress={onClose} />
        </CloseButton>
        <ModalContent>
          {children}
        </ModalContent>
      </ModalContainer>
    </Overlay>
  ) : null;
};
