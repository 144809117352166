import { EnumContratType } from "@utilities/enums"

export interface Post {
    _id?: string
    _organisationID?: string
    _recruiterID?: string
    _qcmID?: string
    criteria: CriteriaType
    entitle: string
    description: string
    city: string
    country: string
    startAt: Date
    createAt?: Date
    isArchieved?: boolean
}

export interface PostInitialStateType {
    posts: Post[]
    organisationPosts: Post[]
    userTalentPosts: Post[]
}

export interface CriteriaType {
    minSalaryMonth: number
    maxSalaryMonth: number
    partialTeleworking: boolean
    teleworking: boolean
    noTeleworking: boolean
    contratType: EnumContratType
    workTime: EnumWorkTime
}

export enum EnumWorkTime {
    Travail_journee = 'Travail en journée',
    Lundi_vendredi = 'Du lundi au vendredi',
    Travail_soiree = 'Travail en soirée',
    Disponible_weekend = 'Disponible le week-end',
}
