import { CSSProperties, FC, useCallback, useState } from "react";
import { useScreenSize, useTranslation } from "@utilities/hooks";
import { TabNavigation, TalentCVModal } from '@connectedComponents';

import { IconNameEnum, StackEnum } from "@utilities/enums";
import { useControllers } from "./useControllers";
import { 
    AnimatedGradianButton, DropdownSelect, 
    Icon, Input, Page, SimpleButton, 
    TalentCard, FilterTextElement, SearchSection,
} from "@components";
import { CardWrapper, PageTitle } from "./styles";
import { CV, UserTalent } from "@utilities/types";
import { activitiesSectors, jobTypes, telework } from "@utilities/static";

type talentCVType = { cv: CV, talent: UserTalent };

export const FindTalent:FC= ()=> {

    const translate = useTranslation();
    const {isMobile} = useScreenSize();

    const {
        talentCVList,
        onSearchTalent,
        onCreateNewAppointmentRequest,
    } = useControllers();

    const [selectedTalentCV, setSelectedTalentCV] = useState<talentCVType | null>(null);

    const [search, setSearch] = useState<string>('');
    const [cityOrCountry, setCityOrCountry] = useState<string>('');
    const [minSalary, setMinSalary] = useState<string>('');
    const [contratType, setContratType] = useState<string>('');
    const [workTime, setWorkTime] = useState<string>('');
    const [enableMoreFilter, setEnableMoreFilter] = useState<boolean>(false);

    // const [search, setSearch] = useState<string>('');
    // const [minSalary, setMinSalary] = useState<string>('');
    // const [contratType, setContratType] = useState<string>('');
    // const [workTime, setWorkTime] = useState<string>('');

    // const [competence, setCompetence] = useState<string>('');
    // const [competences, setCompetences] = useState<string[]>([]);

    // const contratTypeList = useMemo(()=> {
    //     return enumToStringArray(EnumContratType);
    // }, []);

    // const workTimeList = useMemo(()=> {
    //     return enumToStringArray(EnumWorkTime);
    // }, []);

    // const handleAddCompetence = useCallback(()=> {
    //     if(competence){
    //         setCompetences(e=> [...e, competence]);
    //         setCompetence('');
    //     }
    // }, [competence]);

    // const handleDeleteCompetence = useCallback((deletedCompetence: string)=> {
    //     setCompetences(e=> (
    //         e.filter(el=> el !== deletedCompetence)
    //     ))
    // }, []);

    // const handleSearch = useCallback(()=> {
    //     if(!(search || competences.length > 0 || minSalary || contratType || workTime)){
    //         return alert('Il faut au moins un criter de recherche !');
    //     }

    //     onSearchTalent({
    //         ...(search ? {searchStr: search} : {}),
    //         ...(competences? {competences} : {}),
    //         ...(minSalary ? {minSalary} : {}),
    //         ...(contratType ? {contratType} : {}),
    //         ...(workTime ? {workTime} : {}),
    //     });
    // }, [competences, contratType, minSalary, search, workTime, onSearchTalent]);

    const renderSearchSection = useCallback(()=> {

        const mobilButtonStyles:CSSProperties = {
            backgroundColor: "#FDBA66",
            padding: 10,
            borderRadius: 8,
        };

        const animatedButtonStyle:CSSProperties = {
            borderRadius: 0,
        };

        return (
            <SearchSection>
                <Input
                    inputType='search'
                    placeholder="Recherchez par job, mot-clé..."
                    value={search}
                    onChange={setSearch}
                    onClearInput={()=> setSearch('')}
                    noRadius={!isMobile}
                />
                <Input 
                    leftTextIcon="📍"
                    placeholder="Ville, région ou pays"
                    value={cityOrCountry}
                    onChange={setCityOrCountry}
                    noRadius={!isMobile}
                />
                <DropdownSelect
                    placeholder="Secteur"
                    leftIconText="🏢"
                    elements={activitiesSectors}
                    onSelectedElement={e=> {}}
                    width={!isMobile ? 450 : undefined}
                    noRadius={!isMobile}
                />

                {
                    isMobile && (
                        <FilterTextElement onClick={()=> setEnableMoreFilter(!enableMoreFilter)}>
                            <Icon 
                                iconName={!enableMoreFilter ? IconNameEnum.filterDown : IconNameEnum.filterUp}
                                size='lg'
                            />
                            {!enableMoreFilter ? 'Plus de filtres' : 'Moins de filtres'}
                        </FilterTextElement>
                    )
                }

                {
                    (enableMoreFilter || !isMobile) && (
                        <>
                            <DropdownSelect 
                                placeholder="Type de job"
                                leftIconText="💼"
                                elements={jobTypes}
                                onSelectedElement={e=> {}}
                                width={!isMobile ? 450 : undefined}
                                noRadius={!isMobile}
                            />
                            <DropdownSelect 
                                placeholder="Télétravail"
                                leftIconText="🏠"
                                elements={telework}
                                onSelectedElement={e=> {}}
                                width={!isMobile ? 450 : undefined}
                                noRadius={!isMobile}
                            />
                        </>
                    )
                }

                {
                    isMobile ? (
                        <SimpleButton style={mobilButtonStyles}>
                            <Icon iconName={IconNameEnum.search} color="#fff" />
                            Rechercher
                        </SimpleButton>
                    ) : (
                        <AnimatedGradianButton style={animatedButtonStyle}>
                            <Icon iconName={IconNameEnum.search} color="#fff" />
                            Rechercher
                        </AnimatedGradianButton>
                    )
                }
            </SearchSection>
        )
    }, [search, cityOrCountry, isMobile, enableMoreFilter]);

    return (
        <TabNavigation activeStack={StackEnum.FindTalent}>
            <Page>

                {renderSearchSection()}

                <PageTitle>Explorer les talents</PageTitle> 

                <CardWrapper>
                    {
                        talentCVList.map((el, i)=> (
                            <TalentCard 
                                key={i} talentCV={el}
                                onPress={setSelectedTalentCV}
                            />
                        ))
                    }
                </CardWrapper>

                {
                    selectedTalentCV && (
                        <TalentCVModal 
                            talent={selectedTalentCV?.talent}
                            talentCV={selectedTalentCV.cv}
                            isVisible={!!selectedTalentCV}
                            onClose={()=> setSelectedTalentCV(null)}
                        />
                    )
                }
            </Page>
        </TabNavigation>
    )
}
