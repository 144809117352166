import { TalentAccount_OffersSaveComponent } from "./TalentAccount_OffersSave.component";

export const TalentAccount_OffersSave:React.FC = ()=> {



    return (
        <TalentAccount_OffersSaveComponent />
    )
}
