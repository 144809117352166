import { FC, useCallback } from "react";
import styled from "styled-components";
import { IconNameEnum, NavigationPathsEnum } from "@utilities/enums";
import { AnimatedGradianButton, Icon, MessageNotif } from "@components";
import { SubTitle, Title } from "./common";
import { useNavigation, useRecruiterSelectors, useTalentSelectors } from "@utilities/hooks";

interface Props {
    onCloseModal: ()=> void
}

const Section = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const NotifWrapper = styled.div`
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const SignupSuccess:FC<Props> = ({
    onCloseModal
})=> {

    const { talent } = useTalentSelectors();
    const { recruiter } = useRecruiterSelectors();
    const { navigateTo } = useNavigation();

    const handleGoToAccount = useCallback(()=> {
        onCloseModal();
        if(talent){
            navigateTo(NavigationPathsEnum.TALENT_ACCOUNT);
        } else if(recruiter){
            navigateTo(NavigationPathsEnum.RECRUITER_ACCOUNT);
        }
    }, [talent, recruiter, onCloseModal, navigateTo]);

    return (
        <Section>
            <NotifWrapper>
                <MessageNotif 
                    type={"success"} 
                    message={"Nous vous avons envoyé un e-mail pour confirmer votre adresse."}  
                />
            </NotifWrapper>

            <Title>
                Félicitations 🎉 
            </Title>
            <SubTitle>
                Votre espace {talent ? 'Candidat' : recruiter ? 'Recruteur' : ''} est prêt.
            </SubTitle>

            <AnimatedGradianButton onClick={handleGoToAccount}>
                J’y vais
                <Icon 
                    iconName={IconNameEnum.arrowRight}
                    color="#fff"
                />
            </AnimatedGradianButton>
        </Section>
    )
}