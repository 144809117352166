import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

interface Props {
    isActive: boolean
    customBG?: string
    hoverCustomBG?: string
    size?: number
}

export const NavButton = styled.label<Props>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 40px;
    color: #101214;
    background-color: ${props=> props.isActive ? (props.customBG || '#EFECE6') : 'transparent'};
    font-family: ${props=> props.isActive ? FontFamilyEnum.PlusJakartSans_Bold : FontFamilyEnum.PlusJakartSans_Medium};
    cursor: pointer;

    &:hover {
        background-color: ${props=> props.hoverCustomBG || '#F5F4F2'};
        transition: 1s;
    }

    @media (min-width: 760px) {
        font-size: 16px;
        height: 35px;
        padding-left: 24px;
        padding-right: 24px;
        border-radius: 24px;
    }
`;
