import { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import { 
    BadgeWrapper,
    CardPage, 
    CardPageSubTitle, 
    CardPageTitle,
    FixedModalFooter,
    InputLabel,
    InputWrapper,
    Modal,
    ModalContainer,
    ModalSubTitle,
    ModalTitle,
    SimpleBadge,
    SimpleButton,
    SimpleSelect,
} from "@components";
import { useScreenSize } from "@utilities/hooks";

interface Props {
    simpleButtonStyle: CSSProperties
    simpleButtonOutlineStyle: CSSProperties
}

export const CountryManagement:FC<Props> = ({
    simpleButtonStyle,
    simpleButtonOutlineStyle,
})=> {

    const { isMobile } = useScreenSize();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [country, setCountry] = useState<string>('');

    const countries = useMemo(()=> [
        {
            label: "🇨🇮 Côte d'Ivoire (CI)",
            value: "🇨🇮 Côte d'Ivoire (CI)",
        }
        // {
        //     label: "🇫🇷 France (FR)",
        //     value: "🇫🇷 France (FR)",
        // }
    ], []);

    const handleClose = useCallback(()=> {
        setIsVisible(false);
    }, []);

    const handleSave = useCallback(()=> {

    }, []);

    return (
        <>
            <CardPage>
                <CardPageTitle>
                    Pays
                </CardPageTitle>

                <CardPageSubTitle>
                    Choisissez votre pays de navigation pour personnaliser votre expérience.
                </CardPageSubTitle>

                <SimpleButton 
                    onClick={()=> setIsVisible(true)}
                    style={!isMobile ? simpleButtonStyle : {}}
                >
                    Modifier
                </SimpleButton>

                <InputWrapper>
                    <InputLabel>
                        Pays
                    </InputLabel>

                    <BadgeWrapper style={{marginTop: 0}}>
                        <SimpleBadge>
                            🇨🇮 Côte d'Ivoire (CI)
                        </SimpleBadge>
                    </BadgeWrapper>
                </InputWrapper>
            </CardPage>

            <Modal 
                isVisible={isVisible}
                onClose={handleClose}
            >
                <>
                    <ModalContainer>
                        <ModalTitle>
                            Pays
                        </ModalTitle>
                        <ModalSubTitle>
                            Choisissez votre pays de navigation pour personnaliser votre expérience.
                        </ModalSubTitle>

                        <InputWrapper>
                            <SimpleSelect 
                                placeholder="Selectionnez"
                                options={countries}
                                value={country}
                                onChange={setCountry}
                            />
                        </InputWrapper>
                    </ModalContainer>

                    <FixedModalFooter>
                        <SimpleButton onClick={handleSave}>
                            Enregistrer
                        </SimpleButton>

                        <SimpleButton style={simpleButtonOutlineStyle}>
                            Annuler
                        </SimpleButton>
                    </FixedModalFooter>
                </>
            </Modal>
        </>
    )
}