import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

interface IAnimatedGradianButton {
    size?: string
    font?: FontFamilyEnum
}

export const AnimatedGradianButton = styled.button<IAnimatedGradianButton>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: 35px;
    font-size: ${props=> props.size || "13px"};
    font-family: ${props=> props.font || FontFamilyEnum.SF_Pro_Display_Bold};
    border-radius: 32px;
    padding-left: 20px;
    padding-right: 20px;
    color: rgba(255, 255, 255, 0.9);
    background: linear-gradient(to right, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    background-size: 600%;
    animation: anime 5s ease infinite;
    cursor: pointer;
    transition: 0.5S;

    &:hover {
        background: linear-gradient(to right, #f7953398, #f3705598, #ef4e7b98, #a166ab98, #5073b898, #1098ad98, #07b39b98, #6fba8298);
        background-size: 600%;
        animation: anime 5s ease infinite;
        transform: scale(1.05);
        transition: 0.5S;
    }

    @media (min-width: 760px) {
        height: 42px;
        font-size: ${props=> props.size || "16px"};
        font-family: ${props=> props.font || FontFamilyEnum.SF_Pro_Display_Bold};;
        border-radius: 45px;
        padding-left: 28px;
        padding-right: 28px;
    }

    @keyframes anime {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
`;