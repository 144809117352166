import { CSSProperties, FC } from "react";
import { useTranslation } from "@utilities/hooks";
import { SubMenu, TabNavigation } from "@connectedComponents";
import { useController } from "../useControllers";
import { Container } from "./styles";
import { CountryManagement, ChangePassword, DeleteAccount } from "./components";

export const TalentAccount_Settings:FC = ()=> {

    const translate = useTranslation();
    const { itemsMenuNav } = useController();

    const simpleButtonStyle:CSSProperties = {
        position: 'absolute',
        right: 20,
        borderRadius: 50,
    };

    const simpleButtonOutlineStyle:CSSProperties = {
        backgroundColor: 'transparent',
        color: '#101214',
        border: 'solid 1px #101214',
    };

    return (
        <TabNavigation customBG="#EFECE6">
            <>
                <SubMenu
                    navMenu={itemsMenuNav}
                    active={itemsMenuNav[5].label}
                />

                <Container>
                    <CountryManagement 
                        simpleButtonStyle={simpleButtonStyle}
                        simpleButtonOutlineStyle={simpleButtonOutlineStyle}
                    />

                    <ChangePassword 
                        simpleButtonStyle={simpleButtonStyle}
                        simpleButtonOutlineStyle={simpleButtonOutlineStyle}
                    />

                    <DeleteAccount 
                        simpleButtonOutlineStyle={simpleButtonOutlineStyle}
                    />
                </Container>
            </>
        </TabNavigation>
    )
}
