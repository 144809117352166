import React, { useCallback, useEffect, useState } from "react";
import './styles.css';
import { IRecruiterAccount_Matching } from "./interfaces";
import { useNavigation, useScreenSize, useTranslation } from "@utilities/hooks";
import { TabNavigationWithSubHead } from "@connectedComponents";
import { NavigationPathsEnum } from "@utilities/enums";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button, Modal, Paragraph, Title } from "@components";
import { CV, Post } from "@utilities/types";
import { formatTextMaxChars, getColorByNumber } from "@utilities/functions";

export const RecruiterAccount_MatchingComponent:React.FC<IRecruiterAccount_Matching> = ({
    posts,
    postMatching,
    getPostMatching,
})=> {

    const translate = useTranslation();
    const {isMobile} = useScreenSize();
    const {navigateTo} = useNavigation();
    const [showMatching, setShowMatching] = useState<boolean>(false);
    const [selectedPost, setSelectedPost] = useState<string>('');
    const [selectedCV, setSelectedCV] = useState<CV | null>(null);

    const handleGoBack = useCallback(()=> {
        navigateTo(NavigationPathsEnum.RECRUITER_ACCOUNT);
    }, [navigateTo]);

    const renderButton = useCallback((post: Post)=> {
        return (
            <Button 
                label="voir le matching"
                onButtonPress={()=> {
                    getPostMatching(post._id!);
                    setSelectedPost(post.entitle);
                }}
            />
        )
    }, [getPostMatching]);

    const renderPostList = useCallback(()=> {
        return (
            <div className="ft_RecruiterAccount_Matching_sectionContainer">
                <DataTable value={posts} >
                    <Column field="entitle" header="Intitulé" />
                    <Column body={renderButton} />
                </DataTable>
            </div>
        )
    }, [posts, renderButton]);

    useEffect(()=> {
        if(postMatching){
            setShowMatching(true);
        }
    }, [postMatching]);

    return (
        <TabNavigationWithSubHead
            pageTitle='Matching'
            onGoBackPress={handleGoBack}
        >
            <div className="ft_RecruiterAccount_Matching_container">
                {renderPostList()}

                <Modal
                    isVisible={showMatching}
                    onClose={()=> setShowMatching(false)}
                >
                    <div className="ft_RecruiterAccount_Matching_modal_container">
                        <Title
                            label={`Resultat du matching pour: ${selectedPost}`}
                            variant='h_sm'
                            className="ft_RecruiterAccount_Matching_modal_title"
                        />

                        {
                            postMatching &&
                            postMatching.matching
                                .sort((a, b)=> b.score - a.score)
                                .map((match, i)=> {
                                    return (
                                        <div
                                            key={i}
                                            className="ft_RecruiterAccount_Matching_modal_item_container"
                                        >
                                            <Paragraph 
                                                variant='p_sm'
                                                weight='bold'
                                                label={formatTextMaxChars(match.cv.title, isMobile ? 18 : 30)}
                                            />
                                            <div 
                                                className="ft_RecruiterAccount_Matching_modal_itemScoreContainer"
                                                style={{
                                                    backgroundColor: getColorByNumber(match.score)
                                                }}
                                            >
                                                {match.score}
                                            </div>
                                            <Button 
                                                label="voir le cv"
                                                onButtonPress={()=> setSelectedCV(match.cv)}
                                            />
                                        </div>
                                    )
                                })   
                        }
                    </div>
                </Modal>
            </div>
        </TabNavigationWithSubHead>
    )
}
