import { useEffect, useMemo } from "react";
import { Icon } from "@components";
import { IconNameEnum } from "@utilities/enums";
import { 
    useScreenSize, 
    useTalentDispatch, 
    useTalentSelectors,
} from "@utilities/hooks";

export const useControlers = ()=> {

    const { isMobile } = useScreenSize();
    const { talent } = useTalentSelectors();
    const { onGetTalentData } = useTalentDispatch();

    const recruiterData = useMemo(()=> [
        {
            label: "Créez une page carrière pour votre entreprise avec votre marque employer",
            icon: <Icon iconName={IconNameEnum.briefcase} size={isMobile ? '1x' : '2x'} />,
        },
        {
            label: "Notre IA sélectionne les candidats qui répondent à vos besoins",
            icon: <Icon iconName={IconNameEnum.robot} size={isMobile ? '1x' : '2x'} />,
        },
        {
            label: "Notre IA crée des tests pour affiner l'écaluation des compétences",
            icon: <Icon iconName={IconNameEnum.task} size={isMobile ? '1x' : '2x'} />,
        },
        {
            label: "Optimisez votre recrutement: 80% de temps en moins à trier les CV et faire des face-à-face inutiles",
            icon: <Icon iconName={IconNameEnum.clock} size={isMobile ? '1x' : '2x'} />,
        },
        {
            label: "Gérez facilement les différentes étapes du processus de recrutement",
            icon: <Icon iconName={IconNameEnum.stream} size={isMobile ? '1x' : '2x'} />,
        },
        {
            label: "Planifiez des entretiens en toute simplicité",
            icon: <Icon iconName={IconNameEnum.calendarCheck} size={isMobile ? '1x' : '2x'} />,
        }
    ], [isMobile]);

    const talentData = useMemo(()=> [
        {
            label: "Dites adieu aux fausses offres d'emploi",
            icon: <Icon iconName={IconNameEnum.ban} size={isMobile ? '1x' : '2x'} />,
        },
        {
            label: "Créez un CV de qualité professionnelle en quelques minutes",
            icon: <Icon iconName={IconNameEnum.fileAlt} size={isMobile ? '1x' : '2x'} />,
        },
        {
            label: "Passez des tests personnalisés pour valider vos compétences",
            icon: <Icon iconName={IconNameEnum.clipboard} size={isMobile ? '1x' : '2x'} />,
        },
        {
            label: "Postulez aux jobs pour lesquels vous êtes qualifié : c’est 10x plus rapide avec Ohasis",
            icon: <Icon iconName={IconNameEnum.rocket} size={isMobile ? '1x' : '2x'} />,
        },
        {
            label: "Recevez des alertes d’emploi sur mesure pour postuler en priorité",
            icon: <Icon iconName={IconNameEnum.bell} size={isMobile ? '1x' : '2x'} />,
        },
        {
            label: "Gérez facilement toutes vos candidatures et futurs entretiens",
            icon: <Icon iconName={IconNameEnum.calendarCheck} size={isMobile ? '1x' : '2x'} />,
        }
    ], [isMobile]);

    useEffect(()=> {
        if(talent){
            onGetTalentData();
        }
    }, []); // ne pas mettre de dependances pour eviter des appelle en boucle

    return {
        recruiterData,
        talentData,
    }
}
