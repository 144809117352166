import { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import { 
    BadgeWithIcon, BadgeWrapper, FixedModalFooter, ImagePicker, 
    Input, InputLabel, InputWrapper, Modal, 
    ModalContainer, ModalSubTitle, ModalTitle, SimpleBadge, 
    SimpleButton, SimpleCheckbox, SimpleSelect,
} from "@components";
import { 
    Avatar, UserName,
    ProfileDescription, ProfileSection, 
    ProfileSectionBody, ProfileSectionHead, 
    ProfileSectionSubHead, ProfileTitle,
} from "../styles";
import { GenderEnum } from "@utilities/enums";
import { useScreenSize } from "@utilities/hooks";
import { formatDate, formatDateInput, formatTextMaxChars } from "@utilities/functions";
import { CV, UserTalent } from "@utilities/types";

interface Props {
    simpleButtonStyle: CSSProperties
    simpleButtonOutlineStyle: CSSProperties
    talentCV: CV | null
    talent: UserTalent | null
    onSaveTalentCVRequest: (cv: CV)=> void
    onUpdatedUserTalent: (data: UserTalent)=> void
    onUpdatedTalentAvatarData: (e: File)=> void
}

export const ProfileSectionComponent:FC<Props> = ({
    simpleButtonStyle,
    simpleButtonOutlineStyle,
    talentCV,
    talent,
    onSaveTalentCVRequest,
    onUpdatedUserTalent,
    onUpdatedTalentAvatarData,
})=> {

    const { isMobile } = useScreenSize();

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [profilTitle, setProfilTitle] = useState<string>(talentCV?.title || '');
    const [profilDescription, setProfilDescription] = useState<string>(talentCV?.shortDescription || '');
    const [firstname, setFirstname] = useState<string>(talent?.firstname || '');
    const [lastname, setLastname] = useState<string>(talent?.lastname || '');
    const [mail, setMail] = useState<string>(talent?.mail || '');
    const [phone, setPhone] = useState<string>(talent?.phone || '');
    const [birth, setBirth] = useState<string>((talent?.birth && formatDateInput(talent.birth)) || '');
    const [langue, setLangue] = useState<string>('');
    const [languages, setLanguages] = useState<string[]>(talentCV?.languages || []);
    const [selectedGender, setSelectedGender] = useState<GenderEnum | null>(talent?.gender || null);
    const [profilAvatarFile, setProfilAvatarFile] = useState<File | null>(null);

    const simpleMobileButtonStyle:CSSProperties = {
        width: '100%',
        marginTop: 10,
    };

    const sectionHeadStyle:CSSProperties = {
        padding: 20,
        paddingBottom: 25,
        marginBottom: 5,
    };

    const avatarUri = useMemo(()=> (
        `${process.env.REACT_APP_BASE_PUBLIC_URL}/${talent?.avatar?.fileDir}/${talent?.avatar?.filename}`
    ), [talent]);

    const languagesOption = useMemo(()=> [
        {
            label: '🇫🇷 Français',
            value: '🇫🇷 Français',
        },
        {
            label: '🇺🇸 Anglais',
            value: '🇺🇸 Anglais',
        },
    ], []);

    const handleClose = useCallback(()=> {
        setProfilTitle(talentCV?.title || '');
        setProfilDescription(talentCV?.shortDescription || '');
        setFirstname(talent?.firstname || '');
        setLastname(talent?.lastname || '');
        setMail(talent?.mail || '');
        setPhone(talent?.phone || '');
        setBirth((talent?.birth && formatDateInput(talent.birth)) || '');
        setLangue('');
        setLanguages(talentCV?.languages || []);
        setSelectedGender(talent?.gender || null);
        setProfilAvatarFile(null);
        setIsVisible(false);
    }, [talent, talentCV]);

    const handleAddLanguages = useCallback((language: string)=> {
        setLanguages(e => {
            if (!e.includes(language)) {
                return [...e, language];
            }
            return e;
        });
        setLangue('');
    }, []);

    const handleRemoveLanguage = useCallback((language: string)=> {
        setLanguages(e => e.filter(lang=> lang !== language));
    }, []);

    const handleUpdatedProfil = useCallback(()=> {
        talent && onUpdatedUserTalent({
            ...talent,
            firstname,
            lastname,
            ...(phone ? {phone} : {}),
            ...(birth ? {birth: new Date(birth)} : {}),
            ...(selectedGender ? {gender: selectedGender} : {}),
        });

        if(talentCV){
            onSaveTalentCVRequest({
                ...talentCV!,
                ...(profilTitle ? {title: profilTitle} : {}),
                ...(profilDescription ? {shortDescription: profilDescription} : {}),
                ...(languages ? {languages: languages} : {}),
            });
        }

        if(profilAvatarFile){
            onUpdatedTalentAvatarData(profilAvatarFile);
        }

        handleClose();
        setProfilAvatarFile(null);
    }, [
        birth, firstname, 
        lastname, phone, 
        selectedGender, talent,
        talentCV, profilTitle,
        languages,
        profilDescription,
        profilAvatarFile,
        handleClose,
        onUpdatedUserTalent,
        onSaveTalentCVRequest,
        onUpdatedTalentAvatarData,
    ]);

    return (
        <>
            <ProfileSection>
                <ProfileSectionHead style={sectionHeadStyle}>
                    <Avatar
                        src={avatarUri}
                        alt="profile avatar"
                    />

                    <ProfileSectionSubHead>
                        <UserName>
                            {talent?.firstname} {talent?.lastname}
                        </UserName>
                        {
                            talentCV?.title && (
                                <ProfileTitle>
                                    {formatTextMaxChars(talentCV?.title, 45)}
                                </ProfileTitle>
                            )
                        }
                    </ProfileSectionSubHead>

                    <SimpleButton 
                        style={isMobile ? simpleMobileButtonStyle : simpleButtonStyle}
                        onClick={()=> setIsVisible(true)}
                    >
                        Modifier
                    </SimpleButton>
                </ProfileSectionHead>

                <ProfileSectionBody style={{padding: 20, marginTop: 0}}>
                    {
                        talentCV?.shortDescription && (
                            <ProfileDescription>
                                {talentCV?.shortDescription}
                            </ProfileDescription>
                        )
                    }

                    <BadgeWrapper style={{marginTop: 20}}>
                        <SimpleBadge>✉️ {talent?.mail}</SimpleBadge>
                        {talent?.phone && <SimpleBadge>📞 {talent.phone}</SimpleBadge>}
                        {talent?.birth && <SimpleBadge>🎂 {formatDate(talent.birth)}</SimpleBadge>}
                        {talent?.gender && <SimpleBadge>⚧️ {talent.gender}</SimpleBadge>}
                        {talentCV?.languages && (
                            <SimpleBadge>
                                🗣️ {talentCV?.languages.map((el, i)=> {
                                    return (talentCV?.languages.length - 1 === i ? el : `${el} - `);
                                })}
                            </SimpleBadge>
                            )}
                    </BadgeWrapper>
                </ProfileSectionBody>
            </ProfileSection>

            <Modal
                isVisible={isVisible}
                onClose={handleClose}
            >
                <>
                    <ModalContainer>
                        <ModalTitle>
                            Informations personnelles
                        </ModalTitle>
                        <ModalSubTitle>
                            Permettez aux recruteurs de vous contacter.
                        </ModalSubTitle>

                        <InputWrapper>
                            <InputLabel>
                                ✨ Titre du profil
                            </InputLabel>
                            <Input 
                                placeholder="ex : Product Designer avec 5 ans d’expérience"
                                value={profilTitle}
                                onChange={setProfilTitle}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                ✨ Description du profil
                            </InputLabel>
                            <Input 
                                inputType='textarea'
                                placeholder="Description..."
                                value={profilDescription}
                                onChange={setProfilDescription}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                📷 Photo de profil
                            </InputLabel>
                            <ImagePicker 
                                defaultUri={avatarUri}
                                onImageChange={setProfilAvatarFile}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                👤 Prénom*
                            </InputLabel>
                            <Input 
                                placeholder="Prénom"
                                value={firstname}
                                onChange={setFirstname}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                👤 Nom*
                            </InputLabel>
                            <Input 
                                placeholder="Nom"
                                value={lastname}
                                onChange={setLastname}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                ⚧️ Genre
                            </InputLabel>

                            <SimpleCheckbox 
                                label="Je suis une femme"
                                checked={selectedGender === GenderEnum.woman}
                                setChecked={()=> setSelectedGender(GenderEnum.woman)}
                                styles={{marginTop: 10}}
                            />
                            <SimpleCheckbox 
                                label="Je suis un homme"
                                checked={selectedGender === GenderEnum.man}
                                setChecked={()=> setSelectedGender(GenderEnum.man)}
                                styles={{marginTop: 10}}
                            />
                            {/* a contionner l'affichage en fonction du pays/zone geographique */}
                            {/* <SimpleCheckbox 
                                label="Je suis non-binaire"
                                checked={selectedGender === GenderEnum.noBinary}
                                setChecked={()=> setSelectedGender(GenderEnum.noBinary)}
                            /> */}
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                ✉️ Email*
                            </InputLabel>
                            <Input 
                                disabled
                                placeholder="email"
                                value={mail}
                                onChange={setMail}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                📞 Téléphone
                            </InputLabel>
                            <Input 
                                placeholder="Numéro de téléphone"
                                value={phone}
                                onChange={setPhone}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                🎂 Date de naissance
                            </InputLabel>
                            <Input 
                                inputType='date'
                                placeholder="JJ/MM/AAAA"
                                value={birth}
                                onChange={(e)=> setBirth(e)}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                🗣️ Langues parlées
                            </InputLabel>
                            <SimpleSelect 
                                placeholder="Sélectionnez une langue"
                                options={languagesOption}
                                value={langue}
                                onChange={e=> handleAddLanguages(e)}
                            />

                            <BadgeWrapper>
                                {
                                    languages.map((el, i)=> {
                                        return (
                                            <BadgeWithIcon
                                                key={i}
                                                onIconPress={()=> handleRemoveLanguage(el)}
                                            >
                                                {el}
                                            </BadgeWithIcon>
                                        )
                                    })
                                }
                            </BadgeWrapper>
                        </InputWrapper>
                    </ModalContainer>

                    <FixedModalFooter>
                        <SimpleButton onClick={handleUpdatedProfil}>
                            Enregistrer
                        </SimpleButton>

                        <SimpleButton onClick={handleClose} style={simpleButtonOutlineStyle}>
                            Annuler
                        </SimpleButton>
                    </FixedModalFooter>
                </>
            </Modal>
        </>
    )
}