import React, { useCallback } from "react";
import './styles.css';
import { ITalentAccount_UnsolicitedApplications } from "./interfaces";
import { useNavigation, useTranslation } from "@utilities/hooks";
import { TabNavigationWithSubHead } from "@connectedComponents";
import { NavigationPathsEnum } from "@utilities/enums";
import { JobOffersCard } from "@components";

export const TalentAccount_UnsolicitedApplicationsComponent:React.FC<ITalentAccount_UnsolicitedApplications> = ({
    postList
})=> {

    const translate = useTranslation();
    const {navigateTo} = useNavigation();

    const handleGoBack = useCallback(()=> {
        navigateTo(NavigationPathsEnum.TALENT_ACCOUNT);
    }, [navigateTo]);

    return (
        <TabNavigationWithSubHead
            pageTitle='Mes candidatures'
            onGoBackPress={handleGoBack}
        >
            <div className="ft_talentAccountUnsolicitedApplication_container">
                <div className="ft_talentAccountUnsolicitedApplication_sectioncontainer">
                    {
                        postList.map((el, i)=> {
                            return (
                                <JobOffersCard
                                    key={i}
                                    postInfos={el}
                                    hideActionButtons
                                />
                            )
                        })
                    }
                </div>
            </div>
        </TabNavigationWithSubHead>
    )
}
