import React, { useCallback, useEffect, useState } from "react";
import './styles.css';
import { IRecruiterAccount_Test } from "./interfaces";
import { useNavigation, useTranslation } from "@utilities/hooks";
import { TabNavigationWithSubHead } from "@connectedComponents";
import { EnumJobCategory, NavigationPathsEnum } from "@utilities/enums";
import { Button, Modal, Paragraph, QcmGenerator } from "@components";
import { EnumUserLevel, TEST_QCM, qcmTestType } from "@utilities/types";

export const RecruiterAccount_TestComponent:React.FC<IRecruiterAccount_Test> = ({
    recruiter,
    organisationTest,
    onSaveOrganisationTest,
})=> {

    const translate = useTranslation();
    const {navigateTo} = useNavigation();

    const [displayCreateTestFormModal, setDisplayCreateTestFormModal] = useState<boolean>(false);
    const [testTitle, setTestTitle] = useState<string>('');
    const [testLevel, setTestLevel] = useState<EnumUserLevel | null>(null);
    const [testCategory, setTestCategory] = useState<string>('');
    const [questions, setQuestions] = useState<qcmTestType[]>([]);

    const handleGoBack = useCallback(()=> {
        navigateTo(NavigationPathsEnum.RECRUITER_ACCOUNT);
    }, [navigateTo]);

    const toggleDisplayCreateTestFormModal = useCallback(()=> {
        setDisplayCreateTestFormModal(!displayCreateTestFormModal);
    }, [displayCreateTestFormModal]);

    const handleInitQuestion = useCallback(()=> {
        setQuestions(Array(20).fill({
            question: '',
            reponse_a: '',
            reponse_b: '',
            reponse_c: '',
            reponse_d: '',
            solution: '',
            selected_response: '',
            explication: '',
        }));
    }, []);

    const handleSelectedQcm = useCallback((selectedQCM: TEST_QCM)=> {
        setQuestions(selectedQCM.qcm);
        setTestTitle(selectedQCM.entitle);
        setTestLevel(selectedQCM.level);
        // setTestCategory(selectedQCM.jobCategory);
        toggleDisplayCreateTestFormModal();
    }, [toggleDisplayCreateTestFormModal]);

    const handleSaveTest = useCallback(()=> {
        if(!(testTitle && testCategory && testLevel && questions)){
            return alert('Tous les champs doivent etre complet !');
        }

        // onSaveOrganisationTest({
        //     _createdBy_ID: recruiter._organisationID!,
        //     entitle: testTitle,
        //     jobCategory: testCategory as EnumJobCategory,
        //     level: testLevel,
        //     qcm: questions,
        //     time: 10
        // });

        setTestTitle('');
        setTestLevel(null);
        setTestCategory('');
        handleInitQuestion();
        toggleDisplayCreateTestFormModal();
    }, [
        testTitle, testCategory, 
        testLevel, questions, 
        recruiter, onSaveOrganisationTest,
        handleInitQuestion, toggleDisplayCreateTestFormModal,
    ]);

    useEffect(()=> {
        handleInitQuestion();
    }, [])

    return (
        <TabNavigationWithSubHead
            pageTitle='Tests'
            onGoBackPress={handleGoBack}
        >
            <div className="ft_recruiterAccountTest_container">
                <Button 
                    label="creer un test"
                    onButtonPress={toggleDisplayCreateTestFormModal}
                />

                <div className="ft_recruiterAccountTest_sectionContainer">
                    {
                        organisationTest.map((el, i)=> {
                            return (
                                <div
                                    key={i}
                                    className="ft_recruiterAccountTest_testItemContainer"
                                >
                                    <Paragraph 
                                        variant='p_sm'
                                        label={el.entitle}
                                    />

                                    <Button 
                                        label="Voir"
                                        onButtonPress={()=> handleSelectedQcm(el)}
                                    />
                                </div>
                            )
                        })
                    }
                </div>

                <Modal
                    isVisible={displayCreateTestFormModal}
                    // fullScreen
                    onClose={toggleDisplayCreateTestFormModal}
                >
                    <QcmGenerator 
                        testTitle={testTitle}
                        testLevel={testLevel}
                        questions={questions}
                        setTestLevel={setTestLevel}
                        setTestTitle={setTestTitle}
                        setTestCategory={setTestCategory}
                        setQuestions={setQuestions}
                        onSubmitForm={handleSaveTest}
                    />
                </Modal>
            </div>
        </TabNavigationWithSubHead>
    )
}