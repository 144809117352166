import { NavButton } from "@components";
import { FC } from "react";
import styled from "styled-components";

interface Props {
    activeIndex: number
    onChange: (e: number)=> void
}

const Section = styled.section`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 45px;
`;

export const ButtonGroup:FC<Props> = ({activeIndex, onChange})=> {
    return (
        <Section>
            <NavButton 
                isActive={activeIndex === 0}
                onClick={()=> onChange(0)}
            >Se connecter</NavButton>

            <NavButton 
                isActive={activeIndex === 1}
                onClick={()=> onChange(1)}
            >Créer un compte</NavButton>
        </Section>
    )
}