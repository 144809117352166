import styled from "styled-components";

export const FixedModalFooter = styled.div`
    height: 20%;
    padding-top: 20px;
    border-top: solid 1px #D8D6D6;
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media (min-width: 760px) {
        height: 10%;
        flex-direction: row;
        padding-left: 20px;
    }
`;
