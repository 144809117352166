import { useCallback, useEffect } from "react";
import { 
    useAppointmentDispatch, 
    useRecruiterDispatch, 
    useRecruiterSelectors,
} from "@utilities/hooks";
import { SearchTalentType } from "@utilities/types";

export const useControllers = ()=> {

    const { talentCVList } = useRecruiterSelectors();
    const { onCreateNewAppointmentRequest } = useAppointmentDispatch();
    const {
        onGetAllTalentCvsRequest,
        onSearchTalentCvsRequest,
    } = useRecruiterDispatch();

    const onSearchTalent = useCallback((search: SearchTalentType)=> {
        onSearchTalentCvsRequest(search);
    }, [onSearchTalentCvsRequest]);

    useEffect(()=> {
        onGetAllTalentCvsRequest();
    }, []);

    return {
        talentCVList,
        onSearchTalent,
        onCreateNewAppointmentRequest
    }
}
