import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const FooterWrapper = styled.section<{enableFaq: boolean}>`
    display: flex;
    justify-content: center;
    background-color: #101214;
    padding-top: ${props=> props.enableFaq ? '45px': '0px'};;
    border-top-left-radius: ${props=> props.enableFaq ? '40px': '0px'};
    border-top-right-radius: ${props=> props.enableFaq ? '40px': '0px'};

    @media (min-width: 760px) {
        padding: 20px;
        padding-top: ${props=> props.enableFaq ? '144px': '0px'};
        border-top-left-radius: ${props=> props.enableFaq ? '90px': '0px'};
        border-top-right-radius: ${props=> props.enableFaq ? '90px': '0px'};
    }
`;

export const FooterSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1200px) {
        /* padding: 0px 0px 0px 0px; */
        width: 1200px;
    }
`;

export const FooterTitle = styled.h3`
    margin: 0%;
    font-size: 22px;
    color: #efece6;
    text-align: center;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};

    @media (min-width: 760px) {
        font-size: 44px;
    }
`;

export const FooterSubTitle = styled.h5`
    margin: 0%;
    margin-top: 3px;
    font-size: 13px;
    color: #efece6;
    text-align: center;
    width: 90%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_SEMI_BOLD};

    @media (min-width: 760px) {
        font-size: 20px;
        margin-top: 10px;
        width: 70%;
    }
`;

export const FooterSubTitleMail = styled.p`
    color: #efece6;
    margin: 0%;
    font-size: 12px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};

    @media (min-width: 760px) {
        font-size: 20px;
    }
`;

export const DropdownWrapper = styled.div`
    width: 90%;
    margin-top: 40px;
    margin-bottom: 50px;

    @media (min-width: 760px) {
        margin-top: 72px;
        width: 60%;
    }
`;

export const Logo = styled.p<{isMobile:boolean}>`
    margin: 0%;
    color: #efece6;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
    font-size: ${props=> props.isMobile ? "25px" : "35px"};
    margin-left: ${props=> props.isMobile ? "3px" : "6px"};
`;

export const DropdownTextContent = styled.p`
    margin: 0%;
    color: #101214;
    font-size: 10px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Regular};

    @media (min-width: 760px) {
        font-size: 14px;
    }
`;

export const FooterDescriptionParagraphContainer = styled.div`
    @media (min-width: 760px) {
        width: 60%;
    }
`;
export const FooterDescriptionParagraph = styled.p`
    margin: 0%;
    color: #efece6;
    font-size: 12px;
    margin-top: 10px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Regular};
    text-align: justify;

    @media (min-width: 760px) {
        font-size: 20px;
        margin-top: 20px;
    }
`;

export const FooterDescriptionMobilContainer = styled.div`
    padding: 20px;
`;
export const FooterDescriptionMobilSubContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const FooterDescriptionDesktopContainer = styled.div<{enableFaq: boolean}>`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: ${props=> props.enableFaq ? '100px': '20px'};
`;

export const MailText = styled.p`
    margin: 0%;
    font-size: 11px;
    color: #efece6;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    @media (min-width: 760px) {
        font-size: 18px;
    }
`;

export const FooterSubSection = styled.div`
    border-top: solid 1px #555555;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;

    @media (min-width: 760px) {
        margin-top: 20px;
    }
`;

export const FooterTextCopyRight = styled.p`
    margin: 0%;
    color: #efece6;
    font-size: 10px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Regular};

    @media (min-width: 760px) {
        font-size: 18px;
    }
`;