import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const CardPageTitle = styled.p`
    margin: 0%;
    font-size: 18px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};

    @media (min-width: 760px){
        font-size: 22px;
    }
`;