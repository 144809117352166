import { 
    saveTalentAppointment,
    saveRecruiterAppointment,
} from "@data/reducers";
import { 
    fetchCreateNewAppointmentRequest,
    fetchDeletedAppointmentRequest,
    fetchGetRecruiterAppointmentRequest, 
    fetchGetTalentAppointmentRequest,
    fetchUpdatedAppointmentRequest,
} from "@data/services";
import { AppDispatch } from "@data/store";
import { Appointment, FetchResponseType } from "@utilities/types";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export const useAppointmentDispatch = () => {

    const dispatch = useDispatch<AppDispatch>();

    const onGetTalentAppointmentRequest = useCallback(async(userID: string) => {
        const req:FetchResponseType = await fetchGetTalentAppointmentRequest(userID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const talentAppointment:Appointment[] = req.data;
        dispatch(saveTalentAppointment(talentAppointment));
    }, [dispatch]);

    const onGetRecruiterAppointmentRequest = useCallback(async(recruiterID: string) => {
        const req:FetchResponseType = await fetchGetRecruiterAppointmentRequest(recruiterID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const recruiterAppointment:Appointment[] = req.data;
        dispatch(saveRecruiterAppointment(recruiterAppointment));
    }, [dispatch]);

    const onCreateNewAppointmentRequest = useCallback(async(newAppointment: Appointment) => {
        const req:FetchResponseType = await fetchCreateNewAppointmentRequest(newAppointment);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        return 'success !';
    }, []);

    const onUpdatedAppointmentRequest = useCallback(async(updatedAppointment: Appointment) => {
        const req:FetchResponseType = await fetchUpdatedAppointmentRequest(updatedAppointment);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        return 'success !';
    }, []);

    const onDeletedAppointmentRequest = useCallback(async(appointmentID: string) => {
        const req:FetchResponseType = await fetchDeletedAppointmentRequest(appointmentID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        return 'success !';
    }, []);

    const onResetAppointmentRequest = useCallback(()=> {
        dispatch(saveTalentAppointment([]));
        dispatch(saveRecruiterAppointment([]));
    }, [dispatch]);

    return {
        onGetTalentAppointmentRequest,
        onGetRecruiterAppointmentRequest,
        onCreateNewAppointmentRequest,
        onUpdatedAppointmentRequest,
        onDeletedAppointmentRequest,
        onResetAppointmentRequest,
    };
};
