import { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Input, SimpleButton, SimpleSelect } from "@components";
import { AccountTypeEnum } from "@utilities/enums";
import { FormWrapper, InputLabel, InputWrapper } from "./common";
import { useRecruiterDispatch, useRecruiterSelectors, useTalentDispatch, useTalentSelectors } from "@utilities/hooks";
import { EnumOrganisationUserRole } from "@utilities/types";
import { SignupSuccess } from "./signupSuccess";

interface Props {
    onCloseModal: ()=> void
}

const NamesWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
`

export const SignupForm:FC<Props> = ({
    onCloseModal
})=> {

    const { talent } = useTalentSelectors();
    const { recruiter, organisation } = useRecruiterSelectors();
    const { onTalentSignup } = useTalentDispatch();
    const { onSignupRecruiter, onCreateOrganisation } = useRecruiterDispatch();

    const [selectedAccountType, setSelectedAccountType] = useState<string>(AccountTypeEnum.Talent);
    const [brandname, setBrandname] = useState<string>('');
    const [firstname, setFirstname] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const options = useMemo(()=> [
        {
            label: "Je suis à la recherche d'un job",
            value: AccountTypeEnum.Talent,
        },
        {
            label: "Je suis un recruteur indépendant",
            value: AccountTypeEnum.Recruiter,
        },
        {
            label: "Je suis une entreprise qui recrute",
            value: AccountTypeEnum.Organisation,
        },
    ], []);

    const handleSignup = useCallback(()=> {
        if(firstname && lastname && email && password && selectedAccountType){
            if(selectedAccountType === AccountTypeEnum.Talent){
                onTalentSignup({firstname, lastname, mail: email, password});
            } else if(selectedAccountType === AccountTypeEnum.Organisation) {
                if(brandname){
                    onCreateOrganisation({organisationName: brandname});
                }
            } else if(selectedAccountType === AccountTypeEnum.Recruiter){
                onSignupRecruiter({
                    _isOrganisation: false,
                    role: EnumOrganisationUserRole.admin,
                    firstname,
                    lastname,
                    mail: email,
                    password,
                });
            }
        }
    }, [
        email, firstname, lastname, 
        password, brandname, selectedAccountType,
        onTalentSignup, onCreateOrganisation,
        onSignupRecruiter,
    ]);

    useEffect(()=> {
        if(organisation && email && firstname && lastname && password){
            onSignupRecruiter({
                _isOrganisation: true,
                _organisationID: organisation._id,
                role: EnumOrganisationUserRole.admin,
                firstname,
                lastname,
                mail: email,
                password,
            });
        }
    }, [
        email, firstname, 
        lastname, organisation, 
        password, onSignupRecruiter,
    ]);
    
    useEffect(()=> {
        if(talent || recruiter){
            setFirstname('');
            setLastname('');
            setEmail('');
            setPassword('');
            setBrandname('');
            setSelectedAccountType('');
        }
    }, [recruiter, talent]);

    if(talent || recruiter){
        return (
            <SignupSuccess onCloseModal={onCloseModal} />
        )
    }

    return (
        <FormWrapper>
            <InputWrapper>
                <InputLabel>
                    Type de compte*
                </InputLabel>
                <SimpleSelect 
                    placeholder="Particulier, recruteur ou entreprise"
                    value={selectedAccountType}
                    onChange={setSelectedAccountType}
                    options={options}
                />
            </InputWrapper>

            {
                selectedAccountType === AccountTypeEnum.Organisation && (
                    <InputWrapper>
                        <InputLabel>
                            Entreprise*
                        </InputLabel>
                        <Input 
                            placeholder="Entrez le nom de votre entreprise"
                            value={brandname}
                            onChange={setBrandname}
                        />
                    </InputWrapper>
                )
            }

            <NamesWrapper>
                <InputWrapper>
                    <InputLabel>
                        Prénom*
                    </InputLabel>
                    <Input 
                        placeholder="Prénom"
                        value={firstname}
                        onChange={setFirstname}
                    />
                </InputWrapper>

                <InputWrapper>
                    <InputLabel>
                        Nom*
                    </InputLabel>
                    <Input 
                        placeholder="Nom"
                        value={lastname}
                        onChange={setLastname}
                    />
                </InputWrapper>
            </NamesWrapper>

            <InputWrapper>
                <InputLabel>
                    Adresse email*
                </InputLabel>
                <Input 
                    placeholder="Entrez l’adresse email"
                    value={email}
                    onChange={setEmail}
                />
            </InputWrapper>

            <InputWrapper>
                <InputLabel>
                    Mot de passe*
                </InputLabel>
                <Input 
                    inputType='password'
                    placeholder="Entrez le mot de passe"
                    value={password}
                    onChange={setPassword}
                />
            </InputWrapper>

            <SimpleButton onClick={handleSignup}>
                S’inscrire
            </SimpleButton>
        </FormWrapper>
    )
}