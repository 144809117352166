import { FC, useCallback, useState } from "react";
import { useNavigation, useTranslation } from "@utilities/hooks";
import { CreateNewPassword, LoginComponent } from '@connectedComponents';
import { Modal } from "@components";
import { NavigationPathsEnum } from "@utilities/enums";

export const CreateNewPasswordPage:FC = ()=> {

    const translate = useTranslation();
    const { navigateTo } = useNavigation();

    const [isLoginComponent, setIsLoginComponent] = useState<boolean>(false);

    const handleGoToHome = useCallback(()=> {
        navigateTo(NavigationPathsEnum.HOME);
    }, [navigateTo]);

    return (
        <>
            <Modal
                isVisible
                onClose={handleGoToHome}
            >
                {
                    isLoginComponent ? (
                        <LoginComponent onCloseModal={handleGoToHome} />
                    ) : (
                        <CreateNewPassword 
                            handleGoToSignin={()=> setIsLoginComponent(true)}
                        />
                    )}
            </Modal>
        </>
    )
}
