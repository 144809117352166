import { NavButton } from "@components";
import { FC } from "react";
import styled from "styled-components";

interface Props {
    navMenu: {
        label: string, 
        goTo: ()=> void,
    }[]
    active: string
}

export const Menu = styled.section`
    position: fixed;
    z-index: 99;
    top: 60px;
    left: 0%;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #E1DDD6;
    border-top: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    & > * {
        scroll-snap-align: start;
        flex-shrink: 0;
        margin-right: 10px;
    }

    @media (min-width: 760px){
        height: 60px;
        justify-content: center;
        top: 75px;
    }
`;

export const SubMenu:FC<Props> = ({ 
    navMenu,
    active,
})=> {

    return (
        <Menu>
            {
                navMenu.map((el, i)=> (
                    <NavButton 
                        key={i} 
                        isActive={el.label === active}
                        customBG="#F9F9F9"
                        onClick={el.goTo}
                    >
                        {el.label}
                    </NavButton>
                ))
            }
        </Menu>
    )
}