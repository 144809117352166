import { EnumUserLevel } from "@utilities/types"
import { useMemo } from "react"

export const useGetLevelColor = (level: EnumUserLevel)=> {

    const color = useMemo(()=> (
        level === EnumUserLevel.debutant ? '#FFFACD'
        : level === EnumUserLevel.intermediaire ? '#E6E6FA'
        : level === EnumUserLevel.confirme ? '#87CEEB'
        : '#c3c3c3'
    ), [level]);

    const bgColor = useMemo(()=> (
        level === EnumUserLevel.debutant ? '#FFFEF7'
        : level === EnumUserLevel.intermediaire ? '#F9F9FF'
        : level === EnumUserLevel.confirme ? '#ECFAFF'
        : '#fff'
    ), [level]);

    return {
        color,
        bgColor,
    }
}