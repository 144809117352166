import { FontFamilyEnum, IconNameEnum } from "@utilities/enums";
import { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { SimpleBadge } from "./simpleBadge";
import { AnimatedGradianButton } from "./animatedGradianButton";
import { SimpleButton } from "./simpleButton";
import { Icon } from "./icon";
import { useScreenSize } from "@utilities/hooks";

interface Props {
    isHighlighted?: boolean
}

export const Card = styled.div<{isHighlighted?: boolean}>`
    width: 100%;
    position: relative;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px;
    background: ${props=> props.isHighlighted ? 'rgb(253,186,102)' : '#fff'};
    background: ${props=> props.isHighlighted ? 'linear-gradient(90deg, rgba(253,186,102,1) 0%, rgba(253,109,113,1) 100%)' : '#fff'};
    
    border: solid 1px #ECECEC;
    -webkit-box-shadow: 0px 0px 15px -3px #ECECEC; 
    box-shadow: 0px 0px 15px -3px #ECECEC;

    @media (min-width: 760px) {
        padding: 30px;
        padding-left: 35px;
        padding-right: 35px;
    }
`;

export const CardPreview = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const LogoAndBody = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;
export const OrganisationImg = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;

    @media (min-width: 760px) {
        width: 90px;
        height: 90px;
    }
`;

export const CardPreviewBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
export const CardTitle = styled.h3`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
    font-size: 13px;

    @media (min-width: 760px) {
        font-size: 22px;
    }
`;
export const OrganisationName = styled.h4`
    margin: 0%;
    margin-top: 5px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Regular};
    font-size: 12px;

    @media (min-width: 760px) {
        font-size: 18px;
    }
`;
export const CardPreviewBodyBadges = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;

    @media (min-width: 760px) {
        width: 50%;
        gap: 7px;
    }
`;

export const Days = styled.p<{isHighlighted: boolean}>`
    margin: 0%;
    font-size: 13px;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
    color: ${props=> props.isHighlighted ? '#F9F9F9' : '#101214'};

    @media (min-width: 760px) {
        font-size: 18px;
    }
`;

export const Description = styled.div`
    margin-top: 20px;
    padding-top: 20px;
    border-top: solid 1px #ECECEC;
    border-bottom: solid 1px #ECECEC;
`;

export const DescriptionSection = styled.div`
    margin-bottom: 20px;
`;

export const DetailTitle = styled.h4`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
    font-size: 13px;
    color: #101214;

    @media (min-width: 760px) {
        font-size: 18px;
    }
`;

export const DetailDescription = styled.p`
    margin: 0%;
    margin-top: 10px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Regular};
    font-size: 13px;
    color: #101214;

    @media (min-width: 760px) {
        font-size: 18px;
    }
`;

export const ActionsButtons = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    @media (min-width: 760px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 20px;

    @media (min-width: 760px) {
        margin-bottom: 30px;
    }
`;

export const IconWrapper = styled.div<{isHighlighted: boolean}>`
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: ${props=> props.isHighlighted ? '#DB4343' : '#101214'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (min-width: 760px) {
        width: 60px;
        height: 60px;
        border-radius: 60px;
    }
`;

export const HighlightedBadge = styled.label`
    position: absolute;
    top: -9px;
    font-size: 8px;
    padding: 3px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 20px;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
    color: #fff;
    background: rgb(253,186,102);
    background: linear-gradient(90deg, rgba(253,186,102,1) 0%, rgba(253,109,113,1) 100%);
    -webkit-box-shadow: 0px 0px 13px -5px #fff; 
    box-shadow: 0px 0px 13px -5px #fff;

    @media (min-width: 760px) {
        top: -15px;
        font-size: 14px;
        padding: 5px;
        padding-left: 18px;
        padding-right: 18px;
        border-radius: 40px;
    }
`;

export const JobOfferCard:FC<Props> = ({
    isHighlighted = false
})=> {

    const { isMobile } = useScreenSize();
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const buttonStyles:CSSProperties = useMemo(()=> (
        {
            backgroundColor: "#F7F7F7",
            color: "#101214",
        }
    ), []);

    const badgeCustomStyle:CSSProperties = useMemo(()=> {
        if(!isHighlighted) return {};

        return {
            backgroundColor: "#F9F9F9",
        }
    }, [isHighlighted]);

    const animatedButtonCustomStyle:CSSProperties = useMemo(()=> {
        if(!isMobile) return {};

        return {
            borderRadius: 8,
        }
    }, [isMobile]);

    const handleTogleShowDetails = useCallback(()=> {
        setShowDetails(!showDetails);
    }, [showDetails]);

    const renderBadges = useCallback(()=> (
        <CardPreviewBodyBadges>
            <SimpleBadge style={badgeCustomStyle}>💻 Informatique</SimpleBadge>
            <SimpleBadge style={badgeCustomStyle}>⌛️ Temps plein : du lundi au vendredi</SimpleBadge>
            <SimpleBadge style={badgeCustomStyle}>⏰ Début : 02 septembre 2024</SimpleBadge>
            <SimpleBadge style={badgeCustomStyle}>📆 CDD</SimpleBadge>
            <SimpleBadge style={badgeCustomStyle}>🏠 Télétravail occasionnel</SimpleBadge>
        </CardPreviewBodyBadges>
    ), [badgeCustomStyle]);

    return (
        <Card isHighlighted={isHighlighted}>
            {
                isHighlighted && (
                    <HighlightedBadge>
                        Mis en avant 🔥
                    </HighlightedBadge>
                )
            }

            <CardPreview onClick={handleTogleShowDetails}>
                <LogoAndBody>
                    <OrganisationImg 
                        src="https://help.apple.com/assets/654E7A78B450BEA420068D51/654E7A7AB450BEA420068D57/en_US/cfef5ce601689564e0a39b4773f20815.png"
                        alt="organisation logo"
                    />

                    <CardPreviewBody>
                        <CardTitle>Software Engineer Fullstack </CardTitle>
                        <OrganisationName>Apple</OrganisationName>

                        {
                            isMobile ? (
                                <CardPreviewBodyBadges>
                                    <SimpleBadge style={badgeCustomStyle}>🇨🇮 Côte-d’Ivoire</SimpleBadge>
                                    <SimpleBadge style={badgeCustomStyle}>💰 $60k-$100k</SimpleBadge>
                                </CardPreviewBodyBadges>
                            ) : (
                                <SimpleBadge style={{...badgeCustomStyle, marginTop: 5}}>🇨🇮 Côte-d’Ivoire</SimpleBadge>
                            )
                        }
                    </CardPreviewBody>
                </LogoAndBody>

                {!isMobile && renderBadges()}

                <Days isHighlighted={isHighlighted}>5j</Days>

                <IconWrapper isHighlighted={isHighlighted} onClick={handleTogleShowDetails}>
                    <Icon 
                        size={isMobile ? "1x" : "2x"}
                        color="#fff"
                        iconName={showDetails ? IconNameEnum.minus : IconNameEnum.plus}
                    />
                </IconWrapper>
            </CardPreview>
            {
                showDetails && (
                    <>
                        {isMobile && renderBadges()}

                        <Description>
                            <DescriptionSection>
                                <DetailTitle>Descriptif du poste</DetailTitle>
                                <DetailDescription>
                                    As a freak UX/UI designer you will dedicate your time to find nice and friendly ways to fulfill the wants and needs of our users.
                                </DetailDescription>
                            </DescriptionSection>

                            <DescriptionSection>
                                <DetailTitle>Profil recherché</DetailTitle>
                                <DetailDescription>
                                    Work with stakeholders to identify requirements
                                    Research customers, competitors, and products
                                    Develop personas, scenarios, and user stories
                                    Create wireframes, prototypes, and high-fidelity mock-ups
                                    Work with developers to ensure product quality
                                    Work closely with product owners to maximize customer success
                                </DetailDescription>
                            </DescriptionSection>

                            <DescriptionSection>
                                <DetailTitle>Déroulement des entretiens</DetailTitle>
                                <DetailDescription>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis. Ut commodo efficitur neque. Ut diam quam, semper iaculis condimentum ac, vestibulum eu nisl.
                                </DetailDescription>
                            </DescriptionSection>
                        </Description>

                        <ActionsButtons>
                            <ButtonGroup>
                                <SimpleButton style={buttonStyles}>
                                    <Icon 
                                        iconName={IconNameEnum.warning}
                                        size='lg'
                                    />
                                    En savoir plus
                                </SimpleButton>

                                <SimpleButton style={buttonStyles}>
                                    <Icon 
                                        iconName={IconNameEnum.share}
                                        size='lg'
                                    />
                                    Partager
                                </SimpleButton>

                                <SimpleButton style={buttonStyles}>
                                    <Icon 
                                        iconName={IconNameEnum.save}
                                        size='lg'
                                    />
                                    Sauvegarder
                                </SimpleButton>
                            </ButtonGroup>

                            <AnimatedGradianButton style={animatedButtonCustomStyle}>
                                Postuler
                            </AnimatedGradianButton>
                        </ActionsButtons>
                    </>
                )
            }
        </Card>
    )
}