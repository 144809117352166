import { FC, useCallback, useState } from "react";
import styled from "styled-components";
import { Input, SimpleButton } from "@components";
import { FormWrapper, InputLabel, InputWrapper, SubTitle, Title } from "./common";

interface Props {
    handleGoToSignin: ()=> void
}

const Section = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 760px){
        width: 80%;
        margin-left: 10%;
    }
`;

export const CreateNewPassword:FC<Props> = ({ handleGoToSignin })=> {

    const [index, setIndex] = useState<number>(0);
    const [password, setPassword] = useState<string>('');
    const [passwordConfirmed, setPasswordConfirmed] = useState<string>('');

    const handleCreateNewPassword = useCallback(()=> {
        if((password && passwordConfirmed) && (password ===  passwordConfirmed)){
            setIndex(1);
        }
    }, [password, passwordConfirmed]);

    const renderContent = useCallback(()=> {
        switch (index) {
            case 0:
                return (
                    <Section>
                        <Title>
                            Nouveau mot de passe 🤝
                        </Title>
                        <SubTitle>
                            Saisissez et confirmez votre nouveau mot de passe.
                        </SubTitle>

                        <FormWrapper>
                            <InputWrapper>
                                <InputLabel>
                                    Mot de passe*
                                </InputLabel>
                                <Input
                                    placeholder="Entrez le mot de passe"
                                    inputType='password'
                                    value={password}
                                    onChange={setPassword}
                                />
                            </InputWrapper>
                            <InputWrapper>
                                <InputLabel>
                                    Confirmation du mot de passe*
                                </InputLabel>
                                <Input
                                    placeholder="Confirmez le mot de passe"
                                    inputType='password'
                                    value={passwordConfirmed}
                                    onChange={setPasswordConfirmed}
                                />
                            </InputWrapper>

                            <SimpleButton onClick={handleCreateNewPassword}>
                                Confirmé
                            </SimpleButton>
                        </FormWrapper>
                    </Section>
                );

            case 1:
                return (
                    <Section>
                        <Title>
                            Tout s’est bien passé 👍
                        </Title>
                        <SubTitle>
                            Votre nouveau mot de passe a bien été enregistré.Vous pouvez désormais l’utiliser pour vous connecter.
                        </SubTitle>

                        <SimpleButton 
                            style={{width: '100%'}}
                            onClick={handleGoToSignin}
                        >
                            Me connecter
                        </SimpleButton>
                    </Section>
                );
            default:
                return null;
        }
    }, [handleCreateNewPassword, index, password, passwordConfirmed])

    return renderContent();
}