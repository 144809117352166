import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

interface Props {
    color?: string
    bgColor?: string
    fontSize?: string
    height?: string
}

export const DescriptionBadge = styled.label<Props>`
    color: ${props=> props.color || '#101214'};
    flex: 1;
    width: 100%;
    background-color: ${props=> props.bgColor || '#EFECE6'};
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
    font-size: ${props=> props.fontSize || '12px'};
    min-height: ${props=> props.height || '83px'};
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    border-radius: 8px;

    @media (min-width: 760px) {
        font-size: ${props=> props.fontSize || '14px'};
        min-height: ${props=> props.height || '100px'};
        padding: 17px;
        border-radius: 10px;
    }
`;