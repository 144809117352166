export const jobTypes = [
    {
        "id": "1",
        "label": "Tous selectionné",
        "subcategories": [
            "📆 CDD - Temporaire",
            "🏷️ CDI",
            "📚 Alternance",
            "🎓 Stage",
            "🧑‍💻 Freelance",
        ]
    },
];