import { FC, useCallback, useState } from "react";
import styled from "styled-components";
import TransparentBG from '@assets/images/transparentBG.png';
import { Icon } from "./icon";
import { IconNameEnum } from "@utilities/enums";

interface Props {
    defaultUri: string
    onImageChange: (file: File | null) => void;
}

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 170px;
    padding: 10px;
    background-color: #fff;
    border: dashed 1px #D4D5D5;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TransparentBGWrapper = styled.label`
    background-image: url(${TransparentBG});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 140px;
    cursor: pointer;
`;

const Input = styled.input`
    display: none;
`;

const PreviewContainer = styled.div`
    width: 70%;
    height: 140px;
`;

const ImagePreview = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const IconGroup = styled.div`
    display: flex;
    position: absolute;
    z-index: 99;
    top: 10px;
    right: 20px;
    gap: 5px;
`;

const IconWrapper = styled.label`
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: solid 1px #101214;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const IconWrapperTrash = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: solid 1px #B00000;
    background-color: #B00000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const ImagePicker: FC<Props> = ({ 
    onImageChange, 
    defaultUri,
}) => {
    const [selectedImage, setSelectedImage] = useState<string>(defaultUri || '');

    const handleImageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
            onImageChange(file);

            // Optionnel : nettoyer l'URL précédente
            return () => {
                URL.revokeObjectURL(imageUrl);
            };
        }
    }, [onImageChange]);

    const handleRemoveImage = useCallback(() => {
        if (selectedImage) {
            URL.revokeObjectURL(selectedImage);
        }
        setSelectedImage('');
        onImageChange(null);
    }, [selectedImage, onImageChange]);

    return (
        <Container>
            {selectedImage ? (
                <PreviewContainer>
                    <ImagePreview src={selectedImage} alt="Selected" />
                    <IconGroup>
                        <IconWrapper>
                            <Input type="file" accept="image/*" onChange={handleImageChange} />
                            <Icon 
                                iconName={IconNameEnum.edit}
                                color="#101214"
                                size='sm'
                            />
                        </IconWrapper>

                        <IconWrapperTrash onClick={handleRemoveImage}>
                            <Icon 
                                iconName={IconNameEnum.trash}
                                color="#fff"
                                size='sm'
                            />
                        </IconWrapperTrash>
                    </IconGroup>
                </PreviewContainer>
            ) : (
                <TransparentBGWrapper>
                    <Input type="file" accept="image/*" onChange={handleImageChange} />
                    <Icon 
                        iconName={IconNameEnum.plus}
                        color="#B0B0B0"
                        size='2x'
                    />
                </TransparentBGWrapper>
            )}
        </Container>
    );
};
