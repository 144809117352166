import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import { useScreenSize, useTranslation } from "@utilities/hooks";
import { TabNavigation } from '@connectedComponents';
import { 
    AnimatedGradianButton,
    AnimatedInputWithButton, 
    AnimatedText, DoubleCircle,
    FloatingCard, 
    FloatingCard2, Icon, 
    StarsSection,
} from "@components";
import { 
    BrandParagraph,
    BrandSection,
    FloatingCardleftContainer,
    FloatingCardRightContainer,
    FloatingImagesSection,
    HomeItem,
    HomeItemLabel,
    HomeSection,
    HomeSubSection,
    HomeSubTitle,
    HomeTitle,
    IconWrapper,
    Image,
    InfoParagraph, 
    InfoParagraphUnderline, 
    SearchSection, 
    Title, 
    TitleSection,
} from "./styles";
import { FontFamilyEnum, IconNameEnum, StackEnum } from "@utilities/enums";

import CardIllustrationMain from '@assets/images/cardIllustrationMain.png';
import CardIllustrationLeft from '@assets/images/cardIllustrationLeft.png';
import CardIllustrationRight from '@assets/images/cardIllustrationRight.png';

import Byme from '@assets/images/brands/byme.png';
import Collab from '@assets/images/brands/collab.png';
import Popkode from '@assets/images/brands/popkode.png';
import Wit from '@assets/images/brands/wit.png';
import { useControlers } from "./useControlers";

export const Home:React.FC = ()=> {

    const translate = useTranslation();
    const { isMobile } = useScreenSize();

    const {
        recruiterData,
        talentData,
    } = useControlers();

    const [search, setSearch] = useState<string>('');
    const [currentWordIndex, setCurrentWordIndex] = useState<number>(0);
    const words = ["job", "candidat", "entreprise"];

    const animatedButtonCustomStyles:CSSProperties = useMemo(()=> ({
        marginTop: isMobile ? 35 : 60,
        paddingLeft: 20,
        paddingRight: 14,
        boxShadow: '-9px 17px 39px 0px rgba(0,0,0,0.11)', 
    }), [isMobile]);

    const word = useMemo(()=> (words[currentWordIndex]), [currentWordIndex, words]);

    const handleSearch = useCallback(()=> {

    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
          setCurrentWordIndex(prevIndex => (prevIndex + 1) % words.length);
        }, 1500);
    
        return () => clearInterval(intervalId);
    }, [words.length]);

    return (
        <TabNavigation activeStack={StackEnum.Home} enableFaq>
            <StarsSection />

            <TitleSection>
                <Title>
                    Trouvez {isMobile ? '' : 'rapidement'} votre 
                    <AnimatedText 
                        font={FontFamilyEnum.SF_Pro_Display_Black} 
                        size={isMobile ? "25px" : "64px"} 
                        marginLeft={isMobile ? "5px" : "12px"}
                    >
                        {word}
                    </AnimatedText>
                </Title>

                <Title>
                    <AnimatedText 
                        font={FontFamilyEnum.SF_Pro_Display_Black} 
                        size={isMobile ? "25px" : "64px"} 
                        marginRight={isMobile ? "5px" : "12px"}
                    >
                        idéal
                    </AnimatedText> 

                    grâce à notre 

                    <AnimatedText 
                        font={FontFamilyEnum.SF_Pro_Display_Black} 
                        size={isMobile ? "25px" : "64px"} 
                        marginLeft={isMobile ? "5px" : "12px"}
                    >
                        IA
                    </AnimatedText>
                </Title>
            </TitleSection>

            <SearchSection>
                <AnimatedInputWithButton 
                    placeholder={isMobile ? "Cherchez un job..." : "Cherchez un job, mot-clé ou entreprise"}
                    value={search} 
                    onChange={setSearch} 
                    buttonName="Explorer"
                    onButtonPress={handleSearch} 
                />

                <InfoParagraph>
                    Vous êtes une entreprise? 
                    <InfoParagraphUnderline>
                        Postez une offre d’emploi
                    </InfoParagraphUnderline>
                </InfoParagraph>
            </SearchSection>

            <FloatingImagesSection>
                <FloatingCardleftContainer>
                    <FloatingCard2 width={isMobile ? "120px" : "350px"}>
                        <img src={CardIllustrationLeft} width={'100%'} />
                    </FloatingCard2>
                </FloatingCardleftContainer>

                <FloatingCard width={isMobile ? "300px" : "871px"}>
                    <img src={CardIllustrationMain} width={'100%'} />
                </FloatingCard>

                <FloatingCardRightContainer>
                    <FloatingCard2 width={isMobile ? "120px" : "350px"}>
                        <img src={CardIllustrationRight} width={'100%'} />
                    </FloatingCard2>
                </FloatingCardRightContainer>
            </FloatingImagesSection>

            <BrandSection>
                <BrandParagraph>
                    Ils nous font <br /> confiance
                </BrandParagraph>

                <Image src={Byme} width={isMobile ? 53 : 99} alt="byme logo" />
                <Image src={Wit} width={isMobile ? 57 : 106} alt="wit logo" />
                {!isMobile && <Image src={Collab} width={162} alt="collab logo" />}
                <Image src={Popkode} width={isMobile ? 92 : 215} alt="popkode logo" />
            </BrandSection>

            <HomeSection>
                <HomeTitle>
                    Vous recrutez ?
                </HomeTitle>

                <HomeSubTitle>
                    Le CV papier traditionnel est une chose du passé. Avec Ohasis, gagnez un temps précieux en simplifiant vos recrutements avec notre système de matching !
                </HomeSubTitle>

                <HomeSubSection>
                    {
                        recruiterData.map((el, i)=> {
                            return (
                                <HomeItem
                                    key={i}
                                >
                                    <DoubleCircle size={isMobile ? 71 : 112}>
                                        {el.icon}
                                    </DoubleCircle>
                                    
                                    <HomeItemLabel>
                                        {el.label}
                                    </HomeItemLabel>
                                </HomeItem>
                            )
                        })
                    }
                </HomeSubSection>

                <AnimatedGradianButton style={animatedButtonCustomStyles}>
                    Postez une offre d’emploi <IconWrapper><Icon iconName={IconNameEnum.arrowRight} color="#fff" /></IconWrapper>
                </AnimatedGradianButton>
            </HomeSection>

            <HomeSection>
                <HomeTitle>
                    Vous recherchez un job ?
                </HomeTitle>

                <HomeSubTitle>
                    Découvrez comment notre IA avancée peut vous guider vers les meilleures opportunités. Simplifiez votre recherche et trouvez l'emploi idéal rapidement et efficacement.                    
                </HomeSubTitle>
                <HomeSubSection>
                    {
                        talentData.map((el, i)=> {
                            return (
                                <HomeItem
                                    key={i}
                                >
                                    <DoubleCircle size={isMobile ? 71 : 112}>
                                        {el.icon}
                                    </DoubleCircle>
                                    
                                    <HomeItemLabel>
                                        {el.label}
                                    </HomeItemLabel>
                                </HomeItem>
                            )
                        })
                    }
                </HomeSubSection>

                <AnimatedGradianButton style={{...animatedButtonCustomStyles, marginBottom: isMobile ? 100 : 150}}>
                    Explorez les jobs <IconWrapper><Icon iconName={IconNameEnum.arrowRight} color="#fff" /></IconWrapper>
                </AnimatedGradianButton>
            </HomeSection>
        </TabNavigation>
    )
}
