import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const RoundedButton = styled.button`
    color: #101214;
    background-color: #EFECE6;
    height: 37px;
    border-radius: 37px;
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    border: none;
    cursor: pointer;
    font-family: ${FontFamilyEnum.PlusJakartSans_Bold};

    @media (min-width: 950px) {
        height: 45px;
        border-radius: 45px;
        font-size: 18px;
        padding-left: 24px;
        padding-right: 24px;
    }
`;