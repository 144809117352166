import styled from "styled-components";

export const SearchSection = styled.section`
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #EFECE6;

    @media (min-width: 760px) {
        flex-direction: row;
        gap: 0px;
        padding: 42px;
        padding-top: 60px;
        padding-bottom: 35px;
    }
`;