import { FontFamilyEnum, IconNameEnum } from '@utilities/enums';
import React, { CSSProperties, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Icon } from './icon';
import { SimpleButton } from './simpleButton';
import { useScreenSize } from '@utilities/hooks';

const SearchBarContainer = styled.div<{noRadius?: boolean, disabled?: boolean}>`
    position: relative;
    width: 100%;
    min-height: 40px;
    border-radius: ${props=> props.noRadius ? '0px' : '8px'};
    border: solid 1px #F5F4F2;
    background-color: ${props=> props.disabled ? '#eeeeee' : '#ffffff'};
    display: flex;
    align-items: center;

    @media (min-width: 760px){
        min-height: 48px;
        border-radius: ${props=> props.noRadius ? '0px' : '12px'};
    }
`;

const Input = styled.input`
    flex: 1;
    height: 38px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #101214;
    font-size: 12px;
    padding-left: 14px;
    padding-right: 14px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    ::placeholder {
        color: #C3C3C3;
    }

    @media (min-width: 760px){
        height: 46px;
        font-size: 15px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
    }
`;

const SuggestionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

interface SearchBarProps {
    placeholder: string
    suggestions: string[];
    onSearch: (query: string) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({ 
    placeholder, suggestions, onSearch,
}) => {

  const { isMobile } = useScreenSize();

  const [query, setQuery] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);

  const addButtonStyles:CSSProperties = {
    height: isMobile ? 30 : 35,
    marginRight: isMobile ? 5 : 7,
    borderRadius: isMobile ? 10 : 12
  };

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value;
    setQuery(userInput);

    if (userInput.length > 0) {
      const filtered = suggestions.filter(suggestion =>
        suggestion.toLowerCase().includes(userInput.toLowerCase())
      );

      setFilteredSuggestions(filtered);
    }
  }, [suggestions]);

  const handleClick = useCallback((suggestion: string) => {
    onSearch(suggestion);
    setQuery('');
    setFilteredSuggestions([]);
  }, [onSearch]);

  const handleAdd = useCallback(()=> {
    if(query){
        onSearch(query);
        setQuery('');
    }
  }, [onSearch, query]);

  return (
    <SearchBarContainer>
        <Input
            type="text"
            value={query}
            onChange={handleChange}
            placeholder={placeholder}
        />
        <SimpleButton 
          style={addButtonStyles}
          onClick={handleAdd}
        >
          Ajouter
        </SimpleButton>

        {filteredSuggestions.length > 0 && (
            <SuggestionsList>
            {
                filteredSuggestions.map((suggestion, index) => (
                    <SuggestionItem key={index} onClick={() => handleClick(suggestion)}>
                        {suggestion}
                    </SuggestionItem>
                ))
            }
            </SuggestionsList>
        )}
    </SearchBarContainer>
  );
};
