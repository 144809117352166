import { RootState } from "@data/store";
import { useSelector } from "react-redux";

export const useRecruiterSelectors = () => {

    const { 
        recruiter,
        organisation,
        // userRecruiterToken,
        talentCVList,
        organisationTestList,
    } = useSelector((state: RootState) => state.recruiter);

    return {
        recruiter,
        organisation,
        // userRecruiterToken,
        talentCVList,
        organisationTestList,
    };
};

