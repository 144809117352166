import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const SearchSection = styled.section`
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #EFECE6;

    @media (min-width: 760px) {
        flex-direction: row;
        gap: 0px;
        padding: 42px;
        padding-top: 60px;
        padding-bottom: 35px;
    }
`;

export const FilterTextElement = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    font-size: 11px;
    margin-top: 10px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Bold};
`;

export const Title = styled.h3`
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
    font-size: 20px;
    margin: 0%;
    margin-top: 35px;
    margin-left: 10px;
    color: #101214;
`;

export const OrganisationListWrapper = styled.section`
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    padding: 20px;
    margin-top: 20px;
    background-color: #F9F9F9;
    box-sizing: border-box;

    & > * {
        flex: 0 0 auto;
    }

    @media (min-width: 760px) {
        justify-content: center;
        overflow-x: hidden;
        flex-wrap: wrap;
        gap: 38px;
    }
`;
