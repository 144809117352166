import { FC, useCallback, useMemo } from "react";

import {
    FooterSection,
    FooterSubSection,
    FooterSubTitle,
    FooterSubTitleMail,
    FooterTextCopyRight,
    FooterTitle,
    DropdownWrapper,
    MailText, 
    FooterDescriptionDesktopContainer,
    FooterDescriptionMobilContainer,
    FooterDescriptionMobilSubContainer,
    FooterDescriptionParagraph,
    FooterDescriptionParagraphContainer,
    Logo,
    FooterWrapper,
} from './styles';
import { AnimatedLogo, Dropdown } from "@components";
import { useScreenSize } from "@utilities/hooks";
import { useControler } from "./useControler";

interface Props {
    enableFaq?: boolean
}

export const Footer:FC<Props> = ({
    enableFaq = false,
})=> {

    const { isMobile } = useScreenSize();
    const { faqData } = useControler();

    const currentyear = useMemo(()=> {
        return (new Date()).getFullYear();
    }, []);

    const renderFooterDescription = useCallback(()=> (
        <FooterDescriptionParagraphContainer>
            <FooterDescriptionParagraph>
                La plateforme révolutionnaire qui simplifie la recherche d'emploi et le recrutement avec des outils innovants et l’IA, connectant efficacement recruteurs et candidats. Transformez votre processus de recrutement avec notre technologie avancée et découvrez une nouvelle manière de trouver des opportunités et des talents.
            </FooterDescriptionParagraph>
            <FooterDescriptionParagraph>
            🇪🇺 Conforme à la réglementation de l'UE en matière d'IA.
            </FooterDescriptionParagraph>
        </FooterDescriptionParagraphContainer>
    ), []);

    const renderFooterDescriptionSection = useCallback(()=> {
        if(isMobile){
            return (
                <FooterDescriptionMobilContainer>
                    <FooterDescriptionMobilSubContainer>
                        {/* <AnimatedLogo /> */}
                        <Logo isMobile={isMobile}>
                            Ohasis
                        </Logo>

                        <MailText>
                            contact@ohas.is
                        </MailText>
                    </FooterDescriptionMobilSubContainer>
                    {renderFooterDescription()}
                </FooterDescriptionMobilContainer>
            )
        }

        return (
            <FooterDescriptionDesktopContainer enableFaq={enableFaq}>
                {/* <AnimatedLogo /> */}
                <Logo isMobile={isMobile}>
                    Ohasis
                </Logo>

                {renderFooterDescription()}

                <MailText>
                    contact@ohas.is
                </MailText>
            </FooterDescriptionDesktopContainer>
        )
    }, [enableFaq, isMobile, renderFooterDescription]);

    return (
        <FooterWrapper enableFaq={enableFaq}>
            <FooterSection >
                {
                    enableFaq && (
                        <>
                            <FooterTitle>
                                FAQ
                            </FooterTitle>

                            <FooterSubTitle>
                                Vous avez encore une question ?
                            </FooterSubTitle>
                            <FooterSubTitle>
                                Contactez notre équipe via <FooterSubTitleMail>contact@ohas.is</FooterSubTitleMail>
                            </FooterSubTitle>

                            <DropdownWrapper>
                                <Dropdown
                                    elements={faqData}
                                />
                            </DropdownWrapper>
                        </>
                    )
                }

                {renderFooterDescriptionSection()}

                <FooterSubSection>
                    <FooterTextCopyRight>
                        Copyright © {currentyear} Ohasis
                    </FooterTextCopyRight>

                    <FooterTextCopyRight>
                        Tous droits réservés
                    </FooterTextCopyRight>
                </FooterSubSection>
            </FooterSection>
        </FooterWrapper>
    )
}