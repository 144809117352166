import { CSSProperties, FC, useCallback, useMemo } from "react";
import { useControllers } from "./useControllers";
import { 
    BadgeWrapper, DescriptionBadge, Modal, 
    ModalContainer, ModalSubTitle, 
    ModalTitle, SimpleBadge,
} from "@components";
import { 
    Avatar, InfoLabel, InfosWrapper, 
    ModalSection, ModalSectionBody, 
    ModalSectionHeader, ModalSectionSubHeader, 
    ProfileDescription, ProfileName, 
    ProfileTitle, ModalSectionBodySeparator,
    Location
} from "./styles";
import { CV, UserTalent } from "@utilities/types";
import { formatDate, getMonthName } from "@utilities/functions";

interface Props {
    talent: UserTalent
    talentCV: CV
    isVisible: boolean
    onClose: ()=> void
}

export const TalentCVModal:FC<Props> = ({
    talent,
    talentCV,
    isVisible,
    onClose,
})=> {

    const {  } = useControllers();

    const avatarUri = useMemo(()=> (
        `${process.env.REACT_APP_BASE_PUBLIC_URL}/${talent?.avatar?.fileDir}/${talent?.avatar?.filename}`
    ), [talent]);

    const infoLabelStyles:CSSProperties = useMemo(()=> (
        {
            marginBottom: 5
        }
    ), []);

    const renderProfilInfos = useCallback(()=> (
        <ModalSection>
            <ModalSectionHeader>
                <Avatar src={avatarUri} />

                <ModalSectionSubHeader>
                    <ProfileName>{talent.firstname} {talent.lastname}</ProfileName>
                    <ProfileTitle>{talentCV.title}</ProfileTitle>
                </ModalSectionSubHeader>
            </ModalSectionHeader>

            <ModalSectionBody>
                <ProfileDescription>{talentCV.shortDescription}</ProfileDescription>

                <BadgeWrapper style={{marginTop: 20}}>
                    <SimpleBadge>✉️ {talent?.mail}</SimpleBadge>
                    {talent?.phone && <SimpleBadge>📞 {talent.phone}</SimpleBadge>}
                    {talent?.birth && <SimpleBadge>🎂 {formatDate(talent.birth)}</SimpleBadge>}
                    {talent?.gender && <SimpleBadge>⚧️ {talent.gender}</SimpleBadge>}
                    {talentCV?.languages && (
                        <SimpleBadge>
                            🗣️ {talentCV?.languages.map((el, i)=> {
                                return (talentCV?.languages.length - 1 === i ? el : `${el} - `);
                            })}
                        </SimpleBadge>
                        )}
                </BadgeWrapper>
            </ModalSectionBody>
        </ModalSection>
    ), [avatarUri, talent, talentCV]);

    const renderFindedJob = useCallback(()=> (
        <ModalSection>
            <ModalSectionHeader>
                <ModalSectionSubHeader>
                    <ProfileName>Job recherché</ProfileName>
                </ModalSectionSubHeader>
            </ModalSectionHeader>

            <ModalSectionBody>
            {
                talentCV.criteria?.jobTypes && 
                talentCV.criteria.jobTypes.length > 0 && (
                    <InfosWrapper>
                        <InfoLabel style={infoLabelStyles}>
                            Intitulé de poste
                        </InfoLabel>
                        
                        <BadgeWrapper>
                            {
                                talentCV.criteria.jobTypes.map((el, i)=> {
                                    return (
                                        <SimpleBadge key={i} >
                                            {el}
                                        </SimpleBadge>
                                    )
                                })
                            }
                        </BadgeWrapper>
                    </InfosWrapper>
                )
            }

            {
                talentCV.criteria?.localisation && (
                    <InfosWrapper>
                        <InfoLabel style={infoLabelStyles}>
                            Localisation
                        </InfoLabel>
                        
                        <Location>
                            📍 {talentCV.criteria?.localisation}
                        </Location>
                    </InfosWrapper>
                )
            }

            {
                talentCV.criteria?.contratTypes && (
                    <InfosWrapper>
                        <InfoLabel style={infoLabelStyles}>
                            Type de contrat
                        </InfoLabel>
                        
                        <BadgeWrapper>
                        {
                            talentCV.criteria?.contratTypes.map((el, i)=> (
                                <SimpleBadge key={i}>
                                    {el}
                                </SimpleBadge>
                            ))
                        }
                        </BadgeWrapper>
                    </InfosWrapper>
                )
            }

            {
                talentCV.criteria?.teleWoring && 
                talentCV.criteria.teleWoring.length > 0 && (
                    <InfosWrapper>
                        <InfoLabel>
                            Télétravail
                        </InfoLabel>
                        
                        <BadgeWrapper>
                            {
                                talentCV.criteria.teleWoring.map((el, i)=> {
                                    return (
                                        <SimpleBadge key={i} >
                                            {el}
                                        </SimpleBadge>
                                    )
                                })
                            }
                        </BadgeWrapper>
                    </InfosWrapper>
                )
            }

            {
                talentCV.criteria?.level && (
                    <InfosWrapper>
                        <InfoLabel style={infoLabelStyles}>
                            Niveau d’expérience
                        </InfoLabel>
                        
                        <SimpleBadge>
                            {talentCV.criteria?.level}
                        </SimpleBadge>
                    </InfosWrapper>
                )
            }

            {
                talentCV.criteria?.minSalary && (
                    <InfosWrapper>
                        <InfoLabel style={infoLabelStyles}>
                            Salaire minimum
                        </InfoLabel>
                        
                        <SimpleBadge>
                            {talentCV.criteria?.minSalary} F par an
                        </SimpleBadge>
                    </InfosWrapper>
                )
            }
            </ModalSectionBody>
        </ModalSection>
    ), [infoLabelStyles, talentCV]);

    const renderExperiences = useCallback(()=> {
        if(!(talentCV.professionalExperiences && talentCV.professionalExperiences.length > 0)) return;

        return (
            <ModalSection>
                <ModalSectionHeader>
                    <ModalSectionSubHeader>
                        <ProfileName>Expériences</ProfileName>
                    </ModalSectionSubHeader>
                </ModalSectionHeader>

                <ModalSectionBody>
                    {
                        talentCV.professionalExperiences.map((exp, i)=> (
                            <ModalSectionBodySeparator key={i}>
                                <InfosWrapper>
                                    <InfoLabel>
                                        Intitulé de poste
                                    </InfoLabel>

                                    <BadgeWrapper>
                                        <SimpleBadge>
                                            {exp.name}
                                        </SimpleBadge>
                                    </BadgeWrapper>
                                </InfosWrapper>

                                <InfosWrapper>
                                    <InfoLabel>
                                        Entreprise ou client
                                    </InfoLabel>

                                    <BadgeWrapper>
                                        <SimpleBadge>
                                            {exp.organization}
                                        </SimpleBadge>
                                    </BadgeWrapper>
                                </InfosWrapper>

                                <InfosWrapper>
                                    <InfoLabel>
                                        Localisation
                                    </InfoLabel>

                                    <BadgeWrapper>
                                        <SimpleBadge>
                                            {exp.place} {exp.fullTeleWork && `(Télétravail total)`}
                                        </SimpleBadge>
                                    </BadgeWrapper>
                                </InfosWrapper>

                                <InfosWrapper>
                                    <InfoLabel>
                                        Type de contrat
                                    </InfoLabel>

                                    <BadgeWrapper>
                                        <SimpleBadge>
                                            {exp.contratType}
                                        </SimpleBadge>
                                    </BadgeWrapper>
                                </InfosWrapper>

                                <InfosWrapper>
                                    <InfoLabel>
                                        Date
                                    </InfoLabel>

                                    <BadgeWrapper>
                                        <SimpleBadge>
                                            📅  De {`${getMonthName(exp.startMonth).toLowerCase()} ${exp.startYear}`} à {exp.isStillInPost ? "aujourd'hui" : `${getMonthName(exp.endMonth!).toLowerCase()} ${exp.endYear}`}
                                        </SimpleBadge>
                                    </BadgeWrapper>
                                </InfosWrapper>

                                <InfosWrapper>
                                    <InfoLabel>
                                        Description, missions
                                    </InfoLabel>

                                    <BadgeWrapper>
                                        <DescriptionBadge>
                                            {exp.description}
                                        </DescriptionBadge>
                                    </BadgeWrapper>
                                </InfosWrapper>

                                {
                                    exp?.skills.length > 0 && (
                                        <InfosWrapper>
                                            <InfoLabel>
                                                Compétences
                                            </InfoLabel>
                                            <BadgeWrapper>
                                                {
                                                    exp.skills.map((el, j)=> (
                                                        <SimpleBadge key={j} color="#fff" bgColor="#0C1421" >
                                                            {el}
                                                        </SimpleBadge>
                                                    ))
                                                }
                                            </BadgeWrapper>
                                        </InfosWrapper>
                                    )
                                }
                            </ModalSectionBodySeparator>
                        ))
                    }
                </ModalSectionBody>
            </ModalSection>
        )
    }, [talentCV]);

    const renderSkills = useCallback(()=> {
        if(!(talentCV.skills && talentCV.skills.length > 0)) return;

        return (
            <ModalSection>
                <ModalSectionHeader>
                    <ModalSectionSubHeader>
                        <ProfileName>Compétences & expertises</ProfileName>
                    </ModalSectionSubHeader>
                </ModalSectionHeader>

                <ModalSectionBody>
                    <BadgeWrapper style={{marginTop: 30}}>
                        {
                            talentCV.skills.map((el, i)=> (
                                <SimpleBadge key={i} color="#fff" bgColor="#0C1421">
                                    {el}
                                </SimpleBadge>
                            ))
                        }
                    </BadgeWrapper>
                </ModalSectionBody>
            </ModalSection>
        )
    }, [talentCV]);

    const renderFormations = useCallback(()=> {
        if(!(talentCV.formations && talentCV.formations.length > 0)) return;

        return (
            <ModalSection>
                <ModalSectionHeader>
                    <ModalSectionSubHeader>
                        <ProfileName>Diplômes & formations</ProfileName>
                    </ModalSectionSubHeader>
                </ModalSectionHeader>

                <ModalSectionBody>
                {
                    talentCV.formations.map((formation, i)=> (
                        <ModalSectionBodySeparator key={i}>
                            <InfosWrapper>
                                <InfoLabel>
                                    Nom
                                </InfoLabel>

                                <BadgeWrapper>
                                    <SimpleBadge>
                                        ✨ {formation.name}
                                    </SimpleBadge>
                                </BadgeWrapper>
                            </InfosWrapper>

                            <InfosWrapper>
                                <InfoLabel>
                                    Niveau
                                </InfoLabel>

                                <BadgeWrapper>
                                    <SimpleBadge>
                                        ⚡️ {formation.level}
                                    </SimpleBadge>
                                </BadgeWrapper>
                            </InfosWrapper>

                            <InfosWrapper>
                                <InfoLabel>
                                    École ou organisme
                                </InfoLabel>

                                <BadgeWrapper>
                                    <SimpleBadge>
                                        🎓 {formation.organization}
                                    </SimpleBadge>
                                </BadgeWrapper>
                            </InfosWrapper>

                            <InfosWrapper>
                                <InfoLabel>
                                    Date
                                </InfoLabel>

                                <BadgeWrapper>
                                    <SimpleBadge>
                                        📅  De {`${getMonthName(formation.startMonth).toLowerCase()} ${formation.startYear}`} à {formation.isStillInFormation ? "aujourd'hui" : `${getMonthName(formation.endMonth!).toLowerCase()} ${formation.endYear}`}
                                    </SimpleBadge>
                                </BadgeWrapper>
                            </InfosWrapper>

                            <InfosWrapper>
                                <InfoLabel>
                                    Description, missions
                                </InfoLabel>

                                <BadgeWrapper>
                                    <DescriptionBadge>
                                        {formation.description}
                                    </DescriptionBadge>
                                </BadgeWrapper>
                            </InfosWrapper>

                            {
                                formation?.skills.length > 0 && (
                                    <InfosWrapper>
                                        <InfoLabel>
                                            Compétences
                                        </InfoLabel>
                                        <BadgeWrapper>
                                            {
                                                formation.skills.map((el, j)=> (
                                                    <SimpleBadge key={j} color="#fff" bgColor="#0C1421" >
                                                        {el}
                                                    </SimpleBadge>
                                                ))
                                            }
                                        </BadgeWrapper>
                                    </InfosWrapper>
                                )
                            }
                        </ModalSectionBodySeparator>
                    ))
                }
                </ModalSectionBody>
            </ModalSection>
        )
    }, [talentCV]);

    const renderOtherRessources = useCallback(()=> {
        if(!(talentCV.linkedin || talentCV.website || talentCV.otherLink)) return;

        return (
            <ModalSection>
                <ModalSectionHeader>
                    <ModalSectionSubHeader>
                        <ProfileName>Autres ressources</ProfileName>
                    </ModalSectionSubHeader>
                </ModalSectionHeader>

                <ModalSectionBody>
                    {
                        talentCV.linkedin && (
                            <InfosWrapper>
                                <InfoLabel>
                                    LinkedIn
                                </InfoLabel>

                                <BadgeWrapper>
                                    <SimpleBadge>
                                        🤝️  {talentCV.linkedin}
                                    </SimpleBadge>
                                </BadgeWrapper>
                            </InfosWrapper>
                        )
                    }

                    {
                        talentCV.website && (
                            <InfosWrapper>
                                <InfoLabel>
                                    Site internet
                                </InfoLabel>

                                <BadgeWrapper>
                                    <SimpleBadge>
                                        🖥️  {talentCV.website}
                                    </SimpleBadge>
                                </BadgeWrapper>
                            </InfosWrapper>
                        )
                    }

                    {
                        talentCV.otherLink && (
                            <InfosWrapper>
                                <InfoLabel>
                                    Autre lien
                                </InfoLabel>

                                <BadgeWrapper>
                                    <SimpleBadge>
                                        🖥️  {talentCV.otherLink}
                                    </SimpleBadge>
                                </BadgeWrapper>
                            </InfosWrapper>
                        )
                    }
                </ModalSectionBody>
            </ModalSection>
        )
    }, [talentCV]);

    return (
        <Modal
            isVisible={isVisible}
            onClose={onClose}
        >
            <ModalContainer style={{minHeight: '90vh'}}>
                <ModalTitle>
                    Voici votre curriculum vitae
                </ModalTitle>
                <ModalSubTitle>
                    Découvrez comment les recruteurs perçoivent votre profil et optimisez votre présentation.
                </ModalSubTitle>

                {renderProfilInfos()}

                {renderFindedJob()}

                {renderExperiences()}

                {renderSkills()}

                {renderFormations()}

                {renderOtherRessources()}
            </ModalContainer>
        </Modal>
    )
}