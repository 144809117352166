import { usePostsDispatch, usePostSelectors, useTalentSelectors } from "@utilities/hooks";
import { TalentAccount_UnsolicitedApplicationsComponent } from "./TalentAccount_UnsolicitedApplications.component";
import { Post } from "@utilities/types";
import { useEffect, useState } from "react";

export const TalentAccount_UnsolicitedApplications:React.FC = ()=> {

    const { talent } = useTalentSelectors();
    const { userTalentPosts } = usePostSelectors();
    const { onGetTalentResponseToJobRequest } = usePostsDispatch();

    const [postList, setPostList] = useState<Post[]>([]);

    useEffect(()=> {
        if(talent){
            onGetTalentResponseToJobRequest(talent._id!);
        }
    }, [talent]);

    useEffect(()=> {
        if(userTalentPosts){
            setPostList(userTalentPosts);
        }
    }, [userTalentPosts]);

    return (
        <TalentAccount_UnsolicitedApplicationsComponent 
            postList={postList}
        />
    )
}
