export const activitiesSectors = [
    {
        "id": "2",
        "label": "🎓 Académique / Recherche",
        "subcategories": [
            "Professeur d’université",
            "Chercheur académique",
            "Responsable de laboratoire"
        ]
    },
    {
        "id": "3",
        "label": "🚀 Aérospatial / Défense",
        "subcategories": [
            "Ingénieur aérospatial",
            "Analyste en défense",
            "Technicien en aéronautique"
        ]
    },
    {
        "id": "4",
        "label": "🌾 Agriculture",
        "subcategories": [
            "Agronome",
            "Technicien agricole",
            "Responsable de ferme"
        ]
    },
    {
        "id": "5",
        "label": "🏛️ Architecture",
        "subcategories": [
            "Architecte",
            "Designer d’intérieur",
            "Urbaniste"
        ]
    },
    {
        "id": "6",
        "label": "🎨 Arts / Création",
        "subcategories": [
            "Artiste graphique",
            "Illustrateur",
            "Conservateur de musée"
        ]
    },
    {
        "id": "7",
        "label": "🤝 Association / ONG",
        "subcategories": [
            "Chargé de mission associative",
            "Responsable de programme ONG",
            "Coordinateur de projet"
        ]
    },
    {
        "id": "8",
        "label": "🏦 Banques / Assurances / Finance",
        "subcategories": [
            "Analyste financier",
            "Gestionnaire de portefeuille",
            "Conseiller en assurance"
        ]
    },
    {
        "id": "9",
        "label": "🛠️ Construction / BTP",
        "subcategories": [
            "Conducteur de travaux",
            "Architecte d’intérieur",
            "Ingénieur en génie civil"
        ]
    },
    {
        "id": "10",
        "label": "📝 Conseil / Audit",
        "subcategories": [
            "Consultant en stratégie",
            "Auditeur financier",
            "Expert-comptable"
        ]
    },
    {
        "id": "11",
        "label": "🎭 Culture / Média / Divertissement",
        "subcategories": [
            "Journaliste",
            "Producteur audiovisuel",
            "Directeur artistique"
        ]
    },
    {
        "id": "12",
        "label": "🏬 Distribution",
        "subcategories": [
            "Responsable de magasin",
            "Gestionnaire e-commerce",
            "Directeur de la distribution"
        ]
    },
    {
        "id": "13",
        "label": "🏫 Éducation / Formation / Recrutement",
        "subcategories": [
            "Formateur",
            "Chargé de recrutement",
            "Conseiller pédagogique"
        ]
    },
    {
        "id": "14",
        "label": "🌍 Environnement / Développement durable",
        "subcategories": [
            "Consultant en développement durable",
            "Chargé de mission environnement",
            "Spécialiste en énergie renouvelable"
        ]
    },
    {
        "id": "15",
        "label": "🏦 Finance / Comptabilité",
        "subcategories": [
            "Comptable",
            "Auditeur interne",
            "Analyste en crédit"
        ]
    },
    {
        "id": "16",
        "label": "💼 Gestion de projet",
        "subcategories": [
            "Chef de projet",
            "Coordinateur de projet",
            "Manager de projet"
        ]
    },
    {
        "id": "17",
        "label": "🍔 Food et boisson",
        "subcategories": [
            "Chef de cuisine",
            "Responsable qualité alimentaire",
            "Directeur de la restauration"
        ]
    },
    {
        "id": "18",
        "label": "🏨 Hôtellerie / Tourisme / Loisirs",
        "subcategories": [
            "Directeur d’hôtel",
            "Guide touristique",
            "Manager de loisirs"
        ]
    },
    {
        "id": "19",
        "label": "🏠 Immobilier",
        "subcategories": [
            "Agent immobilier",
            "Gestionnaire de patrimoine",
            "Consultant en immobilier commercial"
        ]
    },
    {
        "id": "20",
        "label": "🏭 Industrie",
        "subcategories": [
            "Ingénieur en agroalimentaire",
            "Responsable de production",
            "Technicien en maintenance industrielle"
        ]
    },
    {
        "id": "21",
        "label": "⚙️ Ingénierie",
        "subcategories": [
            "Ingénieur civil",
            "Ingénieur mécanique",
            "Consultant en ingénierie"
        ]
    },
    {
        "id": "22",
        "label": "🖥️ Informatique / Développement",
        "subcategories": [
            "Administrateur systèmes",
            "Ingénieur réseau",
            "Développeur backend"
        ]
    },
    {
        "id": "23",
        "label": "⚖️ Légal / Justice",
        "subcategories": [
            "Avocat",
            "Juriste d’entreprise",
            "Notaire"
        ]
    },
    {
        "id": "24",
        "label": "📦 Logistique / Supply Chain",
        "subcategories": [
            "Gestionnaire de stock",
            "Responsable supply chain",
            "Coordinateur logistique"
        ]
    },
    {
        "id": "25",
        "label": "👗 Mode / Luxe / Beauté / Art de vivre",
        "subcategories": [
            "Styliste",
            "Chef de produit cosmétique",
            "Responsable de boutique de luxe"
        ]
    },
    {
        "id": "26",
        "label": "🚚 Mobilité / Transport",
        "subcategories": [
            "Logisticien",
            "Conducteur de transport routier",
            "Responsable de flotte"
        ]
    },
    {
        "id": "27",
        "label": "🧠 Psychologie / Bien-être",
        "subcategories": [
            "Psychologue",
            "Coach en développement personnel",
            "Conseiller en bien-être"
        ]
    },
    {
        "id": "28",
        "label": "📢 Publicité / Marketing / Agence",
        "subcategories": [
            "Chargé de communication",
            "Responsable marketing digital",
            "Directeur de création"
        ]
    },
    {
        "id": "29",
        "label": "📚 Rédaction / Traduction",
        "subcategories": [
            "Rédacteur technique",
            "Traducteur",
            "Correcteur"
        ]
    },
    {
        "id": "30",
        "label": "🔬 Sciences / Recherche",
        "subcategories": [
            "Chercheur en biotechnologie",
            "Scientifique de laboratoire",
            "Analyste en recherche clinique"
        ]
    },
    {
        "id": "31",
        "label": "🧹 Services de nettoyage / Entretien",
        "subcategories": [
            "Agent d’entretien",
            "Responsable hygiène et propreté",
            "Technicien de surface"
        ]
    },
    {
        "id": "32",
        "label": "🧳 Services aux entreprises",
        "subcategories": [
            "Chargé de clientèle entreprise",
            "Gestionnaire de coworking",
            "Responsable d’incubateur"
        ]
    },
    {
        "id": "33",
        "label": "🏥 Santé / Social / Environnement",
        "subcategories": [
            "Médecin",
            "Chargé de projet environnemental",
            "Responsable d’association caritative"
        ]
    },
    {
        "id": "34",
        "label": "🏢 Secteur public et administration",
        "subcategories": [
            "Fonctionnaire territorial",
            "Responsable administratif",
            "Gestionnaire de collectivité"
        ]
    },
    {
        "id": "35",
        "label": "💻 Tech",
        "subcategories": [
            "Développeur logiciel",
            "Data scientist",
            "Ingénieur en cybersécurité"
        ]
    },
    {
        "id": "36",
        "label": "🌐 Télécommunications",
        "subcategories": [
            "Ingénieur télécom",
            "Responsable de réseau",
            "Technicien en télécommunications"
        ]
    }
];
