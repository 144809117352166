import { NavigationPathsEnum } from "@utilities/enums";
import { useNavigation } from "@utilities/hooks";
import { useMemo } from "react";

export const useController = ()=> {

    const { navigateTo } = useNavigation();

    const itemsMenuNav = useMemo(()=> [
        {
            label: 'Profil',
            goTo: ()=> navigateTo(NavigationPathsEnum.TALENT_ACCOUNT),
        },
        {
            label: 'Tests de compétences',
            goTo: ()=> navigateTo(NavigationPathsEnum.TALENT_ACCOUNT_TESTS),
        },
        {
            label: 'Propositions  de recruteurs',
            // goTo: ()=> navigateTo(NavigationPathsEnum.TALENT_ACCOUNT),
            goTo: ()=> {}
        },
        {
            label: 'Suivi des candidatures',
            // goTo: ()=> navigateTo(NavigationPathsEnum.TALENT_ACCOUNT),
            goTo: ()=> {}
        },
        {
            label: 'Rendez-vous',
            // goTo: ()=> navigateTo(NavigationPathsEnum.TALENT_ACCOUNT),
            goTo: ()=> {}
        },
        {
            label: 'Paramètres',
            goTo: ()=> navigateTo(NavigationPathsEnum.TALENT_ACCOUNT_SETTINGS),
        }
    ], [navigateTo]);

    return {
        itemsMenuNav
    }
};
