import React from "react";
import './styles.css';
import { ItabNavigationWithSubHead } from "./interfaces";

import { TabNavigation } from "../tabNavigation";
import { Icon, Title } from "@components";
import { EnumIconName } from "@utilities/enums";

export const TabNavigationWithSubHead:React.FC<ItabNavigationWithSubHead> = ({
    children,
    pageTitle,
    onGoBackPress,
})=> {
    

    return (
        <TabNavigation>
            <div className="tf_tabNavigationWithSubHead_container">
                <div className="tf_tabNavigationWithSubHead_header">
                    <div className="tf_tabNavigationWithSubHead_header_iconContainer">
                        {/* <Icon 
                            iconName={EnumIconName.arrowLeft}
                            size={20}
                            onIconPress={onGoBackPress}
                        /> */}
                        left
                    </div>
                    <Title 
                        label={pageTitle}
                        variant="h_sm"
                        className="tf_tabNavigationWithSubHead_header_title"
                    />
                </div>
                <>
                    {children}
                </>
            </div>
        </TabNavigation>
    )
}