import { CSSProperties, FC } from "react";
import { DisclaimerTitle, ModalContainer, ModalSection, DisclaimerTxt, TestCardSubTitle, DisclaimerLabel } from "../styles";
import { SimpleBadge, SimpleButton } from "@components";
import { ModalStepsEnum } from "../useControllers";
import { qcmTestType, TEST_QCM } from "@utilities/types";
import { useGetLevelColor } from "@utilities/hooks";
import { FontFamilyEnum } from "@utilities/enums";

interface Props {
    selectedQCM: TEST_QCM | null
    setModalSteps: (e: ModalStepsEnum)=> void
    setCurrentQuestion: (e: qcmTestType | null)=> void
    setCurrentQuestionIndex: (e: number)=> void
    setStartCountdown: (e: boolean)=> void
}

export const DisclaimerComponent:FC<Props> = ({
    selectedQCM,
    setModalSteps,
    setCurrentQuestion,
    setCurrentQuestionIndex,
    setStartCountdown,
})=> {

    const { color } = useGetLevelColor(selectedQCM?.level!);

    const simpleButtonStyle:CSSProperties = {
        fontSize: 14,
        marginTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
    };

    const sujetStyle:CSSProperties = {
        fontSize: 18,
        width: "60%",
        textAlign: 'center',
        fontFamily: FontFamilyEnum.SF_Pro_Display_MEDIUM
    };

    return (
        <ModalContainer style={{height: '90vh'}}>
            <DisclaimerTitle>
                ⚠️ Attention à lire <br /> avant de commencer
            </DisclaimerTitle>

            <DisclaimerTxt>
                ➡️ Vous êtes sur le point de commencer un test de compétence. En cliquant sur <DisclaimerLabel>‘Commencer le test’</DisclaimerLabel>, un compte à rebours de <DisclaimerLabel>{selectedQCM?.time} minutes</DisclaimerLabel> débutera, et vous devrez terminer le QCM dans ce délai sans interruption.
            </DisclaimerTxt>

            <DisclaimerTxt>
                ➡️ Pour valider le test, un minimum de <DisclaimerLabel>15 bonnes réponses sur 20</DisclaimerLabel> est requis, sinon le test et le niveau ne seront pas validés.
            </DisclaimerTxt>

            <SimpleBadge bgColor={color} style={{marginTop: 30}}>
                {selectedQCM?.level}
            </SimpleBadge>

            <TestCardSubTitle style={sujetStyle}>
                📚 {selectedQCM?.entitle}
            </TestCardSubTitle>

            <SimpleButton
                style={simpleButtonStyle}
                onClick={()=> {
                    setModalSteps(ModalStepsEnum.QUIZZ);
                    setCurrentQuestion(selectedQCM?.qcm[0]!);
                    setCurrentQuestionIndex(1);
                    setStartCountdown(true);
                }}
            >
                Commencer le test
            </SimpleButton>
        </ModalContainer>
    )
}