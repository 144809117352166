import { FontFamilyEnum, IconNameEnum } from '@utilities/enums';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Icon } from './icon';

const SearchBarContainer = styled.div<{noRadius?: boolean, disabled?: boolean}>`
    position: relative;
    width: 100%;
    min-height: 40px;
    border-radius: ${props=> props.noRadius ? '0px' : '8px'};
    border: solid 1px #F5F4F2;
    background-color: ${props=> props.disabled ? '#eeeeee' : '#ffffff'};
    display: flex;
    align-items: center;

    @media (min-width: 760px){
        min-height: 48px;
        border-radius: ${props=> props.noRadius ? '0px' : '12px'};
    }
`;

const Input = styled.input`
    flex: 1;
    height: 38px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #101214;
    font-size: 12px;
    padding-left: 14px;
    padding-right: 14px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    ::placeholder {
        color: #C3C3C3;
    }

    @media (min-width: 760px){
        height: 46px;
        font-size: 15px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
    }
`;

const SuggestionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

interface SearchBarProps {
  placeholder: string
  suggestions: string[];
  onChange: (str: string)=> void;
  onSelected: (query: string) => void;
}

export const SearchWithAutoComplete: React.FC<SearchBarProps> = ({ 
  placeholder, suggestions, onSelected, onChange
}) => {
  const [query, setQuery] = useState<string>('');

  const handleClick = useCallback((suggestion: string) => {
    onSelected(suggestion);
    setQuery('');
  }, [onSelected]);

  const handleChange = useCallback((txt: string)=> {
    setQuery(txt);
    onChange(txt);
  }, [onChange]);

  return (
    <SearchBarContainer>
      <Input
        value={query}
        onChange={(e)=> handleChange(e.target.value)}
        placeholder={placeholder}
      />

      {query && (
          <SuggestionsList>
          {
            suggestions.map((suggestion, index) => (
              <SuggestionItem key={index} onClick={() => handleClick(suggestion)}>
                  {suggestion}
              </SuggestionItem>
            ))
          }
          </SuggestionsList>
      )}
    </SearchBarContainer>
  );
};
