import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppointmentInitialStateType,
  Appointment,
} from '@utilities/types';
import { SLICES_NAMES } from '../slicesNames';

const initialState: AppointmentInitialStateType = {
  talentAppointment: [],
  recruiterAppointment: [],
};

const appointmentSlice = createSlice({
  name: SLICES_NAMES.appointment,
  initialState, 
  reducers: {
    saveTalentAppointment: (state, action: PayloadAction<Appointment[]>) => {
      state.talentAppointment = action.payload
    },
    saveRecruiterAppointment: (state, action: PayloadAction<Appointment[]>) => {
      state.recruiterAppointment = action.payload
    },
  },
});

export const { 
  saveTalentAppointment,
  saveRecruiterAppointment
} = appointmentSlice.actions;

export const appointmentReducer = appointmentSlice.reducer;
