import { FontFamilyEnum, IconNameEnum } from "@utilities/enums";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { SimpleCheckbox } from "./simpleCheckbox";
import { Icon } from "./icon";

interface Props {
    width?: number
    noRadius?: boolean
    placeholder: string
    leftIconText?: string
    elements: ItemCategory[]
    onSelectedElement: (e: string[])=> void
}

interface ItemCategory {
    id: string
    label: string
    subcategories: string[]
}

const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
`;

const DropdownButton = styled.button<{noRadius: boolean}>`
    width: 100%;
    height: 43px;
    border-radius: ${props=> props.noRadius ? '0px' : '8px'};
    border: solid 1px #F5F4F2;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-left: 14px;
    padding-right: 14px;

    @media (min-width: 760px){
        height: 48px;
        border-radius: ${props=> props.noRadius ? '0px' : '12px'};
    }
`;

const DropdownContent = styled.div<{ isVisible: boolean, width: string }>`
    display: ${(props) => (props.isVisible ? 'block' : 'none')};
    position: absolute;
    overflow-y: scroll;
    max-height: 300px;
    top: 100%;
    right: 0;  /* Aligne le contenu à droite du bouton */
    left: auto; /* Assure que le contenu s'aligne par rapport à la droite */
    border-left: solid 1px #f4eeee;
    border-right: solid 1px #f4eeee;
    border-bottom: solid 1px #f4eeee;
    background-color: #fff;
    min-width: ${props=> props.width};
    z-index: 99;
`;

const Label = styled.label`
    color: #101214;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
`;

const ItemWrapper = styled.div`
    border-bottom: solid 1px #f4eeee;
`;

const ItemHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
`;

const BadgeContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const ItemBody = styled.div`
    margin-left: 20px;
    padding: 10px;
    border-left: solid 1px #f4eeee;
    margin-bottom: 10px;
`;

const ItemGroupSelectedCounter = styled.div`
    font-size: 12px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background-color: #eaeaea;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LeftIconText = styled.label`
    font-size: 15px;
    margin: 0%;
`;

export const DropdownSelect:FC<Props> = ({ 
    width,
    elements,
    placeholder,
    leftIconText,
    noRadius = false,
    onSelectedElement,
 })=> {

    const selectRef = useRef<any>(null);

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [dropdownContentWidth, setDropdownContentWidth] = useState<string>('');
    const [selectedItems, setSelectedItems] = useState<any>({});
    const [visibleCategories, setVisibleCategories] = useState<{ [key: string]: boolean }>({});

    const totalSelectedCount:any = useMemo(()=> (
        Object.values(selectedItems).reduce((total, subcategory:any) => total + subcategory.length, 0)
    ), [selectedItems]);

    const toggleVisible = useCallback(()=> setIsVisible(!isVisible), [isVisible]);

    const handleCheckboxChange = (categoryId: string, subcategory: string) => {
        setSelectedItems((prevSelectedItems: any) => {
        const categorySelectedItems = prevSelectedItems[categoryId] || [];
        const isSelected = categorySelectedItems.includes(subcategory);

        return {
            ...prevSelectedItems,
            [categoryId]: isSelected
            ? categorySelectedItems.filter((item: any) => item !== subcategory)
            : [...categorySelectedItems, subcategory]
        };
        });
    };

    const getSelectedCount = (categoryId: string) => {
        return selectedItems[categoryId]?.length || 0;
    };

    const areAllSubcategoriesSelected = (categoryId: string) => {
        const category = elements.find(category => category.id === categoryId);
        if (!category) return false;
      
        const selectedSubcategories = selectedItems[categoryId] || [];
        return category.subcategories.every(subcategory => selectedSubcategories.includes(subcategory));
    };

    const toggleSelectAllSubcategories = (categoryId: string) => {
        const category = elements.find(category => category.id === categoryId);
        if (!category) return;
      
        const areAllSelected = areAllSubcategoriesSelected(categoryId);
      
        setSelectedItems((prevSelectedItems: any) => ({
          ...prevSelectedItems,
          [categoryId]: areAllSelected ? [] : category.subcategories
        }));
    };

    const toggleCategoryVisibility = (categoryId: string) => {
        setVisibleCategories(prevVisibleCategories => ({
            ...prevVisibleCategories,
            [categoryId]: !prevVisibleCategories[categoryId]
        }));
    };

    useEffect(() => {
        if (isVisible && selectRef.current) {
            setDropdownContentWidth(`${selectRef.current.offsetWidth}px`);
        }
    }, [isVisible]);

    useEffect(() => {
        if (selectedItems && Object.keys(selectedItems).length > 0) {
            let list: string[] = [];
            Object.values(selectedItems).forEach((el: any) => {
                el.forEach((item: any) => {
                    list.push(item);
                });
            });
            onSelectedElement(list);
        }
    }, [selectedItems, onSelectedElement]);

    return (
        <DropdownContainer>
            <DropdownButton noRadius={noRadius} onClick={toggleVisible} ref={selectRef}>
                <Label>
                    {
                        leftIconText && (
                            <LeftIconText>
                                {leftIconText}
                            </LeftIconText>
                        )
                    }
                    {placeholder} : <ItemGroupSelectedCounter>{totalSelectedCount}</ItemGroupSelectedCounter>
                </Label>

                <Icon 
                    iconName={isVisible ? IconNameEnum.up : IconNameEnum.down}
                />
            </DropdownButton>
            <DropdownContent width={dropdownContentWidth} isVisible={isVisible}>
                {
                    elements.map((category) => {
                        const allIsSelected = areAllSubcategoriesSelected(category.id);

                        return (
                            <ItemWrapper key={category.id} className="category">
                                <ItemHeader>
                                    <SimpleCheckbox 
                                        label={category.label}
                                        checked={allIsSelected}
                                        setChecked={()=> toggleSelectAllSubcategories(category.id)}
                                    />

                                    <BadgeContainer>
                                        <ItemGroupSelectedCounter>
                                            {getSelectedCount(category.id)}
                                        </ItemGroupSelectedCounter>
                                        <Icon 
                                            iconName={ visibleCategories[category.id] ? IconNameEnum.up : IconNameEnum.down}
                                            onPress={()=> toggleCategoryVisibility(category.id)}
                                        />
                                    </BadgeContainer>
                                </ItemHeader>
                                
                                {
                                    visibleCategories[category.id] && (
                                        <ItemBody>
                                            {category.subcategories.map((subcategory) => (
                                                <SimpleCheckbox 
                                                    key={subcategory}
                                                    label={subcategory}
                                                    checked={selectedItems[category.id]?.includes(subcategory) || false}
                                                    setChecked={()=> handleCheckboxChange(category.id, subcategory)}
                                                />
                                            ))}
                                        </ItemBody>
                                    )
                                }
                            </ItemWrapper>
                        )
                    })
                }
            </DropdownContent>
        </DropdownContainer>
    )
}