import { CSSProperties, FC, useCallback, useState } from "react";
import { CompetencesTitle, ProfileSectionBody } from "../styles";
import { 
    BadgeWithIcon, BadgeWrapper, CardPage, 
    CardPageSubTitle, CardPageTitle, 
    FixedModalFooter, 
    InputLabel, InputWrapper, Modal, 
    ModalContainer, 
    ModalSubTitle, 
    ModalTitle, 
    SimpleBadge, SimpleButton, 
    TodoComponent,
} from "@components";
import { useScreenSize } from "@utilities/hooks";
import { useControllers } from "../useControllers";
import { CV } from "@utilities/types";

interface Props {
    simpleButtonStyle: CSSProperties
    simpleButtonOutlineStyle: CSSProperties
    talentCV: CV | null
    onSaveTalentCVRequest: (cv: CV)=> void
}

export const Competences:FC<Props> = ({
    simpleButtonStyle,
    simpleButtonOutlineStyle,
    talentCV,
    onSaveTalentCVRequest,
})=> {

    const { isMobile } = useScreenSize();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [competence, setCompetence] = useState<string>('');
    const [competences, setCompetences] = useState<string[]>(talentCV?.skills || []);

    const handleClose = useCallback(()=> {
        setCompetence('');
        setCompetences(talentCV?.skills || []);
        setIsVisible(false);
    }, [talentCV]);

    const handleAddLanguages = useCallback(()=> {
        setCompetences(e => {
            if (!e.includes(competence)) {
                return [...e, competence];
            }
            return e;
        });
        setCompetence('');
    }, [competence]);

    const handleRemoveCompetence = useCallback((competenceEL: string)=> {
        setCompetences(e => e.filter(element=> element !== competenceEL));
    }, []);

    const handleSaveDate = useCallback(()=> {
        if(competences.length > 0){
            onSaveTalentCVRequest({
                ...talentCV!,
                skills: competences
            });
        }

        handleClose();
    }, [competences, onSaveTalentCVRequest, talentCV, handleClose]);

    return (
        <>
            <CardPage>
                <CardPageTitle>
                    Compétences & expertises 
                </CardPageTitle>
                <CardPageSubTitle>
                    Mettez en avant les compétences et expertises qui vous différencient.
                </CardPageSubTitle>

                <SimpleButton 
                    onClick={()=> setIsVisible(true)}
                    style={simpleButtonStyle}
                >
                    Ajouter
                </SimpleButton>

                {
                    talentCV?.skills && talentCV.skills.length > 0 && (
                        <ProfileSectionBody style={{marginTop: 40}}>
                            <BadgeWrapper style={{margin: 0}}>
                                {
                                    talentCV.skills.map((el, i)=> (
                                        <SimpleBadge key={i} color="#fff" bgColor="#0C1421">
                                            {el}
                                        </SimpleBadge>
                                    ))
                                }
                            </BadgeWrapper>
                        </ProfileSectionBody>
                    )
                }
            </CardPage>

            <Modal
                isVisible={isVisible}
                onClose={handleClose}
            >
                <>
                    <ModalContainer>
                        <ModalTitle>
                            Compétences & expertises
                        </ModalTitle>
                        <ModalSubTitle>
                            Mettez en avant les compétences et expertises qui vous différencient.
                        </ModalSubTitle>

                        <InputWrapper>
                            <InputLabel>
                                💪 Ajouter une compétence
                            </InputLabel>
                            <TodoComponent
                                placeholder="ex : Figma"
                                value={competence}
                                onChange={e=> setCompetence(e)}
                                onAdd={handleAddLanguages}
                            />

                            <CompetencesTitle>
                                COMPÉTENCES
                            </CompetencesTitle>
                            <BadgeWrapper>
                                {
                                    competences.map((el, i)=> {
                                        return (
                                            <BadgeWithIcon
                                                key={i}
                                                onIconPress={()=> handleRemoveCompetence(el)}
                                            >
                                                {el}
                                            </BadgeWithIcon>
                                        )
                                    })
                                }
                            </BadgeWrapper>
                        </InputWrapper>
                    </ModalContainer>

                    <FixedModalFooter>
                        <SimpleButton onClick={handleSaveDate}>
                            Enregistrer
                        </SimpleButton>

                        <SimpleButton onClick={handleClose} style={simpleButtonOutlineStyle}>
                            Annuler
                        </SimpleButton>
                    </FixedModalFooter>
                </>
            </Modal>
        </>
    )
}