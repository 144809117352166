import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

interface Props {
    color?: string
    bgColor?: string
    fontSize?: string
    height?: string
}

export const SimpleBadge = styled.label<Props>`
    color: ${props=> props.color || '#101214'};
    background-color: ${props=> props.bgColor || '#EFECE6'};
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
    font-size: ${props=> props.fontSize || '12px'};
    height: ${props=> props.height || '30px'};
    padding: 0px 15px 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;

    @media (min-width: 760px) {
        font-size: ${props=> props.fontSize || '13px'};
        height: ${props=> props.height || '30px'};
        padding: 0px 17px 0px 17px;
        border-radius: 60px;
    }
`;