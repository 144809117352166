// FR: Définit une énumération pour les chemins de navigation dans l'application.
// EN: Defines an enumeration for navigation paths in the application.

export enum NavigationPathsEnum {
    NOT_FOUND = '*',
    HOME = '/',
    FIND_TALENT = '/rechercher-des-talents',
    FIND_JOB_OFFER = '/rechercher-des-jobs',
    Find_ORGANISATION = '/rechercher-une-entreprise',
    ORGANISATION_PAGE = '/entreprise',
    CREATE_NEW_PASSWORD = '/creer-nouveau-de-passe',

    TALENT_ACCOUNT = '/talent/mon-compte',
    TALENT_ACCOUNT_CV_TOOL = '/talent/cv-tool',
    TALENT_ACCOUNT_SETTINGS = '/talent/settings',
    TALENT_ACCOUNT_OFFERS_SAVE = '/talent/mes-jobs-sauvergarder',
    TALENT_ACCOUNT_RDV = '/talent/mes-rdv',
    TALENT_ACCOUNT_TESTS = '/talent/mes-tests',
    TALENT_ACCOUNT_UNSOLICITED_APPLICATIONS = '/talent/mes-candidatures',

    RECRUITER_ACCOUNT = '/recruteur/mon-compte',
    RECRUITER_ACCOUNT_JOB_OFFERS = '/recruteur/jobs',
    RECRUITER_ACCOUNT_RDV = '/recruteur/rendez-vous',
    RECRUITER_ACCOUNT_TEST = '/recruteur/tests',
    RECRUITER_ACCOUNT_MATCHINGS = '/recruteur/matchings',
    RECRUITER_ACCOUNT_UNSOLICITED_APPLICATIONS = '/recruteur/candidatures',
    RECRUITER_ACCOUNT_COLLAB_MANAGEMENT = '/recruteur/gestion-collaborateurs',
}
