import React, { useCallback, useMemo, useState } from "react";
import './styles.css';
import { IRecruiterAccount_collaboratorManagement } from "./interfaces";
import { useNavigation, useTranslation } from "@utilities/hooks";
import { TabNavigationWithSubHead } from "@connectedComponents";
import { EnumIconName, NavigationPathsEnum } from "@utilities/enums";
import { Button, Icon, Input, Modal, Select, Title } from "@components";
import { enumToStringArray } from "@utilities/functions";
import { EnumOrganisationUserRole } from "@utilities/types";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const RecruiterAccount_collaboratorManagementComponent:React.FC<IRecruiterAccount_collaboratorManagement> = ({
    collabsList,
    recruiter,
    onCreateCollab
})=> {

    const translate = useTranslation();
    const {navigateTo} = useNavigation();

    const [lastname, setLastname] = useState<string>('');
    const [firstname, setFirstname] = useState<string>('');
    const [mail, setMail] = useState<string>('');
    const [collabRole, setCollabRole] = useState<string>('');
    const [displayAddCollabFormModal, setDisplayAddCollabFormModal] = useState<boolean>(false);

    const handleGoBack = useCallback(()=> {
        navigateTo(NavigationPathsEnum.RECRUITER_ACCOUNT);
    }, [navigateTo]);

    const collabRoleList = useMemo(()=> (
        enumToStringArray(EnumOrganisationUserRole)
    ), []);

    const toggleDisplayAddRecruiterForm = useCallback(()=> {
        if(displayAddCollabFormModal){
            setFirstname('');
            setLastname('');
            setCollabRole('');
            setMail('');
            setDisplayAddCollabFormModal(false);
        } else {
            setDisplayAddCollabFormModal(true);
        }
    }, [displayAddCollabFormModal]);

    const handleAddCollab = useCallback(()=> {
        if(!(firstname && lastname && mail && collabRole)){
            return alert('Tous les champs doivent-etre remplis !');
        }

        onCreateCollab({
            _isOrganisation: true,
            _organisationID: recruiter._organisationID,
            role: collabRole as EnumOrganisationUserRole,
            lastname,
            firstname,
            mail,
            password: mail,
        });
        toggleDisplayAddRecruiterForm();
    }, [
        recruiter,
        collabRole, firstname, 
        lastname, mail, 
        onCreateCollab,
        toggleDisplayAddRecruiterForm,
    ]);

    const renderCollabsList = useCallback(()=> {
        return (
            <div className="tf_CollabManagement_collabList_container">
                <DataTable value={collabsList}>
                    <Column field="lastname" header="Nom" />
                    <Column field="firstname" header="Prenom" />
                    <Column 
                        body={()=> (
                            <Button 
                                label="voir"
                                onButtonPress={()=> {}}
                            />
                        )} 
                    />
                    <Column 
                        body={()=> (
                            // <Icon 
                            //     colors="#e4003a"
                            //     iconName={EnumIconName.trash}
                            //     onIconPress={()=> {}}
                            // />
                            <p>trash</p>
                        )} 
                    />
                </DataTable>
            </div>
        )
    }, [collabsList]);

    return (
        <TabNavigationWithSubHead
            pageTitle='Gestion des collaborateurs'
            onGoBackPress={handleGoBack}
        >
            <div className="tf_CollabManagement_container">
                <Button 
                    label="+ ajouter un collab"
                    onButtonPress={toggleDisplayAddRecruiterForm}
                />

                {renderCollabsList()}

                <Modal
                    isVisible={displayAddCollabFormModal}
                    onClose={toggleDisplayAddRecruiterForm}
                >
                    <div className="tf_CollabManagement_modalcontainer">
                        <Title 
                            variant='h_sm'
                            label="Ajouter un collaborateur"
                            className="tf_CollabManagement_modal_title"
                        />

                        <Input 
                            placeholder="nom"
                            value={lastname}
                            onChange={setLastname}
                        />
                        <Input 
                            placeholder="prenom"
                            value={firstname}
                            onChange={setFirstname}
                        />
                        <Input 
                            placeholder="mail"
                            value={mail}
                            onChange={setMail}
                        />
                        <Select 
                            placeholder="role"
                            value={collabRole}
                            onChange={setCollabRole}
                            options={collabRoleList}
                        />

                        <Button 
                            label="ajouter"
                            customClassname="tf_CollabManagement_modal_button"
                            onButtonPress={handleAddCollab}
                        />
                    </div>
                </Modal>
            </div>
        </TabNavigationWithSubHead>
    )
}
