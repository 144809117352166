import { CV } from "./cv.type"
import { Organisation } from "./organisation.type"
import { TEST_QCM } from "./qcm.type"
import { UserTalent } from "./talent.type"

export interface UserRecruiter {
    _id?: string
    _isOrganisation: boolean
    _organisationID?: string
    token?: string
    role?: EnumOrganisationUserRole
    salt?: string
    lastname: string
    firstname: string
    mail: string
    password: string
    phone?: string
    cityResidence?: string
    countryResidence?: string
    confirmedMail?: boolean
    confirmedPhone?: boolean
    isAutorized?: boolean
    createAt?: Date
}

export interface SignInRecruiterActionPayloadRequest {
    mail: string
    password: string
}

export interface RecruiterInitialStateType {
    recruiter: UserRecruiter | null
    organisation: Organisation | null
    // userRecruiterToken: string
    talentCVList: {cv: CV, talent: UserTalent}[]
    organisationTestList: TEST_QCM[]
};

export enum EnumOrganisationUserRole {
    admin = 'Administrateur',
    collab = 'Collaborateur',
}
