import { NotFoundComponent } from "./NotFound.component";

export const NotFound:React.FC = ()=> {



    return (
        <NotFoundComponent />
    )
}
