import { useCallback, useEffect, useMemo, useState } from "react";
import { EnumUserLevel, TEST_QCM, UserQCMSolution, qcmTestType } from "@utilities/types";
import { useTalentDispatch, useTalentSelectors } from "@utilities/hooks";
import { enumToStringArray } from "@utilities/functions";

export enum ModalStepsEnum {
    DISCLAIMER = "diclaimer",
    QUIZZ = "quizz",
    QUESTION_LIST = "questionList",
}

enum TestsFilterEnum {
    allTest = 'Tout afficher',
    myTests = 'Tests basés sur mon CV',
    organisationTest = 'Tests d’entreprise',
    onlyTestResult = 'Résultats',
    debutant = 'Débutant',
    intermediare = 'Intermédiaire',
    confirme = 'Confirmé',
}

export const useControllers = ()=> {

    const { talent, talentCV, talentQCMs, talentQCMsResult } = useTalentSelectors();
    const { 
        onGetTalentCVRequest, 
        onGenerateTestQcm,
        onGetTalentTestQcm,
        onUserTestFixRequest,
        onGetTalentTestResultsRequest,
        onSaveTalentCVRequest,
    } = useTalentDispatch();

    const [modalSteps, setModalSteps] = useState<ModalStepsEnum>(ModalStepsEnum.DISCLAIMER);
    const [selectedQCM, setSelectedQCM] = useState<TEST_QCM | null>(null);
    const [currentQuestion, setCurrentQuestion] = useState<qcmTestType | null>(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [startCountdown, setStartCountdown] = useState<boolean>(false);
    const [countdownValue, setCountdownValue] = useState<number>(-1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedTestFilter, setSelectedTestFilter] = useState<string>(TestsFilterEnum.allTest);

    const levelOrder = {
        [EnumUserLevel.debutant]: 1,
        [EnumUserLevel.intermediaire]: 2,
        [EnumUserLevel.confirme]: 3
    };

    const generateQcmIsOk = useMemo(()=> (
        !!(talentCV?.title && talentCV.shortDescription
            && talentCV.professionalExperiences.length > 0
            && (talentCV.criteria && talentCV.criteria.jobTypes && talentCV.criteria.jobTypes.length > 0))
    ), [talentCV]);

    const isRespondedAllQuestion = useMemo(()=> {
        let status = true;
        selectedQCM?.qcm.map(el=> {
            if(!el.selected_response){
                status = false;
            }
        });

        return status;
    }, [selectedQCM]);

    const filterOptions = useMemo(()=> {
        let result:{label: string, value: string}[] = [];
        const tmp = enumToStringArray(TestsFilterEnum);

        tmp.forEach(el=> result.push({
            label: el,
            value: el,
        }));

        return result;
    }, []);

    const availableQCMs = useMemo(()=> {
        return talentQCMs.filter(talentQCM => {
            // Vérifier s'il y a un test déjà fait avec un niveau supérieur au test non fait
            const higherLevelTest = talentQCMsResult.some(talentQCMResult => 
              levelOrder[talentQCMResult.level] > levelOrder[talentQCM.level] && talentQCMResult.score >= 15
            );
        
            // Si un test supérieur a été fait avec un score >= 15, on n'affiche pas le test non fait
            if (higherLevelTest) {
              return false;
            }
        
            // Sinon, on affiche le test
            return true;
        });
    }, [levelOrder, talentQCMs, talentQCMsResult]);

    const filteredQCMs = useMemo(()=> (
        availableQCMs.filter((el: TEST_QCM) => {
            switch (selectedTestFilter) {
                case TestsFilterEnum.allTest:
                    return true; 
                case TestsFilterEnum.myTests:
                    return el._isOrganisationTest === false; 
                case TestsFilterEnum.onlyTestResult:
                    return false;
                case TestsFilterEnum.organisationTest:
                    return el._isOrganisationTest === true; 
                case TestsFilterEnum.debutant:
                    return el.level === EnumUserLevel.debutant; 
                case TestsFilterEnum.intermediare:
                    return el.level === EnumUserLevel.intermediaire; 
                case TestsFilterEnum.confirme:
                    return el.level === EnumUserLevel.confirme; 
                default:
                    return true; 
            }
        })
    ), [selectedTestFilter, availableQCMs]);

    const filteredQCMResults = useMemo(()=> (
        talentQCMsResult.filter((el: UserQCMSolution) => {
            switch (selectedTestFilter) {
                case TestsFilterEnum.allTest:
                    return true; 
                case TestsFilterEnum.onlyTestResult:
                    return true; 
                case TestsFilterEnum.debutant:
                    return el.level === EnumUserLevel.debutant; 
                case TestsFilterEnum.intermediare:
                    return el.level === EnumUserLevel.intermediaire; 
                case TestsFilterEnum.confirme:
                    return el.level === EnumUserLevel.confirme; 
                default:
                    return false; 
            }
        })
    ), [selectedTestFilter, talentQCMsResult]);

    // Fonction pour obtenir le niveau le plus élevé avec un score >= 15
    const getHighestLevelWithHighScore = useCallback(()=> {
        // Filtrer les tests avec un score >= 15
        const highScoreTests = talentQCMsResult.filter(test => test.score >= 15);

        if (highScoreTests.length === 0) {
            return null; // Aucun test ne répond à ce critère
        }

        // Trouver le test avec le niveau le plus élevé
        const highestLevelTest = highScoreTests.reduce((prev, current) => {
            return levelOrder[current.level] > levelOrder[prev.level] ? current : prev;
        });

        return highestLevelTest.level;
    }, [levelOrder, talentQCMsResult])

    const handleStartTalentEvaluation = useCallback(()=> {
        if(talentCV && !isLoading && generateQcmIsOk){
            setIsLoading(true);
            onSaveTalentCVRequest({
                ...talentCV,
                qcmIsLoading: true,
            });
            onGenerateTestQcm(talentCV._id!);
        }
    }, [onGenerateTestQcm, onSaveTalentCVRequest, talentCV, isLoading, generateQcmIsOk]);

    const handleSelectedResponse = useCallback((response: string) => {
        if (!currentQuestion) return;
    
        setSelectedQCM((prevQCM) => {
            if (!prevQCM) return prevQCM;
    
            return {
                ...prevQCM,
                qcm: prevQCM.qcm.map((el) => {
                    if (el.question === currentQuestion?.question) {
                        return {
                            ...el,
                            selected_response: response,
                        };
                    }
                    return el;
                }),
            };
        });
    
        setCurrentQuestion({
            ...currentQuestion,
            selected_response: response,
        });
    }, [currentQuestion]);

    const handleNextQuestion = useCallback(()=> {
        if(currentQuestionIndex === selectedQCM?.qcm.length) return;

        const questionIndex = currentQuestionIndex! + 1;
        setCurrentQuestionIndex(questionIndex);
        setCurrentQuestion(selectedQCM?.qcm[questionIndex - 1]!);
    }, [currentQuestionIndex, selectedQCM]);

    const handleCloseContentModal = useCallback(()=> {
        if(!startCountdown){
            setSelectedQCM(null);
        } else {
            alert('Impossible de quittez le test !');
        }
    }, [startCountdown]);

    const handleValidateQcm = useCallback(()=> {
        if(selectedQCM){
            onUserTestFixRequest(selectedQCM);
            setCountdownValue(-1);
            setCurrentQuestionIndex(0);
            setStartCountdown(false);
            setSelectedQCM(null);
            setCurrentQuestion(null);
            setModalSteps(ModalStepsEnum.DISCLAIMER);
        }
    }, [selectedQCM, onUserTestFixRequest]);

    useEffect(()=> {
        if(talentCV){
            setIsLoading(
                !!talentCV.qcmIsLoading
            );
        }
    }, [talentCV])

    useEffect(()=> {
        if(talent){
            onGetTalentCVRequest();
            onGetTalentTestQcm();
            onGetTalentTestResultsRequest();
        }
    }, [talent]);

    useEffect(()=> {
        if(countdownValue === 0 && startCountdown){
            handleValidateQcm();
        }
    }, [countdownValue, startCountdown]);

    // useEffect(()=> {
    //     return ()=> {
    //         if(startCountdown){
    //             handleValidateQcm();
    //         }
    //     }
    // }, [startCountdown]);

    useEffect(() => {
        if(startCountdown){
            window.addEventListener('beforeunload', handleValidateQcm);
        }
    
        return () => {
          if(startCountdown){
            window.removeEventListener('beforeunload', handleValidateQcm);
          }
        };
    }, [startCountdown]);

    useEffect(()=> {
        if(talentQCMs.length > 0){
            setIsLoading(false);
        }
    }, [talentQCMs]);

    useEffect(()=> {
        if(talentQCMsResult.length > 0){
            getHighestLevelWithHighScore();
        }
    }, [talentQCMsResult]);

    return {
        generateQcmIsOk,
        isLoading,
        talent,
        talentQCMs,
        talentQCMsResult,
        modalSteps,
        countdownValue,
        isRespondedAllQuestion,
        startCountdown,
        selectedQCM,
        currentQuestion,
        currentQuestionIndex,
        filteredQCMs,
        filteredQCMResults,
        filterOptions,
        selectedTestFilter, 
        setSelectedTestFilter,
        handleValidateQcm,
        handleNextQuestion,
        handleCloseContentModal,
        handleStartTalentEvaluation,
        handleSelectedResponse,
        setSelectedQCM,
        setCountdownValue,
        setModalSteps,
        setCurrentQuestion,
        setCurrentQuestionIndex,
        setStartCountdown,
        getHighestLevelWithHighScore,
    }
}