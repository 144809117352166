import { 
    saveRecruiter,
    saveOrganisation,
    // saveCreateUserRecruiterToken,
    saveTalentCvList,
    saveSearchTalentCvList,
    saveOrganisationTestList,
} from "@data/reducers";
import { 
    fetchCreateOrganisationRequest, 
    fetchGenerateTestByIARequest, 
    fetchGetAllTalentCVsRequest, 
    fetchGetOrganisationTestRequest, 
    fetchOnSearchTalentCvsRequest, 
    fetchSaveOrganisationTestRequest, 
    fetchSigninRecruiterRequest,
    fetchSignupRecruiterRequest,
} from "@data/services";
import { AppDispatch } from "@data/store";
import { 
    CV,
    FetchResponseType, 
    Organisation, 
    qcmTestType, 
    SearchTalentType, 
    SignInRecruiterActionPayloadRequest,
    TEST_QCM,
    UserRecruiter,
    UserTalent,
} from "@utilities/types";
import { useCallback } from "react";
import { useDispatch } from "react-redux"

export const useRecruiterDispatch = () => {

    const dispatch = useDispatch<AppDispatch>();

    const onSigninRecruiter = useCallback(async(signinData: SignInRecruiterActionPayloadRequest) => {
        const req:FetchResponseType = await fetchSigninRecruiterRequest(signinData);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const recruiter:UserRecruiter = req.data;
        dispatch(saveRecruiter(recruiter));
    }, [dispatch]);

    const onCreateOrganisation = useCallback(async(organisationData: Organisation) => {
        const req:FetchResponseType = await fetchCreateOrganisationRequest(organisationData);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const organisation:Organisation = req.data;
        dispatch(saveOrganisation(organisation));
    }, [dispatch]);

    const onSignupRecruiter = useCallback(async(recruiter: UserRecruiter) => {
        const req:FetchResponseType = await fetchSignupRecruiterRequest(recruiter);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const recruiterData:UserRecruiter = req.data;
        dispatch(saveRecruiter(recruiterData));
    }, [dispatch]);

    const onGetAllTalentCvsRequest = useCallback(async() => {
        const req:FetchResponseType = await fetchGetAllTalentCVsRequest();
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const userTalentCV:{cv: CV, talent: UserTalent}[] = req.data;
        dispatch(saveTalentCvList(userTalentCV));
    }, [dispatch]);

    const onSearchTalentCvsRequest = useCallback(async(search: SearchTalentType) => {
        const req:FetchResponseType = await fetchOnSearchTalentCvsRequest(search);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const userTalentSearchCVList:{cv: CV, talent: UserTalent}[] = req.data;

        if(userTalentSearchCVList.length === 0){
            alert("Aucun resultas !");
        }

        dispatch(saveSearchTalentCvList(userTalentSearchCVList));
    }, [dispatch]);

    const getOrganisationTestRequest = useCallback(async(organisationID: string)=> {
        const req:FetchResponseType = await fetchGetOrganisationTestRequest(organisationID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const organisationTestList:TEST_QCM[] = req.data;
        dispatch(saveOrganisationTestList(organisationTestList));
    }, [dispatch]);

    const onSaveOrganisationTestRequest = useCallback(async(organisationQcm: TEST_QCM)=> {
        const req:FetchResponseType = await fetchSaveOrganisationTestRequest(organisationQcm);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        return 'success !';
    }, []);

    const onGenerateTestByIA = useCallback(async(jobOfferID: string, testLevel:string)=> {
        const req:FetchResponseType = await fetchGenerateTestByIARequest({jobOfferID, testLevel});
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const qcmTest:qcmTestType[] = req.data || [];
        return qcmTest;
    }, []);

    const onLogoutRecruiter = useCallback(() => {
        dispatch(saveRecruiter(null));
        dispatch(saveOrganisation(null));
        // dispatch(saveCreateUserRecruiterToken(''));
        dispatch(saveTalentCvList([]));
        dispatch(saveSearchTalentCvList([]));
        dispatch(saveOrganisationTestList([]));
    }, [dispatch]);

    return {
        onSigninRecruiter,
        onCreateOrganisation,
        onSignupRecruiter,
        onGetAllTalentCvsRequest,
        onSearchTalentCvsRequest,
        getOrganisationTestRequest,
        onSaveOrganisationTestRequest,
        onGenerateTestByIA,
        onLogoutRecruiter,
    };
};
