import { FC, ReactNode } from "react";
import { PageWrapperChildren } from "./pageWrapperChildren";
import { PageWrapperComponent } from './pageWrapperComponent';

interface Props {
    children: ReactNode
    customBG?: string
}

export const PageWrapper:FC<Props> = ({ children, customBG })=> (
    <PageWrapperComponent customBG={customBG}>
        <PageWrapperChildren>
            {children}
        </PageWrapperChildren>
    </PageWrapperComponent>
)