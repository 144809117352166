import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Icon } from "./icon";
import { CVstateEnum, FontFamilyEnum, IconNameEnum } from "@utilities/enums";

interface Props {
    cvState: CVstateEnum
    onChangeState: (state: CVstateEnum)=> void
}

interface StatusListType {
    badgeColor: string
    label: string
    description: string
}

const SelectContainer = styled.div`
    width: 100%;
    height: 43px;
    border: solid 1px #F5F4F2;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-left: 14px;
    padding-right: 14px;

    @media (min-width: 760px){
        height: 48px;
    }
`;

const Select = styled.div`
    position: absolute;
    z-index: 99;
    overflow-y: scroll;
    border: solid 2px #F5F4F2;
    background-color: #ffffff;
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 13px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
`;

const BadgeStatus = styled.div<{bgColor: string}>`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${props=> props.bgColor};
`;

const StausElementWrapper = styled.div`
    cursor: pointer;
    padding: 20px;

    &:hover {
        background-color: #F5F4F2;
    }
`;

const Description = styled.p`
    margin: 0%;
    margin-top: 10px;
    font-size: 12px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
`;

export const SelectStatus:FC<Props> = ({
    cvState,
    onChangeState
})=> {

    const selectRef = useRef<any>(null);
    const select = useRef<any>(null);

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [selectedStatus, setSelectedStatus] = useState<StatusListType>();

    const statusList:StatusListType[] = useMemo(()=> [
        {
            badgeColor: '#EE4B66',
            label: CVstateEnum.NotAvailable,
            description: 'Je ne souhaite pas être contacté par des recruteurs.',
        },
        {
            badgeColor: '#3A52D0',
            label: CVstateEnum.OpenToOpportunities,
            description: "Je ne cherche pas un job, mais j'accepte de recevoir des opportunités pertinentes de la part des recruteurs.",
        },
        {
            badgeColor: '#277567',
            label: CVstateEnum.ActivelyLooking,
            description: 'Je cherche activement un job et je souhaite recevoir des opportunités pertinentes de la part des recruteurs.',
        }
    ], []);

    const onToggleIsVisible = useCallback(()=> {
        setIsVisible(!isVisible);
    }, [isVisible]);

    const handleSelectedStatus = useCallback((element: StatusListType)=> {
        setSelectedStatus(element);
        onChangeState(element.label as CVstateEnum);
        onToggleIsVisible();
    }, [onToggleIsVisible, onChangeState]);

    useEffect(() => {
        if (isVisible && selectRef.current && select.current) {
            const rect = selectRef.current.getBoundingClientRect();
            const right = window.innerWidth - rect.right;

            select.current.style.width = `${selectRef.current.offsetWidth}px`;
            select.current.style.top = `${selectRef.current.offsetTop + 45}px`;
            select.current.style.right = `${right}px`;
        }
    }, [isVisible]);

    useEffect(()=> {
        if(cvState){
            const element = statusList.find(el=> el.label === cvState);
            if(element){
                setSelectedStatus(element);
            }
        }
    }, [cvState, statusList]);

    return (
        <>
            <SelectContainer 
                ref={selectRef}
                onClick={onToggleIsVisible}
            >
                <Label>
                    <BadgeStatus bgColor={selectedStatus?.badgeColor || '#fff'} /> {selectedStatus?.label}
                </Label>
                <Icon 
                    iconName={isVisible ? IconNameEnum.up : IconNameEnum.down}
                />
            </SelectContainer>

            {
                isVisible && (
                    <Select ref={select}>
                        {
                            statusList.map((el, i)=> {
                                return (
                                    <StausElementWrapper
                                        key={i}
                                        onClick={()=> handleSelectedStatus(el)}
                                    >
                                        <Label>
                                            <BadgeStatus bgColor={el.badgeColor} /> {el.label}
                                        </Label>

                                        <Description>{el.description}</Description>
                                    </StausElementWrapper>
                                )
                            })
                        }
                    </Select>
                )
            }
        </>
    )
}