import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

// Fonction pour chiffrer une chaîne de caractères
export const encryptString = (plainText: string, secretKey: string)=> {
    const encrypted = AES.encrypt(plainText, secretKey).toString();
    return encrypted;
}

// Fonction pour déchiffrer une chaîne de caractères
export const decryptString = (cipherText: string, secretKey: string)=> {
    const decrypted = AES.decrypt(cipherText, secretKey);
    const originalText = decrypted.toString(Utf8);
    return originalText;
}