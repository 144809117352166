import { FontFamilyEnum } from "@utilities/enums";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import AltAvatar from '@assets/images/defaultImg.png';

interface Props {
    avatar?: string
    onGotoAccount: ()=> void
    onLogout: ()=> void
}

const Avatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: solid 1px #b4b4b4;
    object-fit: cover;
    cursor: pointer;

    &:hover {
        transition: 0.5s;
        border: solid 2px #EFECE6;
        transform: scale(1.1);
    }
`;

const Menu = styled.div`
    position: absolute;
    z-index: 999;
    padding: 10px;
    width: 160px;
    border-radius: 8px;
    border: solid 1px #EFECE6;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Link = styled.p`
    margin: 0%;
    padding: 5px;
    padding-left: 10px;
    border-radius: 5px;
    width: 100%;
    font-size: 12px;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
    cursor: pointer;

    &:hover {
        background-color: #EFECE6;
    }
`;

export const AvatarAcount:FC<Props> = ({
    avatar,
    onGotoAccount,
    onLogout,
})=> {

    const avatarRef = useRef<any>(null);
    const menuRef = useRef<any>(null);

    const [isVisible, setIsVisible] = useState<boolean>(false);

    const handleAccountPress = useCallback(()=> {
        onGotoAccount();
        setIsVisible(false);
    }, [onGotoAccount]);

    const handleLogoutPress = useCallback(()=> {
        onLogout();
        setIsVisible(false);
    }, [onLogout]);

    useEffect(() => {
        if (isVisible && avatarRef.current && menuRef.current) {
            const rect = avatarRef.current.getBoundingClientRect();
            const right = window.innerWidth - rect.right;

            menuRef.current.style.top = `${avatarRef.current.offsetTop + 50}px`;
            menuRef.current.style.right = `${right}px`;
        }
    }, [isVisible]);

    return (
        <>
            <Avatar
                src={avatar || AltAvatar}
                alt="avatr"
                ref={avatarRef}
                onClick={()=> setIsVisible(!isVisible)}
            />

            {
                isVisible && (
                    <Menu ref={menuRef}>
                        <Link onClick={handleAccountPress}>Mon espace</Link>
                        <Link onClick={handleLogoutPress}>Se déconnecter</Link>
                    </Menu>
                )
            }
        </>
    )
}