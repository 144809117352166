import React, { useEffect, useRef, useState } from "react";
import './styles.css';
import { ICountdown } from "./interface";
import styled from "styled-components";
import { FontFamilyEnum } from "@utilities/enums";

export const Text = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
    font-size: 22px;
    color: #101214;
    text-align: center;
`;

function useInterval(callback: ()=>void, delay: number | null) {
    const savedCallback = useRef<any>();

    // Sauvegarde de la dernière callback
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Mise en place de l'intervalle
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export const Countdown:React.FC<ICountdown> = ({
    minutes,
    isStart,
    handleCountdownChange,
})=> {

    const [secondsLeft, setSecondsLeft] = useState<number>(minutes * 60);
    const [isRunning, setIsRunning] = useState<boolean>(false);

    useInterval(() => {
        if (secondsLeft > 0) {
            setSecondsLeft(secondsLeft - 1);
            handleCountdownChange(secondsLeft - 1);
        } else {
            setIsRunning(false);
        }
    }, isRunning ? 1000 : null);

    useEffect(()=> {
        setIsRunning(isStart);
    }, [isStart]);

    useEffect(() => {
        // Réinitialiser le compte à rebours si les minutes changent
        setSecondsLeft(minutes * 60);
    }, [minutes]);

    // Formatage du temps en minutes et secondes
    const minutesLeft = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft % 60;

    return (
        <Text>
            {`${minutesLeft} : ${seconds < 10 ? `0${seconds}` : seconds}`}
        </Text>
    )
}