import { RootState } from "@data/store";
import { useSelector } from "react-redux";

export const useTalentSelectors = () => {

    const { 
        talent,
        // talentToken,
        talentCV,
        talentSavedPost,
        talentQCMs,
        talentQCMsResult,
    } = useSelector((state: RootState) => state.talent);

    return {
        talent,
        // talentToken,
        talentCV,
        talentSavedPost,
        talentQCMs,
        talentQCMsResult,
    };
};

