import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 100%;

    @media (min-width: 760px){
        margin-top: 65px;
        width: 80%;
        margin-left: 10%;
    }
`;

export const InputWrapper = styled.div`
    flex: 1;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 760px){
        margin-bottom: 30px;
    }
`;

export const InputLabel = styled.label`
    margin: 0%;
    color: #0C1421;
    font-size: 10px;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
    margin-bottom: 7px;

    @media (min-width: 760px){
        margin-bottom: 8px;
        font-size: 13px;
        font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
    }
`;

export const Title = styled.p`
    text-align: center;
    margin: 0%;
    font-size: 18px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
`;
export const SubTitle = styled.p`
    text-align: center;
    margin: 0%;
    margin-bottom: 20px;
    font-size: 13px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
`;