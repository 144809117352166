import { EnumPaths } from "@utilities/enums";
import { fetchRequest } from "@utilities/requests";
import { 
    CV,
    FetchRequestType, 
    FetchResponseType, 
    SavedPost, 
    SignInActionPayloadRequest,
    TEST_QCM,
    UserTalent, 
} from "@utilities/types";

export const fetchGetTalentDataRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: `${EnumPaths.GET_USER_TALENT}?token=${payload}`,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchSigninTalentRequest = async(
    payload: SignInActionPayloadRequest
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.LOGIN_USER_TALENT,
        method: 'POST',
        data: payload,
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchSignupTalentRequest = async(
    payload: UserTalent
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.CREATE_USER_TALENT,
        method: 'POST',
        data: payload,
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchUpdatedTalentDataRequest = async(
    payload: UserTalent
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.UPDATED_USER_TALENT,
        method: 'PUT',
        data: payload,
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchUpdatedTalentAvatarDataRequest = async(
    payload: {token: string, avatarFile: File}
):Promise<FetchResponseType>=> {

    const formData = new FormData();
    formData.append('token', payload.token);
    formData.append('avatarFile', payload.avatarFile);

    const requestConfig: FetchRequestType = {
        route: EnumPaths.UPDATED_USER_AVATAR_TALENT,
        method: 'PUT',
        data: formData,
        isFormData: true,
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetTalentCVRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: `${EnumPaths.GET_TALENT_CV}?token=${payload}`,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchSaveTalentCVRequest = async(
    payload: {token: string, cv: CV}
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.SAVE_TALENT_CV,
        method: 'PUT',
        data: {userToken: payload.token, userCV: payload.cv},
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetTalentSavedPostRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: `${EnumPaths.GET_ALL_TALENT_SAVED_POSTS}?_userID=${payload}`,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchSavedTalentPostRequest = async(
    payload: SavedPost
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.TALENT_SAVE_POST,
        method: 'POST',
        data: payload
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchDeletedTalentPostRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.TALENT_DELETE_SAVED_POST,
        method: 'DELETE',
        data: {savedPostID: payload}
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetTalentTestRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: `${EnumPaths.GET_TALENT_QCM}?userTalenttoken=${payload}`,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetTalentTestsResultRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: `${EnumPaths.GET_TALENT_QCM_RESULT}?userTalentToken=${payload}`,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGenerateTestRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.CREATE_TALENT_QCM,
        method: 'POST',
        data: {cvID: payload},
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchUserTestFixRequest = async(
    payload: {userQcmSolution: TEST_QCM, token:string}
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.QCM_TALENT_EVALUATION,
        method: 'POST',
        data: {userQcmSolution: payload.userQcmSolution, talentToken: payload.token}
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};
