import { FontFamilyEnum, IconNameEnum } from "@utilities/enums";
import { CSSProperties, FC } from "react";
import styled from "styled-components";
import { Icon } from "./icon";
import { useScreenSize } from "@utilities/hooks";
import { SimpleButton } from "./simpleButton";

interface Props {
    placeholder: string
    value: string
    noRadius?: boolean
    onChange: (e: string)=> void
    onAdd: ()=> void
}

const InputWrapper = styled.div<{noRadius: boolean}>`
    width: 100%;
    min-height: 40px;
    border-radius: ${props=> props.noRadius ? '0px' : '8px'};
    border: solid 1px #F5F4F2;
    background-color: #ffffff;
    display: flex;
    align-items: center;

    @media (min-width: 760px){
        min-height: 48px;
        border-radius: ${props=> props.noRadius ? '0px' : '12px'};
    }
`;
const InputElement = styled.input`
    flex: 1;
    height: 38px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #101214;
    font-size: 12px;
    padding-left: 14px;
    padding-right: 14px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    &::placeholder {
        color: #b4b4b4;
    }

    @media (min-width: 760px){
        height: 46px;
        font-size: 15px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
    }
`;

export const TodoComponent:FC<Props> = ({ 
    placeholder,
    value,
    noRadius = false,
    onChange,
    onAdd,
 })=> {

    const { isMobile } = useScreenSize();

    const addButtonStyles:CSSProperties = {
        height: isMobile ? 30 : 35,
        marginRight: isMobile ? 5 : 7,
        borderRadius: isMobile ? 10 : 12
    };

    return (
        <InputWrapper noRadius={noRadius}>
            <InputElement 
                placeholder={placeholder}
                value={value}
                onChange={e=> onChange(e.target.value)}
            />
            <SimpleButton
                style={addButtonStyles}
                onClick={onAdd}
            >
                Ajouter
            </SimpleButton>
        </InputWrapper>
    )
}