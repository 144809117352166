import { EnumPaths } from "@utilities/enums";
import { fetchRequest } from "@utilities/requests";
import { 
    FetchRequestType, 
    FetchResponseType,
    SpontaneousDemand,
} from "@utilities/types";

export const fetchGetUserTalentSpontaneousDemandRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: `${EnumPaths.GET_ALL_TALENT_SPONTANEOUS_DEMAND}?userID=${payload}`,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetOrganisationSpontaneousDemandRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: `${EnumPaths.GET_ALL_ORGANISATION_SPONTANEOUS_DEMAND}?organisationID=${payload}`,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchUserTalentNewSpontaneousDemandRequest = async(
    payload: SpontaneousDemand
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.CREATE_SPONTANEOUS_DEMAND,
        method: 'POST',
        data: payload
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchUpdatedSpontaneousDemandRequest = async(
    payload: SpontaneousDemand
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.UPDATED_SPONTANEOUS_DEMAND,
        method: 'PUT',
        data: payload
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchDeletedSpontaneousDemandRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.DELETED_SPONTANEOUS_DEMAND,
        method: 'DELETE',
        data: {spontaneousID: payload}
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

