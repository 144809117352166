import { FC, useState } from "react";
import { ButtonGroup } from './buttonGroup';
import styled from "styled-components";
import { SigninForm } from "./signinForm";
import { SignupForm } from "./signupForm";
import { SubTitle, Title } from "./common";
import { useRecruiterSelectors, useTalentSelectors } from "@utilities/hooks";
import { ForgotPassword } from "./forgotPassword";

interface Props {
    onCloseModal: ()=> void
}

const Section = styled.section`
    margin-top: 30px;

    @media (min-width: 760px){
        margin-top: 50px;
    }
`;

export const LoginComponent:FC<Props> = ({
    onCloseModal
})=> {

    const { talent } = useTalentSelectors();
    const { recruiter } = useRecruiterSelectors();
    const [index, setIndex] = useState<number>(0);
    const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);

    if(isForgotPassword){
        return <ForgotPassword />
    }

    return (
        <Section>
            {
                !(talent || recruiter) && (
                    <>
                        <Title>
                            Bienvenue 👋
                        </Title>
                        <SubTitle>
                            Rejoignez votre espace en un clin d’oeil.
                        </SubTitle>

                        <ButtonGroup 
                            activeIndex={index}
                            onChange={setIndex}
                        />
                    </>
                )
            }

            {
                index === 0 ? (
                    <SigninForm 
                        onForgotPassword={()=> setIsForgotPassword(!isForgotPassword)}
                        onCloseModal={onCloseModal} 
                    />
                )
                : <SignupForm onCloseModal={onCloseModal} />
            }
        </Section>
    )
}