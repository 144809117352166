import { useMemo } from "react";
import { DropdownTextContent } from "./styles";

export const useControler = ()=> {

    const faqData = useMemo(()=> [
        {
            title: 'Qu’est-ce que Ohasis et comment cela fonctionne-t-il ?',
            content: (
                <DropdownTextContent>
                    Ohasis est une plateforme innovante de gestion des talents (ATS) et de recherche d’emploi. Les entreprises peuvent y poster des offres d’emploi, accéder à un système de suivi des candidatures (ATS), et bénéficier d’outils avancés pour trouver les meilleurs candidats. Les candidats peuvent, quant à eux, postuler aux offres et passer des tests de compétences, tout en ayant leur CV visible par les recruteurs.
                </DropdownTextContent>
            )
        },
        {
            title: 'Est-ce que Ohasis est gratuit pour les candidats ?',
            content: (
                <DropdownTextContent>
                    Oui, Ohasis est entièrement gratuit pour les candidats. Ils peuvent s’inscrire, créer un profil, postuler aux offres d’emploi, et passer des tests de compétences sans frais.
                </DropdownTextContent>
            )
        },
        {
            title: 'Comment les entreprises peuvent-elles tirer parti de l’ATS sur Ohasis ?',
            content: (
                <DropdownTextContent>
                    L’ATS de Ohasis permet aux entreprises de suivre facilement les candidatures, de filtrer les CV, et d’organiser les entretiens. Grâce à l’IA, elles peuvent également effectuer un matching précis entre les offres d’emploi et les compétences des candidats.                
                </DropdownTextContent>
            )
        },
        {
            title: 'Le service est-il gratuit pour les entreprises ?',
            content: (
                <DropdownTextContent>
                    Non, Ohasis est un service payant pour les entreprises. En souscrivant à l’une de nos formules, les recruteurs peuvent accéder à l’ATS, poster des offres d’emploi, et utiliser nos outils d’intelligence artificielle pour évaluer les candidatures
                </DropdownTextContent>
            )
        },
        {
            title: 'Comment les tests de compétences fonctionnent-ils pour les candidats ?',
            content: (
                <DropdownTextContent>
                    Les candidats peuvent passer des tests de compétences personnalisés, générés par l’IA en fonction de leur CV. Ils peuvent également effectuer des tests spécifiques proposés par les recruteurs pour valider leurs compétences et augmenter leurs chances d’être recrutés.
                </DropdownTextContent>
            )
        },
        {
            title: 'Les données personnelles des candidats sont-elles protégées par Ohasis ?',
            content: (
                <DropdownTextContent>
                    Absolument. Aucune donnée personnelle n’est conservée par notre intelligence artificielle. Nous respectons strictement la législation européenne en matière de protection des données et de l’IA. Toutes les informations traitées sont sécurisées et utilisées uniquement dans le cadre de la plateforme pour améliorer votre expérience et faciliter le processus de recrutement.
                </DropdownTextContent>
            )
        },
        {
            title: 'Quels sont les avantages d’utiliser Ohasis pour recruter des talents ?',
            content: (
                <DropdownTextContent>
                    Ohasis offre aux recruteurs une solution complète pour gérer le processus de recrutement de bout en bout. Avec des outils d’IA pour le matching, des tests de compétences intégrés, et un suivi des candidatures simplifié, les entreprises peuvent économiser du temps et trouver rapidement les meilleurs talents.
                </DropdownTextContent>
            )
        }
    ], []);

    return { faqData };
}