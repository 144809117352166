export enum EnumJobCategory {
    PROFESSEUR_UNIVERSITE = "Professeur d’université",
    CHERCHEUR_ACADEMIQUE = "Chercheur académique",
    RESPONSABLE_LABORATOIRE = "Responsable de laboratoire",
    INGENIEUR_AEROSPATIAL = "Ingénieur aérospatial",
    ANALYSTE_DEFENSE = "Analyste en défense",
    TECHNICIEN_AERONAUTIQUE = "Technicien en aéronautique",
    AGRONOME = "Agronome",
    TECHNICIEN_AGRICOLE = "Technicien agricole",
    RESPONSABLE_FERME = "Responsable de ferme",
    ARCHITECTE = "Architecte",
    DESIGNER_INTERIEUR = "Designer d’intérieur",
    URBANISTE = "Urbaniste",
    ARTISTE_GRAPHIQUE = "Artiste graphique",
    ILLUSTRATEUR = "Illustrateur",
    CONSERVATEUR_MUSEE = "Conservateur de musée",
    CHARGE_MISSION_ASSOCIATIVE = "Chargé de mission associative",
    RESPONSABLE_PROGRAMME_ONG = "Responsable de programme ONG",
    ANALYSTE_FINANCIER = "Analyste financier",
    GESTIONNAIRE_PORTEFEUILLE = "Gestionnaire de portefeuille",
    CONSEILLER_ASSURANCE = "Conseiller en assurance",
    CONDUCTEUR_TRAVAUX = "Conducteur de travaux",
    ARCHITECTE_INTERIEUR = "Architecte d’intérieur",
    INGENIEUR_GENIE_CIVIL = "Ingénieur en génie civil",
    CONSULTANT_STRATEGIE = "Consultant en stratégie",
    AUDITEUR_FINANCIER = "Auditeur financier",
    EXPERT_COMPTABLE = "Expert-comptable",
    JOURNALISTE = "Journaliste",
    PRODUCTEUR_AUDIOVISUEL = "Producteur audiovisuel",
    DIRECTEUR_ARTISTIQUE = "Directeur artistique",
    RESPONSABLE_MAGASIN = "Responsable de magasin",
    GESTIONNAIRE_ECOMMERCE = "Gestionnaire e-commerce",
    DIRECTEUR_DISTRIBUTION = "Directeur de la distribution",
    FORMATEUR = "Formateur",
    CHARGE_RECRUTEMENT = "Chargé de recrutement",
    CONSEILLER_PEDAGOGIQUE = "Conseiller pédagogique",
    CONSULTANT_DEVELOPPEMENT_DURABLE = "Consultant en développement durable",
    CHARGE_MISSION_ENVIRONNEMENT = "Chargé de mission environnement",
    SPECIALISTE_ENERGIE_RENOUVELABLE = "Spécialiste en énergie renouvelable",
    COMPTABLE = "Comptable",
    AUDITEUR_INTERNE = "Auditeur interne",
    ANALYSTE_CREDIT = "Analyste en crédit",
    CHEF_PROJET = "Chef de projet",
    COORDINATEUR_PROJET = "Coordinateur de projet",
    MANAGER_PROJET = "Manager de projet",
    CHEF_CUISINE = "Chef de cuisine",
    RESPONSABLE_QUALITE_ALIMENTAIRE = "Responsable qualité alimentaire",
    DIRECTEUR_RESTAURATION = "Directeur de la restauration",
    DIRECTEUR_HOTEL = "Directeur d’hôtel",
    GUIDE_TOURISTIQUE = "Guide touristique",
    MANAGER_LOISIRS = "Manager de loisirs",
    AGENT_IMMOBILIER = "Agent immobilier",
    GESTIONNAIRE_PATRIMOINE = "Gestionnaire de patrimoine",
    CONSULTANT_IMMOBILIER_COMMERCIAL = "Consultant en immobilier commercial",
    INGENIEUR_AGROALIMENTAIRE = "Ingénieur en agroalimentaire",
    RESPONSABLE_PRODUCTION = "Responsable de production",
    TECHNICIEN_MAINTENANCE_INDUSTRIELLE = "Technicien en maintenance industrielle",
    INGENIEUR_CIVIL = "Ingénieur civil",
    INGENIEUR_MECANIQUE = "Ingénieur mécanique",
    CONSULTANT_INGENIERIE = "Consultant en ingénierie",
    ADMINISTRATEUR_SYSTEMES = "Administrateur systèmes",
    INGENIEUR_RESEAU = "Ingénieur réseau",
    DEVELOPPEUR_BACKEND = "Développeur backend",
    AVOCAT = "Avocat",
    JURISTE_ENTREPRISE = "Juriste d’entreprise",
    NOTAIRE = "Notaire",
    GESTIONNAIRE_STOCK = "Gestionnaire de stock",
    RESPONSABLE_SUPPLY_CHAIN = "Responsable supply chain",
    COORDINATEUR_LOGISTIQUE = "Coordinateur logistique",
    STYLISTE = "Styliste",
    CHEF_PRODUIT_COSMETIQUE = "Chef de produit cosmétique",
    RESPONSABLE_BOUTIQUE_LUXE = "Responsable de boutique de luxe",
    LOGISTICIEN = "Logisticien",
    CONDUCTEUR_TRANSPORT_ROUTIER = "Conducteur de transport routier",
    RESPONSABLE_FLOTTE = "Responsable de flotte",
    PSYCHOLOGUE = "Psychologue",
    COACH_DEVELOPPEMENT_PERSONNEL = "Coach en développement personnel",
    CONSEILLER_BIEN_ETRE = "Conseiller en bien-être",
    CHARGE_COMMUNICATION = "Chargé de communication",
    RESPONSABLE_MARKETING_DIGITAL = "Responsable marketing digital",
    DIRECTEUR_CREATION = "Directeur de création",
    REDACTEUR_TECHNIQUE = "Rédacteur technique",
    TRADUCTEUR = "Traducteur",
    CORRECTEUR = "Correcteur",
    CHERCHEUR_BIOTECHNOLOGIE = "Chercheur en biotechnologie",
    SCIENTIFIQUE_LABORATOIRE = "Scientifique de laboratoire",
    ANALYSTE_RECHERCHE_CLINIQUE = "Analyste en recherche clinique",
    AGENT_ENTRETIEN = "Agent d’entretien",
    RESPONSABLE_HYGIENE_PROPRETE = "Responsable hygiène et propreté",
    TECHNICIEN_SURFACE = "Technicien de surface",
    CHARGE_CLIENTELE_ENTREPRISE = "Chargé de clientèle entreprise",
    GESTIONNAIRE_COWORKING = "Gestionnaire de coworking",
    RESPONSABLE_INCUBATEUR = "Responsable d’incubateur",
    MEDECIN = "Médecin",
    CHARGE_PROJET_ENVIRONNEMENTAL = "Chargé de projet environnemental",
    RESPONSABLE_ASSOCIATION_CARITATIVE = "Responsable d’association caritative",
    FONCTIONNAIRE_TERRITORIAL = "Fonctionnaire territorial",
    RESPONSABLE_ADMINISTRATIF = "Responsable administratif",
    GESTIONNAIRE_COLLECTIVITE = "Gestionnaire de collectivité",
    DEVELOPPEUR_LOGICIEL = "Développeur logiciel",
    DATA_SCIENTIST = "Data scientist",
    INGENIEUR_CYBERSECURITE = "Ingénieur en cybersécurité",
    INGENIEUR_TELECOM = "Ingénieur télécom",
    RESPONSABLE_RESEAU = "Responsable de réseau",
    TECHNICIEN_TELECOMMUNICATIONS = "Technicien en télécommunications",
}
