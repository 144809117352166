import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    
    margin-top: 135px;
    background-color: #EFECE6;
    padding: 10px;
    padding-bottom: 100px;

    @media (min-width: 760px){
        flex-direction: row;
        gap: 50px;
        padding-left: 50px;
        padding-right: 50px;
    }
`;
