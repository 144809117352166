import { CSSProperties, FC } from "react";
import { 
    ExplicationTxt, LevelResult, ModalContainer, 
    QuestionSection, 
    ResponseSection, ResponseTxt, 
    ResultCard, ResultCardBody, ResultCardHead, 
    ResultCardHeadQuestionLabel, ResultCardHeadQuestionValue,
    ScoreTxt,
    TestCardSubTitle,
} from "../styles";
import { qcmTestType, UserQCMSolution } from "@utilities/types";
import { FontFamilyEnum } from "@utilities/enums";

interface Props {
    selectedQCMSolution: UserQCMSolution | null
    isVisibleResponse: qcmTestType | null
    setIsVisibleResponse: (e: qcmTestType | null)=> void
}

export const TestCorrectionComponent:FC<Props> = ({
    selectedQCMSolution,
    isVisibleResponse,
    setIsVisibleResponse,
})=> {

    const customModalContainer:CSSProperties = {
        justifyContent: 'flex-start',
        paddingTop: 20,
        paddingBottom: 50,
        height: '100vh',
        overflowY: 'scroll',
    };

    const successCardStyles:CSSProperties = {
        borderColor: '#D7FFBE',
        backgroundColor: '#F8FFF3',
    };
    const failureCardStyles:CSSProperties = {
        border: 'solid 3px #101214',
        backgroundColor: '#FFF5F5',
        borderColor: "#FFDEE2"
    };
    const successElementStyles:CSSProperties = {
        color: '#6CA54A',
        backgroundColor: '#EAFFDD',
        fontFamily: FontFamilyEnum.SF_Pro_Display_Bold
    };
    const faillureElementStyles:CSSProperties = {
        color: '#F20E0E',
        backgroundColor: '#FFDEE2',
        fontFamily: FontFamilyEnum.SF_Pro_Display_SEMI_BOLD
    };
    const sujetStyle:CSSProperties = {
        fontSize: 16,
        marginTop: 20,
        textAlign: 'center',
        width: '70%',
    };

    const isValidateLevel = selectedQCMSolution?.score! >= 15;
    const isValidateLevelTxt = isValidateLevel ? 'Niveau validé ' : 'Niveau non validé';

    return (
        <ModalContainer style={customModalContainer}>
            <ScoreTxt>
                Votre score {selectedQCMSolution?.score}/20
            </ScoreTxt>

            <LevelResult style={{color: isValidateLevel ? '#6CA54A' : '#F20E0E'}}>
                {isValidateLevelTxt}
            </LevelResult>

            <TestCardSubTitle style={sujetStyle}>
                📚 {selectedQCMSolution?.entitle}
            </TestCardSubTitle>

            <QuestionSection>
                {
                    selectedQCMSolution?.qcm.map((el, i)=> {

                        const isOK = el.solution === el.selected_response;
                        const questionEmoji = isOK ? '✅' : '❌';
                        const questionNumer = `${questionEmoji} Question ${i+1}`;

                        const isSelectedElement = isVisibleResponse?.question === el.question;
                        const isVisible = (isVisibleResponse && isSelectedElement) || false;

                        const responseA_isSuccess = el.solution === el.reponse_a;
                        const responseA_isFailure = el.selected_response === el.reponse_a && el.solution !== el.selected_response;
                        
                        const responseB_isSuccess = el.solution === el.reponse_b;
                        const responseB_isFailure = el.selected_response === el.reponse_b && el.solution !== el.selected_response;
                        
                        const responseC_isSuccess = el.solution === el.reponse_c;
                        const responseC_isFailure = el.selected_response === el.reponse_c && el.solution !== el.selected_response;
                        
                        const responseD_isSuccess = el.solution === el.reponse_d;
                        const responseD_isFailure = el.selected_response === el.reponse_d && el.solution !== el.selected_response;

                        return (
                            <ResultCard
                                key={i}
                                style={isVisible ? (isOK ? successCardStyles : failureCardStyles) : {}}
                            >
                                <ResultCardHead onClick={()=> setIsVisibleResponse(isSelectedElement ? null : el)}>
                                    <ResultCardHeadQuestionLabel>
                                        {questionNumer}
                                    </ResultCardHeadQuestionLabel>
                                        
                                    <ResultCardHeadQuestionValue>
                                        {el.question}
                                    </ResultCardHeadQuestionValue>
                                </ResultCardHead>

                                {
                                    isVisible && (
                                        <ResultCardBody>
                                            <ResponseSection>
                                                <ResponseTxt 
                                                    style={
                                                        responseA_isSuccess ? successElementStyles :
                                                        responseA_isFailure ? faillureElementStyles : {}
                                                    }
                                                >
                                                    a. {el.reponse_a}
                                                </ResponseTxt>

                                                <ResponseTxt
                                                    style={
                                                        responseB_isSuccess ? successElementStyles :
                                                        responseB_isFailure ? faillureElementStyles : {}
                                                    }
                                                >
                                                    b. {el.reponse_b}
                                                </ResponseTxt>

                                                <ResponseTxt
                                                    style={
                                                        responseC_isSuccess ? successElementStyles :
                                                        responseC_isFailure ? faillureElementStyles : {}
                                                    }
                                                >
                                                    c. {el.reponse_c}
                                                </ResponseTxt>

                                                <ResponseTxt
                                                    style={
                                                        responseD_isSuccess ? successElementStyles :
                                                        responseD_isFailure ? faillureElementStyles : {}
                                                    }
                                                >
                                                    d. {el.reponse_d}
                                                </ResponseTxt>
                                            </ResponseSection>

                                            <ResultCardHeadQuestionLabel>
                                                💡 Explication
                                            </ResultCardHeadQuestionLabel>
                                            <ExplicationTxt>
                                                {el.explication}
                                            </ExplicationTxt>
                                        </ResultCardBody>
                                    )
                                }
                                
                            </ResultCard>
                        )
                    })
                }
            </QuestionSection>
        </ModalContainer>
    )
}