export const telework = [
    {
        "id": "1",
        "label": "Tous selectionné",
        "subcategories": [
            "Télétravail possible",
            "Télétravail occasionnel",
            "Pas de télétravail",
        ]
    },
];