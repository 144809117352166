import { FC } from "react";
import styled from "styled-components";
import { AnimatedText } from "./animatedText";
import { FontFamilyEnum } from "@utilities/enums";
import { useScreenSize } from "@utilities/hooks";

interface Props {
  onPress?: ()=> void
}

const AnimatedLogoWrapper = styled.div<{ isMobile: boolean }>`
  width: ${props => (props.isMobile ? '20px' : '30px')};
  height: ${props => (props.isMobile ? '20px' : '30px')};

  svg {
    width: 100%;
    height: 100%;
  }

  /* .gradient {
    stop {
      animation: gradientShift 5s ease infinite;
    }
  } */

  @keyframes gradientShift {
    0% {
      stop-color: #f79533;
    }
    16.66% {
      stop-color: #f37055;
    }
    33.33% {
      stop-color: #ef4e7b;
    }
    50% {
      stop-color: #a166ab;
    }
    66.66% {
      stop-color: #5073b8;
    }
    83.33% {
      stop-color: #1098ad;
    }
    100% {
      stop-color: #07b39b;
    }
  }
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const TextLogoTmp = styled.p<{isMobile: boolean}>`
  margin: 0%;
  font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
  font-size: ${props=> props.isMobile ? "25px" : "35px"};
  margin-left: ${props=> props.isMobile ? "3px" : "6px"};
  letter-spacing: -1px;

  @media (min-width: 760px){
    letter-spacing: -2px;
  }
`;

export const AnimatedLogo:FC<Props> = ({ onPress })=> {

    const { isMobile } = useScreenSize();

    return (
        <LogoWrapper onClick={onPress}>
            <AnimatedLogoWrapper isMobile={isMobile}>
                <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <linearGradient id="animatedGradient" x1="0%" y1="0%" x2="100%" y2="0%" className="gradient">
                            {/* <stop offset="0%" stopColor="#f79533" />
                            <stop offset="16.66%" stopColor="#f37055" />
                            <stop offset="33.33%" stopColor="#ef4e7b" />
                            <stop offset="50%" stopColor="#a166ab" />
                            <stop offset="66.66%" stopColor="#5073b8" />
                            <stop offset="83.33%" stopColor="#1098ad" />
                            <stop offset="100%" stopColor="#07b39b" /> */}
                            <stop offset="100%" stopColor="#101214" />
                        </linearGradient>
                    </defs>
                    <path d="M5.99951 6C5.99951 2.68629 8.6858 0 11.9995 0C15.3132 0 17.9995 2.68629 17.9995 6V12H5.99951V6Z" fill="url(#animatedGradient)" />
                    <path d="M12.0005 18H24.0005V24C24.0005 27.3137 21.3142 30 18.0005 30C14.6868 30 12.0005 27.3137 12.0005 24V18Z" fill="url(#animatedGradient)" />
                    <path d="M0 18C0 14.6863 2.68629 12 6 12H12V24H6C2.68629 24 0 21.3137 0 18Z" fill="url(#animatedGradient)" />
                    <path d="M17.9995 6.00024H23.9995C27.3132 6.00024 29.9995 8.68654 29.9995 12.0002C29.9995 15.314 27.3132 18.0002 23.9995 18.0002H17.9995V6.00024Z" fill="url(#animatedGradient)" />
                </svg>
            </AnimatedLogoWrapper>
            {/* <AnimatedText 
              font={FontFamilyEnum.SF_Pro_Display_Heavy} 
              size={isMobile ? "20px" : "35px"}
              style={{ marginLeft: isMobile ? 3 : 6 }}
            >
              Ohasis
            </AnimatedText> */}
            <TextLogoTmp isMobile={isMobile}>
              Ohasis
            </TextLogoTmp>
        </LogoWrapper>
    )
}