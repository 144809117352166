import React, { useCallback, useEffect, useState } from "react";
import { RecruiterAccount_UnsolicitedApplicationComponent } from "./RecruiterAccount_UnsolicitedApplication.component";
import { FetchRequestType } from "@utilities/types";
import { useRecruiterSelectors } from "@utilities/hooks";
import { EnumPaths } from "@utilities/enums";
import { fetchRequest } from "@utilities/requests";
import { OrganisationResponseToJobOffer } from "@utilities/types";

export const RecruiterAccount_UnsolicitedApplication:React.FC = ()=> {

    const { recruiter } = useRecruiterSelectors();
    
    const [postList, setPostList] = useState<OrganisationResponseToJobOffer[]>([]);

    const onGetTalentResponseToJob = useCallback(async(organisationID: string)=> {
        const requestConfig:FetchRequestType = {
            route: `${EnumPaths.GET_ORGANISATION_RESPONSES_TO_JOB_OFFER}?organisationID=${organisationID}`,
            method: 'GET'
        };
    
        const response = await fetchRequest(requestConfig);
        if(response.code === 500){
            alert(response.data.message);
            return [];
        } else {
            return response.data as OrganisationResponseToJobOffer[];
        }
    }, []);

    useEffect(()=> {
        (async()=> {
            if(recruiter){
                const result = await onGetTalentResponseToJob(recruiter._organisationID!);
                setPostList(result);
            }
        })();
    }, [onGetTalentResponseToJob, recruiter])

    return (
        <RecruiterAccount_UnsolicitedApplicationComponent 
            postList={postList}
        />
    )
}
