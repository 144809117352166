import { FC, useMemo } from "react";
import styled from "styled-components";
import { Icon } from "./icon";
import { FontFamilyEnum, IconNameEnum } from "@utilities/enums";
import { useScreenSize } from "@utilities/hooks";

interface IMessageNotif {
    type: 'success' | 'error' | 'warning' | 'infos'
    message: string
}

const MessageNotifWrapper = styled.div<{color: string}>`
    display: flex;
    align-items: center;
    flex: 1;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    border: solid 1px ${props=> props.color};
    background-color: ${props=> props.color + 30};

    @media (min-width: 950px) {
        height: 66px;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 12px;
        border-width: 2px;
    }
`;

const Label = styled.label<{color: string}>`
    font-size: 11px;
    margin-left: 9px;
    color: ${props=> props.color};
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    @media (min-width: 950px) {
        font-size: 16px;
        margin-left: 15px;
    }
`;

export const MessageNotif:FC<IMessageNotif> = ({
    type,
    message,
})=> {

    const { isMobile } = useScreenSize();

    const typeColor = useMemo(()=> {
        switch (type) {
            case 'error':
                return '#FF6174';
            case 'success':
                return '#61c589';
            case 'warning':
                return '#fab832';
            case 'infos':
                return '#40ced8';
        }
    }, [type]);

    const labelColor = useMemo(()=> {
        switch (type) {
            case 'error':
                return '#585858';
            case 'success':
                return '#585858';
            case 'warning':
                return '#585858';
            case 'infos':
                return '#585858';
        }
    }, [type]);

    return (
        <MessageNotifWrapper color={typeColor}>
            <Icon 
                iconName={IconNameEnum.warning}
                color={typeColor}
                size={isMobile ? '1x' : '2x'}
            />
            <Label color={labelColor}>
                {message}
            </Label>
        </MessageNotifWrapper>
    )
} 