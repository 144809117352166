import React, { useCallback, useEffect, useState } from "react";
import { RecruiterAccount_TestComponent } from "./RecruiterAccount_Test.component";
import { useRecruiterDispatch, useRecruiterSelectors } from "@utilities/hooks";
import { TEST_QCM } from "@utilities/types";

export const RecruiterAccount_Test:React.FC = ()=> {

    const { recruiter, organisationTestList } = useRecruiterSelectors();
    const {
        getOrganisationTestRequest,
        onSaveOrganisationTestRequest,
    } = useRecruiterDispatch();

    const [organisationTest, setOrganisationTest] = useState<TEST_QCM[]>([]);

    const handleSaveOrganisationTest = useCallback(async(test: TEST_QCM)=> {
        await onSaveOrganisationTestRequest(test);
        if(recruiter){
            await getOrganisationTestRequest(recruiter._organisationID!);
            
        }
    }, [getOrganisationTestRequest, onSaveOrganisationTestRequest, recruiter]);

    useEffect(()=> {
        if(organisationTestList){
            setOrganisationTest(organisationTestList);
        }
    }, [organisationTestList]);

    useEffect(()=> {
        if(recruiter){
            getOrganisationTestRequest(recruiter._organisationID!);
        }
    }, [recruiter])

    return (
        <RecruiterAccount_TestComponent 
            recruiter={recruiter!}
            organisationTest={organisationTest}
            onSaveOrganisationTest={handleSaveOrganisationTest}
        />
    )
}
