import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const TitleSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 18px;

    @media (min-width: 760px) {
        margin-top: 30px;
    }
`;

export const Title = styled.h1`
    display: flex;
    font-size: 25px;
    margin: 0%;
    text-align: center;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};

    @media (min-width: 760px) {
        font-size: 64px;
    }
`;

export const SearchSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    @media (min-width: 760px) {
        margin-top: 60px;
    }
`;

export const InfoParagraph = styled.label`
    font-size: 10px;
    margin-top: 10px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Regular};

    @media (min-width: 760px) {
        font-size: 12px;
        margin-top: 15px;
    }
`;

export const InfoParagraphUnderline = styled.label`
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
`;

export const FloatingImagesSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;

    @media (min-width: 760px) {
        margin-top: 70px;
    }
`;

export const FloatingCardleftContainer = styled.div`
    margin-right: -70px;
    margin-top: 40px;
    z-index: 99;

    @media (min-width: 760px) {
        margin-right: -180px;
        margin-top: 100px;
    }
`;
export const FloatingCardRightContainer = styled.div`
    margin-left: -70px;
    margin-top: 40px;
    z-index: 99;

    @media (min-width: 760px) {
        margin-left: -180px;
        margin-top: 100px;
    }
`;

export const BrandSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;

    @media (min-width: 760px) {
        margin-top: 100px;
    }
`;

export const Image = styled.img`
    margin-left: 5px;
    margin-right: 5px;

    @media (min-width: 760px) {
        margin-left: 20px;
        margin-right: 20px;
    }
`;

export const BrandParagraph = styled.p`
    margin: 0%;
    text-align: center;
    font-size: 12px;
    margin-right: 10px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Bold};

    @media (min-width: 760px) {
        font-size: 30px;
        margin-right: 20px;
    }
`;

export const HomeSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;

    @media (min-width: 760px) {
        margin-top: 160px;
    }
`;
export const HomeTitle = styled.h3`
    margin: 0%;
    font-size: 22px;
    color: #101214;
    text-align: center;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};

    @media (min-width: 760px) {
        font-size: 44px;
    }
`;
export const HomeSubTitle = styled.h5`
    margin: 0%;
    margin-top: 3px;
    font-size: 13px;
    color: #101214;
    text-align: center;
    width: 90%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_SEMI_BOLD};

    @media (min-width: 760px) {
        font-size: 20px;
        margin-top: 10px;
        width: 70%;
    }
`;
export const HomeSubSection = styled.div`
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: 760px) {
        margin-top: 60px;
    }
`;
export const HomeItem = styled.div`
    width: 132px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px;

    @media (min-width: 760px) {
        width: 296px;
        margin: 25px;
    }
`;
export const HomeItemLabel = styled.label`
    margin: 0%;
    font-size: 12px;
    margin-top: 9px;
    text-align: center;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    @media (min-width: 760px) {
        font-size: 18px;
        margin-top: 15px;
    }
`;

export const IconWrapper = styled.label`
    margin-left: 8px;
    margin-right: 8px;
`;
