import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
  RecruiterInitialStateType,
  UserRecruiter,
  Organisation,
  CV,
  TEST_QCM,
  UserTalent,
} from '@utilities/types';
import { SLICES_NAMES } from '../slicesNames';

const initialState: RecruiterInitialStateType = {
  recruiter: null,
  organisation: null,
  // userRecruiterToken: '',
  talentCVList: [],
  organisationTestList: [],
};

const recruiterSlice = createSlice({
  name: SLICES_NAMES.recruiter,
  initialState, 
  reducers: {
    saveRecruiter: (state, action: PayloadAction<UserRecruiter | null>) => {
      state.recruiter = action.payload;
    },
    saveOrganisation: (state, action: PayloadAction<Organisation | null>) => {
      state.organisation = action.payload
    },
    // saveCreateUserRecruiterToken: (state, action: PayloadAction<string>) => {
    //   state.userRecruiterToken = action.payload
    // },
    saveTalentCvList: (state, action: PayloadAction<{cv: CV, talent: UserTalent}[]>) => {
      state.talentCVList = action.payload
    },
    saveSearchTalentCvList: (state, action: PayloadAction<{cv: CV, talent: UserTalent}[]>) => {
      state.talentCVList = action.payload;
    },
    saveOrganisationTestList: (state, action: PayloadAction<TEST_QCM[]>) => {
      state.organisationTestList = action.payload;
    },
  },
});

export const { 
  saveRecruiter,
  saveOrganisation,
  // saveCreateUserRecruiterToken,
  saveTalentCvList,
  saveSearchTalentCvList,
  saveOrganisationTestList,
} = recruiterSlice.actions;

export const recruiterReducers = recruiterSlice.reducer;
