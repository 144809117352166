import { FC, useCallback, useState } from "react";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { SearchWithAutoComplete } from "./SearchWithAutoComplete";

interface Props {
    placeholder?: string
    onSelectedLocalisation: (localisation: string)=> void
}

export const LocalisationAutoComplete:FC<Props> = ({
    placeholder,
    onSelectedLocalisation
})=> {

    const provider = new OpenStreetMapProvider();
    const [citiesAndCountriesSuggestions, setCitiesAndCountriesSuggestions] = useState<string[]>([]);

    const handleSearchCitiesAndCountries = useCallback(async(cityAndCountry: string) => {
        if (cityAndCountry) {
            const results = await provider.search({ query: cityAndCountry });
            console.log(results);
            const resultsStringArray = results.map((option: any) => option.label);
            setCitiesAndCountriesSuggestions(resultsStringArray);
        } else {
            setCitiesAndCountriesSuggestions([]);
        }
    }, [provider]);

    return (
        <SearchWithAutoComplete 
            placeholder={placeholder || "Sélectionnez une ville"}
            suggestions={citiesAndCountriesSuggestions} 
            onChange={handleSearchCitiesAndCountries}
            onSelected={onSelectedLocalisation}
        />
    )
}