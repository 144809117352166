export enum EnumPaths {
    GET_ALL_USERS_TALENTS = '/users-talents/all-users-talents',
    GET_USER_TALENT = '/users-talents/user-talent',
    CREATE_USER_TALENT = '/users-talents/create-user-talent',
    LOGIN_USER_TALENT = '/users-talents/login-user-talent',
    UPDATED_USER_TALENT = '/users-talents/updated-user-talent',
    UPDATED_USER_AVATAR_TALENT = '/users-talents/updated-user-avatar-talent',
    CHANGE_USER_TALENT_PASSWORD = '/users-talents/change-user-talent-password',
    CREATE_USER_TALENT_NEW_PASSWORD = '/users-talents/create-user-talent-new-password',

    GET_ALL_USERS_RECRUITERS_BY_ORGABISATION = '/users-recruiters/all-users-recruiters-by-organisation',
    GET_ALL_USERS_RECRUITERS = '/users-recruiters/all-users-recruiters',
    GET_USER_RECRUITER = '/users-recruiters/user-recruiter',
    CREATE_USER_RECRUITER = '/users-recruiters/create-user-recruiter',
    LOGIN_USER_RECRUITER = '/users-recruiters/login-user-recruiter',
    UPDATED_USER_RECRUITER = '/users-recruiters/updated-user-recruiter',
    CHANGE_USER_RECRUITER_PASSWORD = '/users-recruiters/change-user-recruiter-password',
    CREATE_USER_RECRUITER_NEW_PASSWORD = '/users-recruiters/create-user-recruiter-new-password',

    GET_ALL_TALENT_CV = '/cv/get-all-talent-cv',
    GET_TALENT_CV = '/cv/get-talent-cv',
    SAVE_TALENT_CV = '/cv/save-talent-cv',
    FIND_TALENTS_BY_CV = '/cv/find-talents-by-cv',

    GET_TALENT_QCM = '/qcm/get-talent-qcm',
    GET_TALENT_QCM_RESULT = '/qcm/get-talent-qcm-result',
    CREATE_TALENT_QCM = '/qcm/create-talent-qcm',
    QCM_TALENT_EVALUATION = '/qcm/qcm-talent-evaluation',
    GENERATE_ORGANISATION_QCM = '/qcm/generate-organisation-qcm',
    SAVE_ORGANISATION_QCM = "/qcm/save-organisation-qcm",
    GET_ORGANISATION_QCM = "/qcm/get-organisation-qcm",

    GET_ALL_ORGANISATIONS = '/organisations/get-all-organisations',
    GET_ORGANISATION = '/organisations/get-organisation',
    CREATE_ORGANISATION = '/organisations/create-organisation',
    UPDATED_ORGANISATION = '/organisations/create-organisation',

    GET_ALL_ORGANISATION_APPOINTMENTS = '/appointments/get-all-organisation-appointments',
    GET_ALL_TALENT_APPOINTMENTS = '/appointments/get-all-talent-appointments',
    CREATE_APPOINTMENT = '/appointments/create-appointment',
    UPDATED_APPOINTMENT = '/appointments/updated-appointment',
    DELETE_APPOINTMENT = '/appointments/delete-appointment',

    GET_ALL_POSTS = '/posts/get-all-posts',
    GET_ALL_ORGANISATION_POSTS = '/posts/get-all-organisation-posts',
    CREATE_POST = '/posts/create-post',
    UPDATED_POST = '/posts/updated-post',
    DELETED_POST = '/posts/deleted-post',

    GET_ALL_SPONTANEOUS_DEMAND = '/spontaneous-demand/get-all-spontaneous-demand',
    GET_ALL_TALENT_SPONTANEOUS_DEMAND = '/spontaneous-demand/get-all-talent-spontaneous-demand',
    GET_ALL_ORGANISATION_SPONTANEOUS_DEMAND = '/spontaneous-demand/get-all-organisation-spontaneous-demand',
    CREATE_SPONTANEOUS_DEMAND = '/spontaneous-demand/create-spontaneous-demand',
    UPDATED_SPONTANEOUS_DEMAND = '/spontaneous-demand/updated-spontaneous-demand',
    DELETED_SPONTANEOUS_DEMAND = '/spontaneous-demand/deleted-spontaneous-demand',

    GET_ALL_TALENT_SAVED_POSTS = '/saved-posts/get-all-talent-saved-posts',
    TALENT_SAVE_POST = '/saved-posts/talent-save-post',
    TALENT_DELETE_SAVED_POST = '/saved-posts/talent-delete-saved-post',

    GET_JOB_2_CV_MATCHING = '/matching/get-job-2-cv-matching',
    MATCHING_JOB_2_CV = '/matching/matching-job-2-cv',

    GET_TALENT_RESPONSES_TO_JOB_OFFER = '/responseToJobOffer/get-talent-responses-to-job-offer',
    GET_ORGANISATION_RESPONSES_TO_JOB_OFFER = '/responseToJobOffer/get-organisation-responses-to-job-offer',
    SAVE_RESPONSE_TO_JOB_OFFER = '/responseToJobOffer/save-response-to-job-offer',
}
