import { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { Icon } from './icon';
import { FontFamilyEnum, IconNameEnum } from '@utilities/enums';

interface Props {
  label: string
  checked: boolean
  setChecked: (checked: boolean)=> void
  styles?: CSSProperties
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckBoxWrapper = styled.div<{active: boolean}>`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: solid 1px ${props=> props.active ? '#101214' : 'lightgrey'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Check = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background-color: #101214;
`;

const Label = styled.label`
  margin-left: 8px;
  color: #101214;
  font-size: 12px;
  font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
  cursor: pointer;

  @media (min-width: 760px){
    font-size: 14px;
  }
`;

export const SimpleCheckbox:FC<Props> = ({ label, checked, styles, setChecked }) => {

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <CheckboxContainer onClick={handleCheckboxChange} style={styles}>
      {
        checked ? (
          <CheckBoxWrapper active={checked}>
            <Check />
          </CheckBoxWrapper>
        ) : (
          <CheckBoxWrapper active={checked} />
        )
      }
      <Label>{label}</Label>
    </CheckboxContainer>
  );
};