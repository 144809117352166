import React, { useState } from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './styles.css';
import { ICalendarEvents } from "./interface";
import { Modal } from '../../components'

import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import FR from 'date-fns/locale/fr';

export const CalendarEvents:React.FC<ICalendarEvents> = ({
    
})=> {

    const [showAgenda, setShowAgenda] = useState<boolean>(false);

    const locales = {
        'fr': FR,
    }
    
    const localizer = dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales,
    });

    const myEventsList = [
        {
            allDay: false,
            title: 'Lorem ipsum dolor sit',
            start: new Date(),
            end: new Date(),
            resource: 'resource',
        }
    ];

    return (
        <div>
            <div
                onClick={()=> setShowAgenda(true)}
                className="ft_calendarIcon_container"
            >
                {/* <Icon 
                    iconName={EnumIconName.calendar}
                    size={25}
                    colors="#fff"
                /> */}
            </div>

            <Modal
                isVisible={showAgenda}
                onClose={()=> setShowAgenda(false)}
            >
                <Calendar
                    localizer={localizer}
                    events={myEventsList}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    culture="fr"
                    messages={{
                        week: 'Semaine',
                        work_week: 'Semaine de travail',
                        day: 'Jour',
                        month: 'Mois',
                        previous: 'Précédent',
                        next: 'Suivant',
                        today: `Aujourd'hui`,
                        agenda: 'Agenda',
                        showMore: (total) => `+${total} plus`,
                    }}
                />
            </Modal>
        </div>
    )
}