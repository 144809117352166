import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SpontaneousDemandInitialStateType,
  SpontaneousDemand,
} from '@utilities/types';
import { SLICES_NAMES } from '../slicesNames';

const initialState: SpontaneousDemandInitialStateType = {
  userTalentDemand: [],
  organisationRecruiterDemand: []
};

const spontaneousDemandSlice = createSlice({
  name: SLICES_NAMES.spontaneous_demand,
  initialState, 
  reducers: {
    saveUserTalentSpontaneousDemand: (state, action: PayloadAction<SpontaneousDemand[]>) => {
      state.userTalentDemand = action.payload
    },
    saveOrganisationRecruiterSpontaneousDemand: (state, action: PayloadAction<SpontaneousDemand[]>) => {
      state.organisationRecruiterDemand = action.payload
    },
  },
});

export const { 
  saveUserTalentSpontaneousDemand,
  saveOrganisationRecruiterSpontaneousDemand,
} = spontaneousDemandSlice.actions;

export const spontaneousDemandReducers = spontaneousDemandSlice.reducer;
