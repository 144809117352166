import React, { useCallback, useMemo, useState } from "react";
import './styles.css';
import { IQcmGenerator } from "./interface";
import { Paragraph, Title } from "../../atomes";
import { Button, Checkbox, Select } from "../../molecules";
import { Accordion, AccordionTab } from "primereact/accordion";
import { EnumUserLevel, qcmTestType } from "@utilities/types";
import { enumToStringArray } from "@utilities/functions";
import { EnumJobCategory } from "@utilities/enums";
import { Input } from "../../components";

export const QcmGenerator:React.FC<IQcmGenerator> = ({
    testTitle,
    testLevel,
    questions,
    setTestLevel,
    setTestCategory,
    setQuestions,
    setTestTitle,
    onSubmitForm,
})=> {

    const [questionsTmp, setQuestionsTmp] = useState<qcmTestType[]>(questions);
    const [selectedQcmLevel, setSelectedQcmLevel] = useState<EnumUserLevel | null>(testLevel);
    const [selectedJobCategory, setSelectedJobCategory] = useState<string>('');

    const jobCategories = useMemo(()=> (
        enumToStringArray(EnumJobCategory)
    ), [])

    const handleQuestionChange = useCallback((index: number, field: string, value: string) => {
        const updatedQuestions = questionsTmp.map((question, qIndex) => {
            if (qIndex === index) {
                return { ...question, [field]: value };
            }
            return question;
        });
        setQuestionsTmp(updatedQuestions);
        setQuestions(updatedQuestions);
    }, [questionsTmp, setQuestions]);

    const handleSolutionChange = useCallback((index: number, solution: string) => {
        const updatedQuestions = questionsTmp.map((question, qIndex) => {
            if (qIndex === index) {
                return { ...question, solution };
            }
            return question;
        });
        setQuestionsTmp(updatedQuestions);
        setQuestions(updatedQuestions);
    }, [questionsTmp, setQuestions]);

    const handleSubmit = useCallback(() => {
        if (!testTitle.trim()) {
            alert("Le titre du test est requis.");
            return;
        }
        for (let i = 0; i < questionsTmp.length; i++) {
            const q = questionsTmp[i];
            if (!q.question.trim()) {
                alert(`La question ${i + 1} est requise.`);
                return;
            }
            if (!q.reponse_a.trim() || !q.reponse_b.trim() || !q.reponse_c.trim() || !q.reponse_d.trim()) {
                alert(`Toutes les réponses sont requises pour la question ${i + 1}.`);
                return;
            }
            if (!q.solution) {
                alert(`La solution est requise pour la question ${i + 1}.`);
                return;
            }
        }

        onSubmitForm();
    }, [questionsTmp, testTitle, onSubmitForm]);

    return (
        <div className="ft_rexruiter_test_formModalContainer">
            <Title 
                variant='h_sm'
                weight='regular'
                label="Créer un nouveau test"
                className="ft_recruiter_test_modalFormTitle"
            />

            <Select 
                placeholder="Selectionner une categorie"
                options={jobCategories}
                value={selectedJobCategory}
                onChange={(e)=> {
                    setSelectedJobCategory(e);
                    setTestCategory(e);
                }}
            />
            <Input
                placeholder="Titre du test"
                value={testTitle}
                onChange={setTestTitle}
            />

            <div className="ft_rexruiter_testLevel_CheckboxBlockContainer">
                <Paragraph 
                    label="Niveau du test :"
                    className="ft_rexruiter_testLevel_txt"
                />
                <div className="ft_rexruiter_testLevel_CheckboxBlock">
                    <Checkbox 
                        label={EnumUserLevel.debutant}
                        checked={EnumUserLevel.debutant === selectedQcmLevel}
                        onCheckboxPress={() => {
                            setSelectedQcmLevel(EnumUserLevel.debutant);
                            setTestLevel(EnumUserLevel.debutant);
                        }}
                    />
                    <Checkbox 
                        label={EnumUserLevel.intermediaire}
                        checked={EnumUserLevel.intermediaire === selectedQcmLevel}
                        onCheckboxPress={() => {
                            setSelectedQcmLevel(EnumUserLevel.intermediaire);
                            setTestLevel(EnumUserLevel.intermediaire);
                        }}
                    />
                    <Checkbox 
                        label={EnumUserLevel.confirme}
                        checked={EnumUserLevel.confirme === selectedQcmLevel}
                        onCheckboxPress={() => {
                            setSelectedQcmLevel(EnumUserLevel.confirme);
                            setTestLevel(EnumUserLevel.confirme);
                        }}
                    />
                </div>
            </div>

            <Accordion
                style={{width: '100%'}}
            >
                {
                    questionsTmp.map((question, index) => (
                        <AccordionTab 
                            key={index}
                            header={`Question ${index + 1}`}
                        >
                            <div className="ft_rexruiter_test_question_block">
                                <div>
                                    <Paragraph 
                                        label={`Question ${index + 1}`}
                                    />
                                    <Input 
                                        inputType='textarea'
                                        placeholder={`Question ${index + 1}`}
                                        value={question.question}
                                        onChange={(e) => handleQuestionChange(index, 'question', e)}
                                    />
                                </div>

                                <div>
                                    <Paragraph 
                                        label="Réponse A"
                                    />
                                    <Input 
                                        inputType='textarea'
                                        placeholder="Réponse A"
                                        value={question.reponse_a}
                                        onChange={(e) => handleQuestionChange(index, 'reponse_a', e)}
                                    />
                                </div>

                                <div>
                                    <Paragraph 
                                        label="Réponse B"
                                    />
                                    <Input 
                                        inputType='textarea'
                                        placeholder="Réponse B"
                                        value={question.reponse_b}
                                        onChange={(e) => handleQuestionChange(index, 'reponse_b', e)}
                                    />
                                </div>

                                <div>
                                    <Paragraph 
                                        label="Réponse C"
                                    />
                                    <Input 
                                        inputType='textarea'
                                        placeholder="Réponse C"
                                        value={question.reponse_c}
                                        onChange={(e) => handleQuestionChange(index, 'reponse_c', e)}
                                    />
                                </div>

                                <div>
                                    <Paragraph 
                                        label="Réponse D"
                                    />
                                    <Input 
                                        inputType='textarea'
                                        placeholder="Réponse D"
                                        value={question.reponse_d}
                                        onChange={(e) => handleQuestionChange(index, 'reponse_d', e)}
                                    />
                                </div>

                                <div className="ft_rexruiter_test_solution_container">
                                    <Paragraph 
                                        label="Solution :"
                                    />
                                    <div className="ft_rexruiter_test_solution_CheckboxBlock">
                                        <Checkbox 
                                            label="A"
                                            checked={question.solution !== '' && question.solution === question.reponse_a}
                                            onCheckboxPress={() => handleSolutionChange(index, question.reponse_a)}
                                        />
                                        <Checkbox 
                                            label="B"
                                            checked={question.solution !== '' && question.solution === question.reponse_b}
                                            onCheckboxPress={() => handleSolutionChange(index, question.reponse_b)}
                                        />
                                        <Checkbox 
                                            label="C"
                                            checked={question.solution !== '' && question.solution === question.reponse_c}
                                            onCheckboxPress={() => handleSolutionChange(index, question.reponse_c)}
                                        />
                                        <Checkbox 
                                            label="D"
                                            checked={question.solution !== '' && question.solution === question.reponse_d}
                                            onCheckboxPress={() => handleSolutionChange(index, question.reponse_d)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <Paragraph 
                                        label="Explication"
                                    />
                                    <Input 
                                        inputType='textarea'
                                        placeholder="Explication"
                                        value={question.explication!}
                                        onChange={(e) => handleQuestionChange(index, 'explication', e)}
                                    />
                                </div>
                            </div>
                        </AccordionTab>
                    ))
                }
            </Accordion>

            <Button 
                label="Soumettre le test"
                onButtonPress={handleSubmit}
                customClassname="ft_rexruiter_test_formModalButton"
            />
        </div>
    )
} 