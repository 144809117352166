import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RecruiterAccount_MatchingComponent } from "./RecruiterAccount_Matching.component";
import { 
    usePostSelectors, 
    usePostsDispatch, 
    useRecruiterSelectors, 
} from "@utilities/hooks";
import { FetchRequestType, FetchResponseType, Matching } from "@utilities/types";
import { fetchRequest } from "@utilities/requests";
import { EnumPaths } from "@utilities/enums";

export const RecruiterAccount_Matching:React.FC = ()=> {

    const { recruiter } = useRecruiterSelectors();
    const { posts } = usePostSelectors();
    const { onGetOrganisationPostsRequest } = usePostsDispatch();

    const [postMatching, setPostMatching] = useState<Matching>();

    const filteredPost = useMemo(()=> (posts), [posts]);

    const getPostMatching = useCallback(async(postID: string)=> {
        try {
            const requestConfig: FetchRequestType = {
                route: `${EnumPaths.GET_JOB_2_CV_MATCHING}?postID=${postID}`,
                method: 'GET',
            };

            const response:FetchResponseType = await fetchRequest(requestConfig);
            if(response.code === 200 && response.data.length > 0){
                setPostMatching(response.data[0]);
            } else {
                onReqPostMatching(postID);
            }

        } catch (error) {
            console.error('Error in fetchSigninRequest:', error);
            throw error;
        }
    }, []);

    const onReqPostMatching = useCallback(async(postID: string)=> {
        try {
            const requestConfig: FetchRequestType = {
                route: EnumPaths.MATCHING_JOB_2_CV,
                method: 'POST',
                data: {postID: postID}
            };

            const response:FetchResponseType = await fetchRequest(requestConfig);
            
            if(response.code === 200){
                await getPostMatching(postID);
            }

        } catch (error) {
            console.error('Error in fetchSigninRequest:', error);
            throw error;
        }
    }, [getPostMatching]);

    useEffect(()=> {
        if(recruiter){
            onGetOrganisationPostsRequest(recruiter._organisationID!);
        }
    }, [recruiter]);

    return (
        <RecruiterAccount_MatchingComponent 
            postMatching={postMatching!}
            posts={filteredPost}
            getPostMatching={getPostMatching}
        />
    )
}
