import { 
    faAngleDown,
    faAngleUp,
    faArrowDownShortWide,
    faArrowRight,
    faArrowUpShortWide,
    faBan,
    faBars, 
    faBell, 
    faBookmark, 
    faBriefcase, 
    faCalendarCheck, 
    faCheck, 
    faCircleExclamation, 
    faClipboardCheck, 
    faClock, 
    faEye, 
    faEyeSlash, 
    faFileAlt, 
    faMagnifyingGlass, 
    faMinus, 
    faPen, 
    faPlus, 
    faRightFromBracket, 
    faRightToBracket, 
    faRobot, 
    faRocket, 
    faSearch, 
    faShare, 
    faStar, 
    faStream, 
    faTasks, 
    faTrash, 
    faTrashCan, 
    faUser,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";

export const IconNameEnum = {
    menu: faBars,
    user: faUser,
    close: faXmark,
    plus: faPlus,
    minus: faMinus,
    search: faSearch,
    briefcase: faBriefcase,
    robot: faRobot,
    task: faTasks,
    clock: faClock,
    stream: faStream,
    calendarCheck: faCalendarCheck,
    ban: faBan,
    fileAlt: faFileAlt,
    clipboard: faClipboardCheck,
    rocket: faRocket,
    bell: faBell,
    arrowRight: faArrowRight,
    magnifyingGlass: faMagnifyingGlass,
    eye: faEye,
    eyeSlash: faEyeSlash,
    warning: faCircleExclamation,
    connect: faRightToBracket,
    disconect: faRightFromBracket,
    star: faStar,
    down: faAngleDown,
    up: faAngleUp,
    filterDown: faArrowDownShortWide,
    filterUp: faArrowUpShortWide,
    share: faShare,
    save: faBookmark,
    edit: faPen,
    trash: faTrash,
    trashCan: faTrashCan,
    check: faCheck,
}