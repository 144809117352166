import React, { useCallback, useState } from "react";
import './styles.css';
import { IJobOffersCard } from "./interface";
import { HtmlParagraph, Paragraph, Title } from "../../atomes";
import { Modal } from "../../components";
import { formatDate, formatTextMaxChars } from "@utilities/functions";
import { Badge, Button } from "../../molecules";
import { EnumIconName } from "@utilities/enums";

const badgeCustomColor = '#F3920050';

export const JobOffersCard:React.FC<IJobOffersCard> = ({
    postInfos,
    testTitle,
    hideActionButtons = false,
    onSendPostResponse,
    onSavePost,
    onReportPost,
    onEdit,
    onDelete,
    onArchieved,
    onAddTest
})=> {

    const [displaySelectedJobModal, setDisplaySelectedJobModal] = useState<boolean>(false);

    const toggleDisplaySelectedJobModal = useCallback(()=> {
        setDisplaySelectedJobModal(!displaySelectedJobModal);
    }, [displaySelectedJobModal]);

    const renderActionButtons = useCallback(()=> {
        return (
            <div className="ft_jobOffer_actionsButtons_container">
                <div className="ft_jobOffer_iconButtons_container">
                    {
                        onArchieved && (
                            <div 
                                className="ft_jobOffer_Icon_container"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onArchieved();
                                }}
                            >
                                {/* <Icon 
                                    iconName={EnumIconName.lock}
                                    size={18}
                                    colors="#814997"
                                /> */}
                                archieved
                            </div>
                        )
                    }
                    {
                        onEdit && (
                            <div 
                                className="ft_jobOffer_Icon_container"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onEdit();
                                }}
                            >
                                {/* <Icon 
                                    iconName={EnumIconName.edit}
                                    size={18}
                                    colors="#F39200"
                                /> */}
                                edit
                            </div>
                        )
                    }
                    {
                        onDelete && (
                            <div 
                                className="ft_jobOffer_Icon_container"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDelete();
                                }}
                            >
                                {/* <Icon 
                                    iconName={EnumIconName.trash}
                                    size={18}
                                    colors="#e4003a"
                                /> */}
                                delete
                            </div>
                        )
                    }
                </div>

                {
                    onAddTest && (
                        <Button 
                            label="ajouter un test"
                            onButtonPress={(e)=> {
                                e.stopPropagation();
                                onAddTest();
                            }}
                        />
                    )
                }
            </div>
        )
    }, [onEdit, onDelete, onArchieved, onAddTest]);

    return (
        <div>
            <div 
                className="ft_jobOffer_container"
                onClick={toggleDisplaySelectedJobModal}
            >   
                <Title 
                    label={postInfos.entitle}
                    variant='h_xs'
                    weight='regular'
                    className="ft_jobOfferCard_title"
                />
                <Paragraph 
                    label={`${postInfos.city}, ${postInfos.country}`}
                    className="ft_jobOfferCard_subTitle"
                />

                <div className="ft_jobOffer_container_badgeList_container">
                    <Badge 
                        label={postInfos.criteria.contratType}
                        customColor={badgeCustomColor}
                    />
                    <Badge 
                        label={`Début le ${formatDate(postInfos.startAt)}`}
                        customColor={badgeCustomColor}
                    />
                    <Badge 
                        label={`${String(postInfos.criteria.minSalaryMonth)} Fcfa/mois min`}
                        customColor={badgeCustomColor}
                    />
                </div>

                <Paragraph 
                    label={formatTextMaxChars(postInfos.description, 150)}
                />

                {renderActionButtons()}

                {
                    testTitle && (
                        <Paragraph 
                            label={`Test associé: ${testTitle}`}
                            className="ft_jobOffer_testName"
                        />
                    )
                }

                <Paragraph 
                    label="Publier le 11/03/2024"
                    weight='bold'
                    variant='p_sm'
                    className="ft_jobOffer_container_Date_txt"
                />
            </div>

            <Modal
                // fullScreen
                isVisible={displaySelectedJobModal}
                onClose={toggleDisplaySelectedJobModal}
            >
                <div className="tf_jobOffer_modal_container">
                    <div className="tf_jobOffer_modal_header_container">
                        <Title 
                            label="Développeur mobile IOS H/F"
                            variant='h_xs'
                            weight='bold'
                            className="tf_jobOffer_modal_header_title"
                        />
                        <Paragraph 
                            label="Abidjan, Côte d'ivoire"
                            className="tf_jobOffer_modal_header_subTitle"
                        />

                        <div className="tf_jobOffer_modal_header_subContainer">
                            <Badge 
                                label={postInfos.criteria.contratType}
                                customColor={badgeCustomColor}
                            />
                            <Badge 
                                label={postInfos.criteria.workTime}
                                customColor={badgeCustomColor}
                            />
                            <Badge 
                                label={`Début le ${formatDate(postInfos.startAt)}`}
                                customColor={badgeCustomColor}
                            />
                            <Badge 
                                label={postInfos.criteria.teleworking ? 'Télétravail' : 'Pas de télétravail'}
                                customColor={badgeCustomColor}
                            />
                            {
                                postInfos.criteria.partialTeleworking && (
                                    <Badge 
                                        label='Télétravail partielle'
                                        customColor={badgeCustomColor}
                                    />
                                )
                            }
                            <Badge 
                                label={`${String(postInfos.criteria.minSalaryMonth)} Fcfa/mois min`}
                                customColor={badgeCustomColor}
                            />
                        </div>

                        {
                            !hideActionButtons && (
                                <div className="tf_jobOffer_modal_header_actionsButton">
                                    <Button 
                                        label="Postuler maintenant"
                                        onButtonPress={onSendPostResponse!}
                                        customClassname="tf_jobOffer_modal_actionsButton"
                                    />

                                    {/* <Icon 
                                        iconName={EnumIconName.bookmark}
                                        size={25}
                                        onIconPress={onSavePost}
                                    /> */}
                                    save
                                </div>
                            )
                        }
                    </div>

                    <HtmlParagraph 
                        label={postInfos.description}
                        className="ft_jobOffer_description"
                    />

                    {/* {
                        !hideActionButtons && (
                            <Button 
                                label="Signaler l'annonce"
                                onButtonPress={onReportPost!}
                            />
                        ) 
                    } */}
                </div>
            </Modal>
        </div>
    )
} 