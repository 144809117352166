import React from "react";
import './styles.css';
import { INotFound } from "./interfaces";
import { useTranslation } from "@utilities/hooks";

export const NotFoundComponent:React.FC<INotFound> = ({

})=> {

    const translate = useTranslation();

    return (
        <div>
            <h1>
                {translate('notFoundPageText')}
            </h1>
        </div>
    )
}
