import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const ModalSection = styled.section`
    width: 100%;
    margin-top: 20px;
    border-radius: 16px;
    background-color: #fff;
    overflow: hidden;
`;

export const ModalSectionHeader = styled.div`
    background-color: #EFECE6;
    padding: 30px 20px 20px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const ModalSectionSubHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const Avatar = styled.img`
    width: 70px;
    height: 75px;
    border-radius: 10px;
    object-fit: cover;
`;

export const ProfileName = styled.p`
    margin: 0%;
    font-size: 18px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
`;
export const ProfileTitle = styled.p`
    margin: 0%;
    font-size: 13px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
`;

export const ModalSectionBody = styled.div`
    padding: 0px 20px 30px 20px;
`;

export const ProfileDescription = styled.p`
    padding-top: 20px;
    margin: 0%;
    font-size: 12px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
`;

export const InfosWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
`;

export const InfoLabel = styled.p`
    margin: 0%;
    font-size: 12px;
    color: #0C1421;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
`;

export const ModalSectionBodySeparator = styled.div`
    border-bottom: solid 1px #F5F3EF;
    padding-bottom: 40px;
    margin-top: 40px;
`;


export const Location = styled.p`
    margin: 0%;
    margin-left: 10px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
    font-size: 12px;
    margin-top: 10px;

    @media (min-width: 760px){
        font-size: 14px;
    }
`;
