import styled from "styled-components";

export const CardPage = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 20px;
    margin-top: 20px;
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;

    @media (min-width: 760px){
        padding: 40px;
    }
`;