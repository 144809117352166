import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const Header = styled.section`
    width: 100%;
    height: 60vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const HeaderContent = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #00000040;
`;

export const Section = styled.section`
    margin-top: 30px;
    padding: 10px;

    @media (min-width: 760px){
        width: 70%;
        margin-left: 15%;
    }
`;

export const SectionTitle = styled.h3`
    margin: 0%;
    font-size: 16px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Black};

    @media (min-width: 760px){
        font-size: 22px;
    }
`;

export const SectionTxt = styled.p`
    margin: 0%;
    margin-top: 10px;
    font-size: 13px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Regular};

    @media (min-width: 760px){
        font-size: 18px;
    }
`;

export const BadgesSection = styled.section`
    margin-top: 30px;
    padding: 10px;
    gap: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: 760px){
        width: 70%;
        margin-left: 15%;
    }
`;

export const JobOffersSection = styled.section`
    width: 100%;
    margin-top: 30px;
    padding: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
